import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolioPersonAttributes = createAsyncThunk(
    'fetchPortfolioPersonRelationshipAttributes/fetchPortfolioPersonRelationshipAttributes',
    async () => {
        const response = await APIClient.getData(
            '/portfolio/relationship/client/attribute/?limit=100000'
        );
        return response.data.results;
    }
);


export const portfoliosPersonsAttributesSlice = createSlice({
    name: 'fetchPortfolioPersonRelationshipAttributes',
    initialState,
    reducers: {
      resetPortfolioPersonAttribute: (state) => {
        state.data= [];
        state.status= Status.idle;
        state.error = null;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioPersonAttributes.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioPersonAttributes.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(fetchPortfolioPersonAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
    },
});


export const { resetPortfolioPersonAttribute } = portfoliosPersonsAttributesSlice.actions;
