/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IEntity, IItemCompanyAttribute } from '../../common/interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface SliceState {
    id: string | null;
    attributes: IItemCompanyAttribute[];
    personRelations: IEntity[];
    portfolioRelations: IEntity[];
    status: Status;
    error: string | null;
}

const initialState: SliceState = {
    id: null,
    attributes: [],
    personRelations: [],
    portfolioRelations: [],
    status: Status.idle,
    error: null,
};

export const updateBusinessAttributes = createAsyncThunk(
    'singleBusiness/updateBusinessAttributes',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string | number; index: number }[];
    }) => {
        const neWData: any = [];

        attributes.forEach((element) => {
            neWData.push({
                name: element.name,
                value: element.value,
                index: Number(element.index),
            });
        });
        const response = await APIClient.postData(`/company/id/${id}/detail/`, {
            data: neWData,
        });
        return response.data;
    }
);

export const fetchSingleBusiness = createAsyncThunk(
    'singleBusiness/fetchSingleBusiness',
    async (id: string) => {
        const company = await APIClient.getData(
            `/company/id/${id}/detail?limit=10000`
        );

        // RELACIONES PERSONAS

        const companiesResult = await APIClient.getData(
            `/company/id/${id}/relationship/?limit=10000`
        );
        const companiesR = companiesResult.data.results;

        const relationsTypeResult = await APIClient.getData(
            `/company/relationship/?limit=10000`
        );
        const relationsType = relationsTypeResult.data.results;

        const personRelationsData = companiesR
            .map((r: any) => {
                const rela = relationsType.find((rt: any) => rt.id === r.type);
                if (r && rela) {
                    return {
                        id: r.client_from.id,
                        idRelationship: r.id,
                        relationshipTypeId: r.type.id,
                        entity: `${r.client_from.name}`,
                        name: rela.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        // RELACIONES CUENTAS DE INVERSION

        const portfoliosResult = await APIClient.getData(
            `/portfolio/?limit=10000`
        );
        const portfolios = portfoliosResult.data.results;

        const portfolioClientResult = await APIClient.getData(
            `/company/id/${id}/portfolio/relationship/?limit=10000`
        );
        const portfolioClient = portfolioClientResult.data.results;

        const portfolioRelationsData = portfolioClient
            .map((r: any) => {
                const por = portfolios.find(
                    (rt: any) => rt.id === r.portfolio_to
                );
                if (por && r) {
                    return {
                        id: por.id,
                        idRelationship: r.id,
                        relationshipTypeId: r.type.id,
                        entity: por.reference_id,
                        name: r.type.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        return {
            id,
            company: company.data.results,
            persons: personRelationsData,
            portfolios: portfolioRelationsData,
        };
    }
);

export const singleBusinessSlice = createSlice({
    name: 'singleBusiness',
    initialState,
    reducers: {
        resetSingleBusiness: (state) => {
            state.attributes = [];
            state.error = null;
            state.id = null;
            state.personRelations = [];
            state.portfolioRelations = [];
            state.status = Status.idle;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSingleBusiness.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchSingleBusiness.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.id = action.payload.id;
                state.attributes = action.payload.company;
                state.personRelations = action.payload.persons;
                state.portfolioRelations = action.payload.portfolios;
            })
            .addCase(fetchSingleBusiness.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateBusinessAttributes.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
            })
            .addCase(updateBusinessAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetSingleBusiness } = singleBusinessSlice.actions;
