import React, { useState , useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TFunction } from 'react-i18next';
import Modal from '../Modal';
import { resetRelationshipType } from '../../modules/personPersonRelationshipType/personPersonRelationshipTypeSlice';
import { resetRelationshipTypeBusiness } from '../../modules/personBusinessRelationshipType/personBusinessRelationshipTypeSlice';
import { resetRelationshipTypePortfolio } from '../../modules/personPortfolioRelationshipType/personPortfolioRelationshipTypeSlice';
import { resetRelationshipTypePortfolioPort } from '../../modules/portfolioPortfolioRelationshipType/personBusinessRelationshipTypeSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: string;
};

export function CreateRelationshipType({
    setShowCreate,
    t,
    title,
    relationshipCreate,
    valdiateIfExists,
    stateRelationship,
    person,
    company,
    portfolioPerson,
    portfolioBusiness,
    portfolioPortfolio,
}: {
    setShowCreate: React.Dispatch<React.SetStateAction<boolean>>;
    t: TFunction<'translation', undefined>;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    relationshipCreate: any;
    valdiateIfExists: (name: string) => boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateRelationship: any;
    person: boolean;
    company: boolean;
    portfolioPerson: boolean;
    portfolioBusiness: boolean;
    portfolioPortfolio: boolean;
}) {
    const [addRequestStatus, setAddRequestStatus] = useState('');

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    const dispatch = useDispatch();
    const [existName, setExistName] = useState(false);

    const onSubmit = async (data: FormData) => {
        setAddRequestStatus('');
        setExistName(false);
        if (valdiateIfExists(data.name) === true) {
            setExistName(true);
            setAddRequestStatus('Nombre de relación ya existe');
            return;
        }
        try {
            dispatch(relationshipCreate({ name: data.name }));
            reset();
        } catch (e) {
            setAddRequestStatus(t('relationshipType.saved.error'));
        }
    };
    const clearData = () => {
        setShowCreate(false);
        setAddRequestStatus('');
        setExistName(false);
        reset();
    };
    useEffect(() => {
        if (existName === false && stateRelationship.status === 'success') {
            setAddRequestStatus(t('relationshipType.saved.success'));
            if (person) {
                dispatch(resetRelationshipType());
            }
            if (company) {
                dispatch(resetRelationshipTypeBusiness());
            }
            if (portfolioPerson) {
                dispatch(resetRelationshipTypePortfolio());
            }
            if (portfolioBusiness) {
                dispatch(resetRelationshipTypeBusiness());
            }
            if (portfolioPortfolio) {
                dispatch(resetRelationshipTypePortfolioPort());
            }
        }
    }, [company, dispatch, existName, person, portfolioBusiness, portfolioPerson, portfolioPortfolio, stateRelationship.status, t]);

    return (
        <Modal
            setShowModal={setShowCreate}
            onClose={() => clearData}
            className="h-[500px]"
        >
            <div className="px-4">
                <h3 className="text-center text-2xl font-bold mb-10">
                    Crear tipo de relación {title}
                </h3>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label
                                htmlFor="value"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('relationshipType.form.name')} *
                                <div className="mt-1">
                                    <input
                                        id="name"
                                        type="text"
                                        className={`${
                                            errors.name
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('name', {
                                            required: true,
                                        })}
                                    />
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {errors.name.type === 'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                        <div className="mt-4">
                            <button
                                disabled={
                                    stateRelationship.status === 'loading'
                                }
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                Guardar
                            </button>
                            {addRequestStatus ===
                                t('relationshipType.saved.error') && (
                                <p className="text-red-600 mb-2 mt-4">
                                    {addRequestStatus}
                                </p>
                            )}
                            {addRequestStatus ===
                                t('relationshipType.saved.success') && (
                                <p className="text-green-600 mb-2 mt-4">
                                    {addRequestStatus}
                                </p>
                            )}
                            {existName === true && (
                                <p className="text-red-600 mb-2 mt-4">
                                    {addRequestStatus}
                                </p>
                            )}
                            {stateRelationship.status === 'loading' && (
                                <span> Cargando...</span>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
