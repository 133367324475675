import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPersonPersonRelationshipType } from './interfaces';
import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPersonRelationshipTypeState {
    data: IPersonPersonRelationshipType | null;
    status: Status;
    error: string | null;
}

const initialState: PersonPersonRelationshipTypeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const createPersonPersonRelationshipType = createAsyncThunk(
    'personPersonRelationshipType/createPersonPersonRelationshipType',
    async ({ name }: { name: string }) => {
        const response = await APIClient.postData(`/client/relationship/`, {
            name,
        });
        return response.data;
    }
);

export const updatePersonPersonRelationshipType = createAsyncThunk(
    'personPersonRelationshipTypeUpdate/updatePersonPersonRelationshipType',
    async ({ name, id }: { name: string; id: number }) => {
        const response = await APIClient.postData(
            `/client/relationship/${id}/`,
            {
                name,
            }
        );
        return response.data;
    }
);
export const personPersonRelationshipTypeSlice = createSlice({
    name: 'personPersonRelationshipType',
    initialState,
    reducers: {
        resetRelationshipType: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createPersonPersonRelationshipType.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPersonPersonRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                createPersonPersonRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(updatePersonPersonRelationshipType.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                updatePersonPersonRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                updatePersonPersonRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetRelationshipType } =
    personPersonRelationshipTypeSlice.actions;
