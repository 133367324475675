/* eslint-disable react/jsx-props-no-spreading */

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import EditAttribute from '../../../../components/EditAttribute/index';
import { updateCompanyAttribute } from '../../../../modules/business-attributes/features/businessAttributesSlice';

export default function EditCompanyAttribute({
    show = false,
    attributeData,
    toggle,
}: {
    show: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributeData: any;
    toggle: () => void;
}) {
    const dispatch = useAppDispatch();
    return (
        attributeData.data && (
            <SideCreate show={show} title="Editar atributo" toggle={toggle}>
                <EditAttribute
                    entity={2}
                    create={(body) =>
                        dispatch(updateCompanyAttribute(body)).unwrap()
                    }
                    show={show}
                    toggle={toggle}
                    attributeData={attributeData}
                />
            </SideCreate>
        )
    );
}
