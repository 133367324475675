/* eslint-disable react/jsx-props-no-spreading */

import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';

import TableSearch from '../../../components/Table/TableSearch';
// import Create from './components/Create';
import { fetchPersonPersonRelationships } from '../../../modules/person-person-relationships/features/personPersonRelationshipSlice';
import {
    fetchPersonRelationshipAttributes,
    addRelationshiptAttribute,
    resetRelAttributes,
} from '../../../modules/persons-relationships-attributes/features/personRelationshipAttributeSlice';
import {
    fetchPersonRelationshipAttributesValues,
    resetValues,
} from '../../../modules/persons-relationships-attributes/features/personRelationshipAttributesValuesSlice';
import Modal from '../../../components/Modal';
// import { IPersonAttribute } from '../../../modules/persons-attributes/interfaces/index';
import { fetchPersonRelationshipEnum } from '../../../modules/person-relationship-enums/personRelationshipEnum';
import { validateDate, validateType } from '../../../utils/helpers';
import { TypesAttribute } from '../../../components/enums/enums';
import AttributesTable from './components/AttributesTable';
import { fetchPersonRelationshipRelAttributesValues } from '../../../modules/persons-relationships-attributes/features/personRelAttributesSlice';
import { fetchSinglePerson } from '../../../modules/persons/features/singlePersonSlice';
import { resetValuesDeleteRelPerson } from '../../../modules/persons-relationships-attributes/features/deletePersonRelValudeSlice';
import { fetchPersonsRelationshipsAttributes } from '../../../modules/persons-relationships-attributes/features/fetchPersonRelAttributeSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    name: string;
    index: number;
    idAttribute: number;
};

export default function ListPersonsRelationshipAttributesValues() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormData>();

    const [getAvailableAttributes, setGetAvailableAttributes] = useState(true);
    // const [newAttributeStatus, setNewAttributeStatus] = useState('idle');
    const [showCreate, setShowCreate] = useState(false);

    const [isEnum, setIsEnum] = useState(false);
    const [isUnique, setIsUnique] = useState(false);
    const [idAttribute, setIdAttribute] = useState('');
    // const [availableAttributes, setAvailableAttributes] = useState<
    //     IPersonAttribute[]
    // >([]);
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const personRelationshipAttributes = useSelector(
        (state: RootState) => state.personsRelationshipsAttributes
    );
    const personsRelAttributes = useSelector(
        (state: RootState) => state.personsRelAttributes
    );
    const personRelationships = useSelector(
        (state: RootState) => state.personPersonRelationships
    );
    const personsRelationshipsAttributesValues = useSelector(
        (state: RootState) => state.personsRelationshipsAttributesValues
    );
    const deletedPersonsRelationshipsAttributesValues = useSelector(
        (state: RootState) => state.deletePersonsRelationshipAttributesRelValues
    );
    // const personsRelationshipValues = useSelector(
    //     (state: RootState) => state.personsRelationshipValue
    // );

    const singleRelationshipEnum = useSelector(
        (state: RootState) => state.personsRelationshipsAttributesEnum
    );
    const singlePerson = useSelector((state: RootState) => state.singlePerson);

    const personRelAttributes = useSelector(
        (state: RootState) => state.personRelAttributes
    );

    const { id, personId } = useParams();
    // console.log(id)
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (singlePerson.status === 'idle') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(fetchSinglePerson(personId!));
        }
    }, [dispatch, personId, singlePerson.status]);


      useEffect( () => 
        {
          dispatch(resetValues());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []

      )

    useEffect(() => {
        
        if (
            personRelAttributes.status === 'idle' &&
            singlePerson.status === 'success'
        ) {
            const dataRel = singlePerson.personRelations.filter(
                (attribute) => attribute.idRelationship === Number(id)
            );
            dispatch(
                fetchPersonRelationshipRelAttributesValues(
                    dataRel[0].relationshipTypeId
                )
            );
        }
    }, [
        dispatch,
        id,
        personRelAttributes.status,
        singlePerson.personRelations,
        singlePerson.status,
    ]);

    useEffect(() => {
        // Filter available attributes for new attribute
        if (
            getAvailableAttributes &&
            personRelationshipAttributes.data.length > 0 &&
            singlePerson.attributes.length > 0
        ) {
            // setAvailableAttributes(personRelationshipAttributes.data);
            // setNewAttributeStatus('success');
            setGetAvailableAttributes(false);
        }
    }, [
        personRelationshipAttributes.data,
        singlePerson.attributes,
        getAvailableAttributes,
        singlePerson.personRelations,
        id,
    ]);

    useEffect(() => {
        if (deletedPersonsRelationshipsAttributesValues.status === 'success') {
            // setAddRequestStatus('loading');
            dispatch(resetValues());
            dispatch(resetValuesDeleteRelPerson());
            // dispatch(fetchPersonPersonRelationships());
            // setAddRequestStatus('');
        }
    }, [deletedPersonsRelationshipsAttributesValues.status, dispatch]);
    
    useEffect(() => {
        if (
            personRelationshipAttributes.status === 'idle' &&
            personRelationships.status === 'idle'
        ) {
            dispatch(fetchPersonRelationshipAttributes());
            dispatch(fetchPersonPersonRelationships());
        }
    }, [
        personRelationshipAttributes.status,
        personRelationships.status,
        dispatch,
    ]);
    
    useEffect(() => {
        if (personsRelAttributes.status === 'idle') {
            // dispatch(resetRelAttributesPerson());
            dispatch(fetchPersonsRelationshipsAttributes());
        }
    }, [personsRelAttributes.status, dispatch]);

    useEffect(() => {
        if (
            personsRelationshipsAttributesValues.status === 'idle' &&
            id &&
            addRequestStatus !== 'success'
        ) {
            dispatch(resetValues());
            dispatch(fetchPersonRelationshipAttributesValues(id || ''));
        }
    }, [
        addRequestStatus,
        dispatch,
        id,
        personsRelationshipsAttributesValues.status,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.value').toString(),
                accessor: 'data',
            },
            {
                Header: 'Indice',
                accessor: 'index',
            },
        ],
        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: personsRelationshipsAttributesValues.data || [],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = tableInstance;

    const [msgError, setMsgError] = useState('');
    const [type, setType] = useState(0);
    const value = watch('value');

    const onSubmit = async (data: FormData) => {
        try {
            if (type === TypesAttribute.Number) {
                // eslint-disable-next-line no-param-reassign
                data.value = Number(data.value);
            }
            if (type === TypesAttribute.Date) {
                let splitDate = '';
                if (value) {
                    splitDate = value.split('-');
                }

                const isValidDate = validateDate(splitDate, value);

                if (isValidDate) {
                    // eslint-disable-next-line no-param-reassign
                    data.value = isValidDate;
                } else {
                    setMsgError(
                        'Formato fecha invalido debe ingresar dd-mm-yyyy'
                    );
                }
            }

            // eslint-disable-next-line no-param-reassign
            data.index = data.index || 1;

            // eslint-disable-next-line no-param-reassign
            data.idAttribute = Number(idAttribute);

            setAddRequestStatus('loading');

            dispatch(
                addRelationshiptAttribute({
                    id: id ?? '',
                    attributes: [data],
                })
            );
            dispatch(resetRelAttributes());
            // await create(body);
            setAddRequestStatus('success');
            reset();
            setShowCreate(false);
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };

    useEffect(() => {
        if (
            personsRelationshipsAttributesValues.status === 'success' &&
            addRequestStatus === 'success'
        ) {
            dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
            // dispatch(fetchPersonRelationshipAttributes());

            setAddRequestStatus('');
            dispatch(resetValues());
        }
    }, [
        personRelationshipAttributes.status,
        dispatch,
        id,
        addRequestStatus,
        personsRelationshipsAttributesValues.status,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('value', '');
        setIsEnum(false);
        setIsUnique(false);
        if (e.target.selectedOptions[0].getAttribute('data-type') === '1') {
            setType(1);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '2') {
            setType(2);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '3') {
            setType(3);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '4') {
            setType(4);
        }
        if (
            e.target.selectedOptions[0].getAttribute('data-unique') === 'true'
        ) {
            setIsUnique(true);
        }

        if (e.target.selectedOptions[0].getAttribute('data-enum') === 'true') {
            setIsEnum(true);
        }
        setIdAttribute(
            e.target.selectedOptions[0].getAttribute(
                'data-id'
            ) as SetStateAction<string>
        );
    };
    useEffect(() => {
        if (idAttribute) {
            dispatch(fetchPersonRelationshipEnum(Number(idAttribute)));
            //         dispatch(fetchPersonRelationshipAttributes());

            setIdAttribute(idAttribute);
        }
    }, [dispatch, idAttribute]);



    return (
        <div className="relative mb-20">
            {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}

            <PageHeader title="Valor atributo de relación cliente" />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {

              personsRelationshipsAttributesValues.status === 'loading'  
&&
            (
                    <LoadingAnimated message={t('common.loading')} />
                )}

            {personsRelationshipsAttributesValues.status === 'success' &&
                personRelAttributes.status === 'success' &&
                personsRelationshipsAttributesValues.data &&
                personsRelationshipsAttributesValues.data.length === 0 && (
                    <h3 className="text-lg text-center">
                        No existen valores para esta relación
                    </h3>
                )}

            <div>
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={t('attributes.create.title')}
                    type="button"
                    onClick={() => setShowCreate(!showCreate)}
                />
            </div>
            {personsRelationshipsAttributesValues.status === 'success' &&
                personRelAttributes.status === 'success' &&
                personsRelationshipsAttributesValues.data.length > 0 && (
                    <AttributesTable
                        clientId={id || ''}
                        data={personsRelationshipsAttributesValues.data}
                    />
                )}

            {showCreate && (
                <Modal
                    setShowModal={setShowCreate}
                    onClose={() => setShowCreate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('attributes.create.addNew')}
                        </h3>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('attributes.title')} *
                                        <div className="mt-1">
                                            <select
                                                id="name"
                                                className={`${
                                                    errors.name
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register('name', {
                                                    required: true,
                                                    onChange: handleChange,
                                                })}
                                            >
                                                {personRelAttributes.status ===
                                                    'success' &&
                                                personRelAttributes.data
                                                    .length > 0 ? (
                                                    <option value="" key={0}>
                                                        {t(
                                                            'attributes.create.selectAnAttribute'
                                                        )}
                                                    </option>
                                                ) : (
                                                    <option>
                                                        No existen atributos
                                                        asociados a la relación
                                                    </option>
                                                )}

                                                {personRelAttributes.status ===
                                                    'success' &&
                                                    personRelAttributes.data
                                                        .length > 0 &&
                                                    personRelAttributes.data.map(
                                                        (attribute) => (
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.name ||
                                                                    ''
                                                                }
                                                                data-enum={
                                                                    attribute.type ===
                                                                    2
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-type={
                                                                    attribute.type
                                                                }
                                                                data-unique={
                                                                    attribute.is_unique
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-id={
                                                                    attribute.id
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                {/* END Attributes Selection */}

                                {isEnum ? (
                                    <div className="w-full mt-4 mb-4">
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Valores de atributo *
                                            <div className="mt-1">
                                                <select
                                                    id="value"
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="" key={0}>
                                                        {t(
                                                            'attributes.create.selectAnAttribute'
                                                        )}
                                                    </option>
                                                    {singleRelationshipEnum.status ===
                                                        'success' &&
                                                        singleRelationshipEnum
                                                            .data.length > 0 &&
                                                        singleRelationshipEnum.data.map(
                                                            (attribute) =>
                                                                attribute.enum
                                                                    .length >
                                                                0 ? (
                                                                    attribute.enum.map(
                                                                        (
                                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                            e: any
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    e.id
                                                                                }
                                                                                value={
                                                                                    e.name
                                                                                }
                                                                                data-enum={
                                                                                    attribute.type ===
                                                                                    2
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-unique={
                                                                                    attribute.is_unique
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-id={
                                                                                    e.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <option
                                                                    disabled
                                                                    >
                                                                        No existen valores
                                                                    </option>
                                                                )
                                                        )}
                                                </select>
                                            </div>
                                        </label>

                                        {errors.name && (
                                            <p className="text-red-600 ml-2">
                                                {t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('common.value')} *
                                            <div className="mt-1">
                                                <input
                                                    id="value"
                                                    type={validateType(type)}
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                />
                                                {errors.value && (
                                                    <p className="text-red-600 ml-2">
                                                        {errors.value.type ===
                                                            'required' &&
                                                            t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                    </p>
                                                )}
                                                {msgError !== '' && (
                                                    <p className="text-red-600 ml-2">
                                                        {msgError}
                                                    </p>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                )}
                                {/*  */}

                                {isUnique === false && (
                                    <div className="mt-3">
                                        <label
                                            htmlFor="index"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {' '}
                                            Indice
                                            <div className="mt-1">
                                                <input
                                                    {...register('index', {})}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    id="index"
                                                    className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )}
                                <div className="mt-4">
                                    {addRequestStatus === 'error' && (
                                        <p className="text-red-600 mb-2">
                                            {t('attributes.create.error')}
                                        </p>
                                    )}
                                    {addRequestStatus === 'success' && (
                                        <p className="text-green-600 mb-2">
                                            {t('attributes.create.success')}
                                        </p>
                                    )}
                                    <button
                                        disabled={
                                            addRequestStatus === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {addRequestStatus === 'loading'
                                            ? t('wait.message')
                                            : t('common.save')}
                                    </button>
                                    <Button
                                        type="button"
                                        variant="link"
                                        className="w-full mt-10"
                                        label={t('common.cancel')}
                                        onClick={() => setShowCreate(false)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
