import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBusinessPortfolioRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BusinessPortfolioRelationState {
    data: IBusinessPortfolioRelation[];
    status: Status;
    error: string | null;
}

const initialState: BusinessPortfolioRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBusinessPortfolioRelationships = createAsyncThunk(
    'businessPortfolioRelationships/fetchBusinessPortfolioRelationships',
    async () => {
        const response = await APIClient.getData(
            '/portfolio/relationship/type/company/?limit=100000'
        );
        return response.data.results;
    }
);

export const addPortfolioBusinessRelation = createAsyncThunk(
    'singlePortfolio/addPortfolioBusinessRelation',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { company_from: number; portfolio_to: number; relationship_name: string  };
    }) => {
        const response = await APIClient.postData(`/portfolio/id/${id}/relationship/company/`, attributes);

        return response.data;
    }
);

export const businessPortfolioRelationshipSlice = createSlice({
    name: 'businessPortfolioRelationships',
    initialState,
    reducers: {
        resetBusinessPortfolioRelationship: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBusinessPortfolioRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                fetchBusinessPortfolioRelationships.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                fetchBusinessPortfolioRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetBusinessPortfolioRelationship } =
    businessPortfolioRelationshipSlice.actions;
