import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioCompanyAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioCompanyAttributeState {
    data: IPortfolioCompanyAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioCompanyAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const createPortfolioCompanyAttribute = createAsyncThunk(
    'portfolioCompanyAttributes/create',
    async (body: { label: string; name: string, index: number, type:number, is_unique:boolean, parent_attribute_id:number }) => {
        const response = await APIClient.postData('/portfolio/relationship/company/attribute/', body);
        return response.data;
    }
);


export const updatePortfolioCompanyAttribute = createAsyncThunk(
    'portfolioCompanyAttributes/updatePortfolioCompany',
    async (body: {
        id: string;
        label: string;
        name: string;
        type: number | undefined;
        is_unique: boolean;
        parent_attribute_id: number | null;
        portfolio_company_relationship_type: number | null | undefined;
    }) => {
        if (!body.portfolio_company_relationship_type) {
            // eslint-disable-next-line no-param-reassign
            delete body.portfolio_company_relationship_type;
        }
        const response = await APIClient.postData(
            `/portfolio/relationship/company/attribute/${body.id}/`,
            body
        );
        return response.data;
    }
);

export const portfolioCompanyAttributesSlice = createSlice({
    name: 'portfolioCompanyAttributes',
    initialState,
    reducers: {
      resetPortfolioCompanyAttribute : (state) =>{
        state.data = [];
        state.error = null;
        state.status = Status.idle
      }
    },
    extraReducers(builder) {
        builder
            .addCase(createPortfolioCompanyAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPortfolioCompanyAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                createPortfolioCompanyAttribute.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(updatePortfolioCompanyAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                updatePortfolioCompanyAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                updatePortfolioCompanyAttribute.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetPortfolioCompanyAttribute } =
    portfolioCompanyAttributesSlice.actions;
