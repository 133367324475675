/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';


import TableSearch from '../../../components/Table/TableSearch';
// import Create from './components/Create';

import Create from './components/Create';
import { ICompanyRelationshipAttribute } from '../../../modules/company-relationships-attributes/interfaces';
import { fetchPersonBusinessRelationships } from '../../../modules/person-business-relationships/features/personBusinessRelationshipSlice';
import {
    fetchCompaniesRelationshipAttributes,
    resetValuesCompaniesRelAttribute,
} from '../../../modules/company-relationships-attributes/features/companiesRelationshipAttributesSlice';
import { validateTypeAttribute } from '../../../utils/helpers';
import { resetValuesCompany } from '../../../modules/company-relationships-attributes/features/companyRelationshipAttributeSlice';
import { resetGetCompanyPersonAttributes, getCompanyPersonAttribute } from '../../../modules/company-relationships-attributes/features/getCompanyRelationshipAttributes';
import EditCompanyPersonAttribute from './components/Edit';

export default function ListCompaniesRelationshipAttributes() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const companiesRelationshipAttribute = useSelector(
        (state: RootState) => state.companiesRelationshipsAttributes
    );
    const companyRelationshipAttribute = useSelector(
        (state: RootState) => state.companyRelationshipsAttribute
    );
    const [showCreate, setShowCreate] = useState(false);

    // let locale = 'en-US';
    // if (i18n.language === 'es') {
    //     locale = 'es-ES';
    // }

    useEffect(() => {
        if (companiesRelationshipAttribute.status === 'idle') {
            dispatch(fetchCompaniesRelationshipAttributes());
            dispatch(fetchPersonBusinessRelationships());
	
        }
    }, [companiesRelationshipAttribute.status, dispatch]);


	useEffect(() => {
	  if(companyRelationshipAttribute.status === 'success'){
		setTimeout(() => {
			setShowCreate(false)
			dispatch(resetValuesCompany())
			dispatch(resetValuesCompaniesRelAttribute());
		}, 2000);
	  }
	
	
	}, [dispatch, companyRelationshipAttribute.status])
    const [showEdit, setShowEdit] = useState(false);

    const columns = useMemo<Column<ICompanyRelationshipAttribute>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.identifier').toString(),
                accessor: 'label',
            },
            {
                Header: 'Tipo',
                accessor: 'type',

                Cell: ({
                    cell: { value },
                }: {
                    cell: { value: ICompanyRelationshipAttribute };
                }) => validateTypeAttribute(value),
            },

            {
                Header: 'Multi-Atributo',
                accessor: 'is_unique',
                Cell: ({
                    cell: { value },
                }: {
                    cell: { value: ICompanyRelationshipAttribute };
                }) => (value ? 'No' : 'Si'),
            },
            {
                Header: 'Padre',
                accessor: 'parent_attribute_id',
                Cell: (row: {
                    row: {
                        original: {
                            parent_attribute_id: { toString: () => string };
                            parent: {
                                fields: {
                                    name: { toString: () => string };
                                };
                            };
                        };
                    };
                }) =>
                    row.row.original.parent_attribute_id &&
                    row.row.original.parent
                        ? `${row.row.original.parent_attribute_id}-${row.row.original.parent.fields.name}`
                        : '-',
            },
            {
                Header: 'Editar',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({
                    row: {
                        // eslint-disable-next-line camelcase
                        original: { id },
                    },
                }: {
                    row: {
                        original: {
                            id: string;
                        };
                    };
                }) => (
                    <button
                        type="button"
                        className="hover:bg-red-200"
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            setShowEdit(!showEdit);
                            dispatch(resetGetCompanyPersonAttributes());
                            dispatch(getCompanyPersonAttribute(id));
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                        </svg>
                    </button>
                ),
            },
        ],

        [dispatch, showEdit, t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: companiesRelationshipAttribute.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    const companyPersonAttributes = useSelector(
        (state: RootState) => state.getCompanyPersonAttributes
    );
    return (
        <div className="relative mb-20">
            <Create show={showCreate} toggle={() => setShowCreate(false)} />
            <Button
                className="absolute right-0 top-0 z-10"
                label={t('attributes.create.title')}
                type="button"
                onClick={() => setShowCreate(!showCreate)}
            />
            {companyPersonAttributes.status === 'success' && (
                <EditCompanyPersonAttribute
                    show={showEdit}
                    attributeData={companyPersonAttributes}
                    toggle={() => setShowEdit(false)}
                />
            )}
            <PageHeader title="Atributos Relación Jurídica" />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {companiesRelationshipAttribute.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {companiesRelationshipAttribute.status === 'success' &&
            companiesRelationshipAttribute.data.length > 0 ? (
                <>
                    <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                        <div className="flex space-x-4 items-center">
                            <button
                                type="button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Ir la página:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const newPage = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(newPage);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[50, 100, 200, 300].map((pageS) => (
                                    <option key={pageS} value={pageS}>
                                        Mostrar {pageS}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="overflow-x-auto shadow-md">
                        <table
                            className="table-auto w-full mx-auto"
                            {...getTableProps()}
                        >
                            <thead className="text-white">
                                {
                                    // Loop over the header rows

                                    headerGroups.map((headerGroup) => (
                                        // Apply the header row props

                                        <tr
                                            className="text-left"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {
                                                // Loop over the headers in each row

                                                headerGroup.headers.map(
                                                    (column) => (
                                                        // Apply the header cell props

                                                        <th
                                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                minWidth: '2%',
                                                            }}
                                                        >
                                                            <div className="flex justify-between">
                                                                {
                                                                    // Render the header

                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                }
                                                                {column.canSort ? (
                                                                    <span
                                                                        className={`${
                                                                            column.isSorted &&
                                                                            'text-sym-primary-500'
                                                                        }`}
                                                                    >
                                                                        {column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M19 9l-7 7-7-7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                        {!column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M5 15l7-7 7 7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                    )
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    // Loop over the table rows

                                    page.map((row) => {
                                        // Prepare the row for display

                                        prepareRow(row);

                                        return (
                                            // Apply the row props

                                            <tr
                                                className="odd:bg-slate-200 hover:bg-slate-300"
                                                {...row.getRowProps()}
                                            >
                                                {
                                                    // Loop over the rows cells

                                                    row.cells.map((cell) => (
                                                        // Apply the cell props

                                                        <td
                                                            className="py-4 px-2 cursor-pointer"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents

                                                                cell.render(
                                                                    'Cell'
                                                                )
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                        <div className="flex space-x-4 items-center">
                            <button
                                type="button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Ir la página:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const newPage = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(newPage);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[50, 100, 200, 300].map((pageS) => (
                                    <option key={pageS} value={pageS}>
                                        Mostrar {pageS}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            ) : (
                companiesRelationshipAttribute.status === 'success' &&
                companiesRelationshipAttribute.data.length === 0 && (
                    <p className="text-center">No se encontraron datos</p>
                )
            )}
        </div>
    );
}
