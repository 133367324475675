import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICompanyRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyPersonAttributeState {
    data: ICompanyRelationshipAttribute | null;
    status: Status;
    error: string | null;
}

const initialState: CompanyPersonAttributeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const getCompanyPersonAttribute = createAsyncThunk(
    'getCompanyPersonAttributes/getCompanyPersonAttributes',
    async (id: string) => {
        const response = await APIClient.getData(
            `/company/relationship/attribute/${id}`
        );
        return response.data;
    }
);

export const getCompanyPersonAttributesSlice = createSlice({
    name: 'getCompanyPersonAttribute',
    initialState,
    reducers: {
        resetGetCompanyPersonAttributes: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getCompanyPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getCompanyPersonAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(getCompanyPersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetGetCompanyPersonAttributes } =
    getCompanyPersonAttributesSlice.actions;
