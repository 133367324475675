import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonAttributeState {
    data: IPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PersonAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPersonAttributes = createAsyncThunk(
    'personAttributes/fetchPersonAttributes',
    async () => {
        const response = await APIClient.getData(
            '/client/attribute/?limit=100000'
        );
        return response.data.results;
    }
);

export const createPersonAttribute = createAsyncThunk(
    'personAttributes/create',
    async (body: { label: string; name: string, index: number, type:number, is_unique:boolean, parent_attribute_id:number }) => {
        const response = await APIClient.postData('/client/attribute/', body);
        return response.data;
    }
);
export const updatePersonAttribute = createAsyncThunk(
    'personAttributes/update',
    async (body: { id: string; label: string; name: string,  type:number | undefined, is_unique:boolean, parent_attribute_id:number | null  | undefined}) => {
        const response = await APIClient.postData(`/client/attribute/${body.id}/`, body);
        return response.data;
    }
);
export const createPersonAttributeEnum = createAsyncThunk(
    'personAttributes/createEnum',
    async (body: {  name: string, attribute:number}) => {
        const response = await APIClient.postData('/client/enum/', body);
        return response.data;
    }
);

export const personsAttributesSlice = createSlice({
    name: 'personAttributes',
    initialState,
    reducers: {
      resetPostPersonAttribute: (state) => {
        state.data = [];
        state.status = Status.idle;
        state.error = null;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPersonAttributes.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPersonAttributes.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchPersonAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPersonAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createPersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createPersonAttributeEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPersonAttributeEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createPersonAttributeEnum.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updatePersonAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(updatePersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});


export const { resetPostPersonAttribute } = personsAttributesSlice.actions;
