import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPortfolioAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPortfolioAttributeState {
    data: IPortfolioPortfolioAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPortfolioAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolioPortfolioAttributes = createAsyncThunk(
    'fetchPPportfolioPortfolioAttributes/fetchPortfolioPortfolioAttributes',
    async () => {
        const response = await APIClient.getData(
            '/portfolio/relationship/portfolio/attribute/?limit=100000'
        );
        return response.data.results;
    }
);


export const portfoliosPortfolioAttributesSlice = createSlice({
    name: 'fetchPPportfolioPortfolioAttributes',
    initialState,
    reducers: {
      resetPortfolioPortAttribute : (state) => {
        state.data = [];
        state.error = null;
        state.status = Status.idle;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioPortfolioAttributes.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioPortfolioAttributes.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(fetchPortfolioPortfolioAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
    },
});

export const { resetPortfolioPortAttribute } = portfoliosPortfolioAttributesSlice.actions;
