import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBusinessAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
    delete = 'delete'
}

export interface BusinessAttributeState {
    data: IBusinessAttribute[];
    status: Status;
    error: string | null;
}

const initialState: BusinessAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBusinessAttributes = createAsyncThunk(
    'businessAttributes/fetchBusinessAttributes',
    async () => {
        const response = await APIClient.getData(
            '/company/attribute/?limit=100000'
        );
        return response.data.results;
    }
);

export const createBusinessAttribute = createAsyncThunk(
    'busnessAttributes/create',
    async (body: { label: string; name: string, index: number, type:number, is_unique:boolean, parent_attribute_id:number }) => {
        const response = await APIClient.postData('/company/attribute/', body);
        return response.data;
    }
);

export const createBusinessAttributeEnum = createAsyncThunk(
    'busnessAttributes/createEnum',
    async (body: {  name: string, attribute:number}) => {
        const response = await APIClient.postData('/company/enum/', body);
        return response.data;
    }
);

export const updateCompanyAttribute = createAsyncThunk(
    'companyAttributes/update',
    async (body: {
        id: string;
        label: string;
        name: string;
        type: number| undefined;
        is_unique: boolean;
        parent_attribute_id: number |  undefined;
    }) => {
        const response = await APIClient.postData(
            `/company/attribute/${body.id}/`,
            body
        );
        return response.data;
    }
);

export const deleteCompanyAttribute = createAsyncThunk(
    'busnessAttributes/deleteCompanyAttribute',
    async ({
        idClient,
        idAttribute,
    }: {
        idClient: string;
        idAttribute: string;
    }) => {
        const response = await APIClient.deleteDataEntiy(
            `/company/id/${idClient}/detail/${idAttribute}/`
        );

        return response.data;
    }
);
export const businessAttributesSlice = createSlice({
    name: 'businessAttributes',
    initialState,
    reducers: {
        resetSingleBusinessAttributes: (state) => {
          state.data = [];
          state.status = Status.idle;
          state.error =  null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBusinessAttributes.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchBusinessAttributes.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchBusinessAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createBusinessAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createBusinessAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createBusinessAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createBusinessAttributeEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createBusinessAttributeEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createBusinessAttributeEnum.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteCompanyAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteCompanyAttribute.fulfilled, (state) => {
                state.status = Status.delete;
                // Add any fetched data to the array
            })
            .addCase(deleteCompanyAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateCompanyAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateCompanyAttribute.fulfilled, (state) => {
                state.status = Status.delete;
                // Add any fetched data to the array
            })
            .addCase(updateCompanyAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});


export const { resetSingleBusinessAttributes } = businessAttributesSlice.actions;
