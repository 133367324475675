


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICompanyRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyRelationshipAttributeState {
    data: ICompanyRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: CompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const fetchCompanyRelationshipRelAttributesValues = createAsyncThunk(
    'companyAttributes/fetchCompanyAttributesRelValues',
    async (id: string) => {
        const response = await APIClient.getData(
            `/company/relationship/attribute/relationship/${id}?limit=100000`
        );
        return response.data.results;
    }
);

export const updateCompanyPersonAttribute = createAsyncThunk(
    'companyPersonAttributes/updateCompany',
    async (body: {
        id: string;
        label: string;
        name: string;
        type: number | undefined;
        is_unique: boolean;
        parent_attribute_id: number | null | undefined;
        company_relationship_type: number | null | undefined;
    }) => {
        if (!body.company_relationship_type) {
            // eslint-disable-next-line no-param-reassign
            delete body.company_relationship_type;
        }
        const response = await APIClient.postData(
            `/company/relationship/attribute/${body.id}/`,
            body
        );
        return response.data;
    }
);

export const companiesRelationshipAttributesRelValuesSlice = createSlice({
    name: 'companyRelationshipAttributesValues',
    initialState,
    reducers: {

		resetValuesCompaniesRel: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(
                fetchCompanyRelationshipRelAttributesValues.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                fetchCompanyRelationshipRelAttributesValues.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchCompanyRelationshipRelAttributesValues.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(updateCompanyPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                updateCompanyPersonAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(updateCompanyPersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
          
    },
});

export const { resetValuesCompaniesRel } = companiesRelationshipAttributesRelValuesSlice.actions;
