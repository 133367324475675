/* eslint-disable react/jsx-props-no-spreading */

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import {  updatePersonAttribute } from '../../../../modules/persons-attributes/features/personsAttributeSlice';
import EditAttribute from '../../../../components/EditAttribute/index';

export default function EditPersonAttribute({
    show = false,
    attributeData,
    toggle,
}: {
    show: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributeData: any;
    toggle: () => void;
}) {
    const dispatch = useAppDispatch();
    return (
        
          attributeData.data &&

            <SideCreate show={show} title="Editar atributo" toggle={toggle}>
                <EditAttribute
                    entity={1}
                    create={(body) =>
                        dispatch(updatePersonAttribute(body)).unwrap()
                    }
                    show={show}
                    toggle={toggle}
                    attributeData={attributeData}
                />
            </SideCreate>


        
    );
}
