/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetRelAttributes } from '../../modules/persons-relationships-attributes/features/personRelationshipAttributeSlice';
import { RootState } from '../../store';
import {
    validTypeAttributes,
    ValidAttributes,
} from '../validTypeAttribute/validTypesAttribute';
import { resetValuesCompaniesRelAttribute } from '../../modules/company-relationships-attributes/features/companiesRelationshipAttributesSlice';

type FormData = {
    name: string;
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: any;
    is_unique: boolean;
    parent_attribute_id: number;
    client_relationship_type: number;
};

type FormDataCompany = {
    name: string;
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: any;
    is_unique: boolean;
    parent_attribute_id: number;
    company_relationship_type: number;
};
export default function EditAttributeRelationship({
    create,
    show,
    entity,
    toggle,
    attributeData,
}: {
    entity: number;
    create: ({
        label,
        name,
    }: {
        id: string;
        label: string;
        name: string;
        type: number | undefined;
        is_unique: boolean;
        parent_attribute_id: number | null | undefined;
        client_relationship_type: number | null | undefined;
    }) => Promise<void>;
    show: boolean;
    toggle: () => void;
    attributeData: any;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormData>();

    const { t } = useTranslation();

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [isUniqueChecked, setIsUniqueChecked] = useState(
        attributeData.data.is_unique
    );
    // const [isUnique, setIsUnique] = useState('false');

    const personsRelationships = useSelector(
        (state: RootState) => state.personPersonRelationships
    );

    const personsRelationshipAttributes = useSelector(
        (state: RootState) => state.personsRelationshipsAttributes
    );

 
    // eslint-disable-next-line
    const handleCheckboxUnique = (e: any) => {
        setIsUniqueChecked(!isUniqueChecked);
        setValue(e.target.name, isUniqueChecked);
    };

    // eslint-disable-next-line
    const handleChange = (e: any) => {
        // setIsUnique(e.target.selectedOptions[0].getAttribute('data-unique'));
        setValue('client_relationship_type', e.target.value);
    };
    const handleChangeParent = (e: any) => {
        // setIsUnique(e.target.selectedOptions[0].getAttribute('data-unique'));
        setValue('parent_attribute_id', e.target.value);
    };
    useEffect(() => {
        if (!show) {
            reset();

            setAddRequestStatus('idle');
        }
    }, [show, reset]);
    const dispatch = useDispatch();

    const onSubmit = async (data: FormData) => {
        // if (isUnique === 'false' || isUnique === null) {
        let typeData = data.type;
        if (typeData.match(/^-?\d+$/)) {
            typeData = Number(data.type);
        } else {
            typeData = validTypeAttributes.find(
                (att) => att.name === data.type
            )?.id;
        }
        const body = {
            id: attributeData.data.id,
            name: data.name,
            label: data.name,
            index: data.index,
            type: typeData,
            is_unique: Boolean(data.is_unique),
            parent_attribute_id: !data.parent_attribute_id
                ? attributeData.data.parent_attribute_id
                : Number(data.parent_attribute_id),
            client_relationship_type: !data.client_relationship_type
                ? attributeData.data.client_relationship_type
                : Number(data.client_relationship_type),
        };
        try {
            setAddRequestStatus('loading');
            await create(body);
            setAddRequestStatus('success');
            dispatch(resetRelAttributes());

            toggle();
            reset();
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
        // } else {
        //     setAddRequestStatus('error');
        // }
    };

    return (
        <div className="mb-20">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.name')}
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                defaultValue={attributeData.data.name}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('name', {
                                    required: true,
                                })}
                                // Format text to be uppercase and spaces as _
                                onChange={(e) => {
                                    setValue(
                                        'name',
                                        e.target.value
                                            .toUpperCase()
                                            .replace(/ /g, '_')
                                    );
                                }}
                            />
                            {errors.name && (
                                <p className="text-red-600 ml-2">
                                    {errors.name.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de atributo
                        <div className="mt-1">
                            <select
                                id="type"
                                // onChange={handleChange}
                                {...register('type', {})}
                                required
                            >
                                <option defaultValue={attributeData.data.type}>
                                    {validTypeAttributes
                                        .filter(
                                            (attType) =>
                                                attType.id ===
                                                attributeData.data.type
                                        )
                                        .map(
                                            (vAttribute: ValidAttributes) =>
                                                vAttribute.name
                                        )}
                                </option>
                                {validTypeAttributes.map(
                                    (vAttribute: ValidAttributes) =>
                                        attributeData.data.type !==
                                            vAttribute.id && (
                                            <option value={vAttribute.id}>
                                                {vAttribute.name}
                                            </option>
                                        )
                                )}
                                {/* <option value={2}> Enumeración</option>
                                <option value={3}> Fecha</option>
                                <option value={4}> Númerico</option> */}
                            </select>
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="is_unique"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Es Único
                        <div className="mt-1">
                            <input
                                id="is_unique"
                                type="checkbox"
                                checked={isUniqueChecked}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('is_unique', {})}
                                // Format text to be uppercase and spaces as _
                                onChange={handleCheckboxUnique}
                            />
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="parent-attribute"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Relación
                        <div className="mt-1">
                            <select
                                id="parent-attribute"
                                defaultValue={
                                    attributeData.data.client_relationship_type
                                }
                                onChange={handleChange}
                            >
                                <option
                                    value="null"
                                    data-unique={attributeData.data.is_unique}
                                >
                                    Seleccione una opción
                                </option>

                                {personsRelationships.status === 'loading' && (
                                    <option> cargando ...</option>
                                )}
                                {personsRelationships.data.length > 0 &&
                                    personsRelationships.data.map((e) => (
                                        <option
                                            key={e.id}
                                            data-unique={e.is_unique}
                                            value={e.id}
                                            selected={
                                                attributeData.data
                                                    .client_relationship_type ===
                                                e.id
                                            }
                                        >
                                            {e.name}
                                        </option>
                                    ))}
                            </select>
                            {/* <select
                                id="parent-attribute"
                                // onChange={handleChange}
                                {...register('client_relationship_type', {})}
                                required
                            >
                                {personsRelationships.status === 'loading' && (
                                    <option> cargando ...</option>
                                )}
                                <option> Seleccione un valor ...</option>
                                {personsRelationships.data.length > 0 &&
                                    personsRelationships.data.map((e) => (
                                        <option
                                            key={e.id}
                                            data-unique={e.is_unique}
                                            value={e.id}
                                        >
                                            {e.name}
                                        </option>
                                    ))}
                            </select> */}
                        </div>
                    </label>
                    {/* {isUnique === 'true' && (
                        <p className="text-red-600 mb-2">
                            Atributo padre es único
                        </p>
                    )} */}
                </div>
                {entity === 4 && personsRelationshipAttributes && (
                    <div className="mt-3">
                        <label
                            htmlFor="parent-attribute"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Attributo Padre
                            <div className="mt-1">
                                <select
                                    id="parent-attribute"
                                    defaultValue={
                                        attributeData.data.parent_attribute_id
                                    }
                                    onChange={handleChangeParent}
                                >
                                    <option
                                        value="null"
                                        data-unique={
                                            attributeData.data.is_unique
                                        }
                                    >
                                        Seleccione una opción
                                    </option>

                                    {personsRelationships.status ===
                                        'loading' && (
                                        <option> cargando ...</option>
                                    )}

                                    {personsRelationshipAttributes.data.length >
                                        0 &&
                                        personsRelationshipAttributes.data.map(
                                            (e) => (
                                                <option
                                                    key={e.id}
                                                    data-unique={e.is_unique}
                                                    value={e.id}
                                                    selected={
                                                        attributeData.data
                                                            .parent_attribute_id ===
                                                        e.id
                                                    }
                                                >
                                                    {e.name}
                                                </option>
                                            )
                                        )}
                                </select>
                            </div>
                        </label>
                    </div>
                )}

                <div className="mt-4">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('attributes.create.error')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('attributes.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export function EditCompanyAttributeRelationship({
    create,
    show,
    entity,
    toggle,
    attributeData,
}: {
    entity: number;
    create: ({
        label,
        name,
    }: {
        id: string;
        label: string;
        name: string;
        type: number | undefined;
        is_unique: boolean;
        parent_attribute_id: number | null | undefined;
        company_relationship_type: number | null | undefined;
    }) => Promise<void>;
    show: boolean;
    toggle: () => void;
    attributeData: any;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormDataCompany>();

    const { t } = useTranslation();

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [isUniqueChecked, setIsUniqueChecked] = useState(
        attributeData.data.is_unique
    );

    const personsRelationships = useSelector(
        (state: RootState) => state.personBusinessRelationships
    );

    const personsRelationshipAttributes = useSelector(
        (state: RootState) => state.companiesRelationshipsAttributes
    );

    // eslint-disable-next-line
    const handleCheckboxUnique = (e: any) => {
        setIsUniqueChecked(!isUniqueChecked);
        setValue(e.target.name, isUniqueChecked);
    };

    // eslint-disable-next-line
    const handleChange = (e: any) => {
      setValue('company_relationship_type', e.target.value);
    };
    const handleChangeParent = (e: any) => {
      // setIsUnique(e.target.selectedOptions[0].getAttribute('data-unique'));
      setValue('parent_attribute_id', e.target.value);
        };
    useEffect(() => {
        if (!show) {
            reset();

            setAddRequestStatus('idle');
        }
    }, [show, reset]);
    const dispatch = useDispatch();

    const onSubmit = async (data: FormDataCompany) => {
        let typeData = data.type;
        if (typeData.match(/^-?\d+$/)) {
            typeData = Number(data.type);
        } else {
            typeData = validTypeAttributes.find(
                (att) => att.name === data.type
            )?.id;
        }
        const body = {
            id: attributeData.data.id,
            name: data.name,
            label: data.name,
            type: typeData,
            is_unique: Boolean(data.is_unique),
            parent_attribute_id: !data.parent_attribute_id
                ? attributeData.data.parent_attribute_id
                : Number(data.parent_attribute_id),
            company_relationship_type: !data.company_relationship_type
                ? attributeData.data.company_relationship_type
                : Number(data.company_relationship_type),
        };
        try {
            setAddRequestStatus('loading');
            await create(body);
            setAddRequestStatus('success');
            dispatch(resetValuesCompaniesRelAttribute());
            toggle();
            reset();
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };

    return (
        <div className="mb-20">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.name')}
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                defaultValue={attributeData.data.name}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('name', {
                                    required: true,
                                })}
                                // Format text to be uppercase and spaces as _
                                onChange={(e) => {
                                    setValue(
                                        'name',
                                        e.target.value
                                            .toUpperCase()
                                            .replace(/ /g, '_')
                                    );
                                }}
                            />
                            {errors.name && (
                                <p className="text-red-600 ml-2">
                                    {errors.name.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de atributo
                        <div className="mt-1">
                            <select
                                id="type"
                                // onChange={handleChange}
                                {...register('type', {})}
                                required
                            >
                                <option defaultValue={attributeData.data.type}>
                                    {validTypeAttributes
                                        .filter(
                                            (attType) =>
                                                attType.id ===
                                                attributeData.data.type
                                        )
                                        .map(
                                            (vAttribute: ValidAttributes) =>
                                                vAttribute.name
                                        )}
                                </option>
                                {validTypeAttributes.map(
                                    (vAttribute: ValidAttributes) =>
                                        attributeData.data.type !==
                                            vAttribute.id && (
                                            <option value={vAttribute.id}>
                                                {vAttribute.name}
                                            </option>
                                        )
                                )}
                                {/* <option value={2}> Enumeración</option>
                                <option value={3}> Fecha</option>
                                <option value={4}> Númerico</option> */}
                            </select>
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="is_unique"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Es Único
                        <div className="mt-1">
                            <input
                                id="is_unique"
                                type="checkbox"
                                checked={isUniqueChecked}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('is_unique', {})}
                                // Format text to be uppercase and spaces as _
                                onChange={handleCheckboxUnique}
                            />
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="parent-attribute"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Relación
                        <div className="mt-1">
                            <select
                                id="parent-attribute"
                                defaultValue={
                                    attributeData.data.company_relationship_type
                                }
                                onChange={handleChange}
                            >
                                <option
                                    value="null"
                                    data-unique={attributeData.data.is_unique}
                                >
                                    Seleccione una opción
                                </option>

                                {personsRelationships.status === 'loading' && (
                                    <option> cargando ...</option>
                                )}
                                {personsRelationships.data.length > 0 &&
                                    personsRelationships.data.map((e) => (
                                        <option
                                            key={e.id}
                                            data-unique={e.is_unique}
                                            value={e.id}
                                            selected={
                                                attributeData.data
                                                    .company_relationship_type ===
                                                e.id
                                            }
                                        >
                                            {e.name}
                                        </option>
                                    ))}
                            </select>
                            {/* <select
                                id="parent-attribute"
                                // onChange={handleChange}
                                {...register('client_relationship_type', {})}
                                required
                            >
                                {personsRelationships.status === 'loading' && (
                                    <option> cargando ...</option>
                                )}
                                <option> Seleccione un valor ...</option>
                                {personsRelationships.data.length > 0 &&
                                    personsRelationships.data.map((e) => (
                                        <option
                                            key={e.id}
                                            data-unique={e.is_unique}
                                            value={e.id}
                                        >
                                            {e.name}
                                        </option>
                                    ))}
                            </select> */}
                        </div>
                    </label>
                    {/* {isUnique === 'true' && (
                        <p className="text-red-600 mb-2">
                            Atributo padre es único
                        </p>
                    )} */}
                </div>
                {entity === 5 && personsRelationshipAttributes && (
                    <div className="mt-3">
                        <label
                            htmlFor="parent-attribute"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Attributo Padre
                            <div className="mt-1">
                                <select
                                    id="parent-attribute"
                                    defaultValue={
                                        attributeData.data.parent_attribute_id
                                    }
                                    onChange={handleChangeParent}
                                >
                                    <option
                                        value="null"
                                        data-unique={
                                            attributeData.data.is_unique
                                        }
                                    >
                                        Seleccione una opción
                                    </option>

                                    {personsRelationships.status ===
                                        'loading' && (
                                        <option> cargando ...</option>
                                    )}

                                    {personsRelationshipAttributes.data.length >
                                        0 &&
                                        personsRelationshipAttributes.data.map(
                                            (e) => (
                                                <option
                                                    key={e.id}
                                                    data-unique={e.is_unique}
                                                    value={e.id}
                                                    selected={
                                                        attributeData.data
                                                            .parent_attribute_id ===
                                                        e.id
                                                    }
                                                >
                                                    {e.name}
                                                </option>
                                            )
                                        )}
                                </select>
                            </div>
                        </label>
                    </div>
                )}

                <div className="mt-4">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('attributes.create.error')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('attributes.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </form>
        </div>
    );
}

// export function CreatePortfolioPortfolioAttributeRelationship({
//     create,
//     show,
//     entity,
// }: {
//     entity: number;
//     create: ({
//         label,
//         name,
//     }: {
//         label: string;
//         name: string;
//         index: number;
//         type: number;
//         is_unique: boolean;
//         parent_attribute_id: number;
//         portfolio_portfolio_relationship_type: number;
//     }) => Promise<void>;
//     show: boolean;
// }) {
//     const {
//         register,
//         handleSubmit,
//         formState: { errors },
//         reset,
//         setValue,
//     } = useForm<FormData>();

//     const { t } = useTranslation();

//     const [addRequestStatus, setAddRequestStatus] = useState('idle');
//     const [isUniqueChecked, setIsUniqueChecked] = useState(false);
//     const [isUnique, setIsUnique] = useState('false');

//     const personsRelationships = useSelector(
//         (state: RootState) => state.portfolioPortfolioRelationships
//     );

//     const personsRelationshipAttributes = useSelector(
//         (state: RootState) => state.portfoliosPortfolioRelationshipsAttributes
//     );

//     // eslint-disable-next-line
//     const handleCheckboxUnique = (e: any) => {
//         setIsUniqueChecked(!isUniqueChecked);
//         setValue(e.target.name, isUniqueChecked);
//     };

//     // eslint-disable-next-line
//     const handleChange = (e: any) => {
//         setIsUnique(e.target.selectedOptions[0].getAttribute('data-unique'));

//         setValue('parent_attribute_id', e.target.value);
//     };
//     useEffect(() => {
//         if (!show) {
//             reset();

//             setAddRequestStatus('idle');
//         }
//     }, [show, reset]);

//     const onSubmit = async (data: FormData) => {
//         if (isUnique === 'false' || isUnique === null) {
//             const body = {
//                 name: data.name,
//                 label: data.name,
//                 index: data.index,
//                 type: Number(data.type),
//                 is_unique: Boolean(data.is_unique),
//                 parent_attribute_id: Number(data.parent_attribute_id),
//                 portfolio_portfolio_relationship_type: Number(
//                     data.client_relationship_type
//                 ),
//             };
//             try {
//                 setAddRequestStatus('loading');
//                 await create(body);
//                 setAddRequestStatus('success');
//                 reset();
//             } catch (error) {
//                 // console.log(error);
//                 setAddRequestStatus('error');
//             }
//         } else {
//             setAddRequestStatus('error');
//         }
//     };

//     return (
//         <div className="mb-20">
//             <form onSubmit={handleSubmit(onSubmit)}>
//                 <div>
//                     <label
//                         htmlFor="name"
//                         className="block text-sm font-medium text-gray-700"
//                     >
//                         {t('common.name')}
//                         <div className="mt-1">
//                             <input
//                                 id="name"
//                                 type="text"
//                                 className={`${
//                                     errors.name
//                                         ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
//                                         : ''
//                                 }`}
//                                 {...register('name', {
//                                     required: true,
//                                 })}
//                                 // Format text to be uppercase and spaces as _
//                                 onChange={(e) => {
//                                     setValue(
//                                         'name',
//                                         e.target.value
//                                             .toUpperCase()
//                                             .replace(/ /g, '_')
//                                     );
//                                 }}
//                             />
//                             {errors.name && (
//                                 <p className="text-red-600 ml-2">
//                                     {errors.name.type === 'required' &&
//                                         t('common.errors.fieldRequired')}
//                                 </p>
//                             )}
//                         </div>
//                     </label>
//                 </div>
//                 <div className="mt-3">
//                     <label
//                         htmlFor="type"
//                         className="block text-sm font-medium text-gray-700"
//                     >
//                         Tipo de atributo
//                         <div className="mt-1">
//                             <select
//                                 id="type"
//                                 // onChange={handleChange}
//                                 {...register('type', {})}
//                                 required
//                             >
//                                 <option> Seleccione un valor ...</option>
//                                 {validTypeAttributes.map(
//                                     (vAttribute: ValidAttributes) => (
//                                         <option value={vAttribute.id}>
//                                             {' '}
//                                             {vAttribute.name}
//                                         </option>
//                                     )
//                                 )}
//                                 {/* <option value={2}> Enumeración</option>
//                                 <option value={3}> Fecha</option>
//                                 <option value={4}> Númerico</option> */}
//                             </select>
//                             {/* <input
//                                 id="type"
//                                 type="number"
//                                 defaultValue={1}
//                                 min={1}
//                                 max={4}
//                                 className={`${
//                                     errors.name
//                                         ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
//                                         : ''
//                                 }`}
//                                 {...register('type', {})}

//                                 // Format text to be uppercase and spaces as _
//                             /> */}
//                         </div>
//                     </label>
//                 </div>
//                 <div className="mt-3">
//                     <label
//                         htmlFor="is_unique"
//                         className="block text-sm font-medium text-gray-700"
//                     >
//                         Es Único
//                         <div className="mt-1">
//                             <input
//                                 id="is_unique"
//                                 type="checkbox"
//                                 checked={isUniqueChecked}
//                                 className={`${
//                                     errors.name
//                                         ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
//                                         : ''
//                                 }`}
//                                 {...register('is_unique', {})}
//                                 // Format text to be uppercase and spaces as _
//                                 onChange={handleCheckboxUnique}
//                             />
//                         </div>
//                     </label>
//                 </div>
//                 <div className="mt-3">
//                     <label
//                         htmlFor="parent-attribute"
//                         className="block text-sm font-medium text-gray-700"
//                     >
//                         Relación
//                         <div className="mt-1">
//                             <select
//                                 id="parent-attribute"
//                                 // onChange={handleChange}
//                                 {...register('client_relationship_type', {})}
//                                 required
//                             >
//                                 {personsRelationships.status === 'loading' && (
//                                     <option> cargando ...</option>
//                                 )}
//                                 <option> Seleccione un valor ...</option>
//                                 {personsRelationships.data.length > 0 &&
//                                     personsRelationships.data.map((e) => (
//                                         <option
//                                             key={e.id}
//                                             data-unique={e.is_unique}
//                                             value={e.id}
//                                         >
//                                             {e.name}
//                                         </option>
//                                     ))}
//                             </select>
//                         </div>
//                     </label>
//                     {isUnique === 'true' && (
//                         <p className="text-red-600 mb-2">
//                             Atributo padre es único
//                         </p>
//                     )}
//                 </div>
//                 {entity === 4 && personsRelationshipAttributes && (
//                     <div className="mt-3">
//                         <label
//                             htmlFor="parent-attribute"
//                             className="block text-sm font-medium text-gray-700"
//                         >
//                             Attributo Padre
//                             <div className="mt-1">
//                                 <select
//                                     id="parent-attribute"
//                                     onChange={handleChange}
//                                 >
//                                     {personsRelationshipAttributes.status ===
//                                         'loading' && (
//                                         <option> cargando ...</option>
//                                     )}
//                                     <option> Seleccione un valor ...</option>
//                                     {personsRelationshipAttributes.data.length >
//                                         0 &&
//                                         personsRelationshipAttributes.data.map(
//                                             (e) => (
//                                                 <option
//                                                     key={e.id}
//                                                     data-unique={e.is_unique}
//                                                     value={e.id}
//                                                 >
//                                                     {e.name}
//                                                 </option>
//                                             )
//                                         )}
//                                 </select>
//                             </div>
//                         </label>
//                         {isUnique === 'true' && (
//                             <p className="text-red-600 mb-2">
//                                 Atributo padre es único
//                             </p>
//                         )}
//                     </div>
//                 )}

//                 <div className="mt-4">
//                     {addRequestStatus === 'error' && (
//                         <p className="text-red-600 mb-2">
//                             {t('attributes.create.error')}
//                         </p>
//                     )}
//                     {addRequestStatus === 'success' && (
//                         <p className="text-green-600 mb-2">
//                             {t('attributes.create.success')}
//                         </p>
//                     )}
//                     <button
//                         disabled={addRequestStatus === 'loading'}
//                         type="submit"
//                         className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
//                     >
//                         {addRequestStatus === 'loading'
//                             ? t('wait.message')
//                             : t('common.save')}
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );
// }
