/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import LoadingAnimated from '../../../components/elements/Loading';

import AttributesTable from './components/AttributesTable';
import RelationShipTable from './components/RelationShipTable';
import CompanyRelTable from './components/CompanyRelTable';
import {
    addPortfolioAttributes,
    fetchSinglePortfolio,
    resetSinglePortfolio,

} from '../../../modules/portfolios/features/singlePortfolioSlice';
// import { IPortfolioAttribute } from '../../../modules/portfolios-attributes/interfaces';

import { fetchPortfolioAttributes, resetPortfolioAttribute } from '../../../modules/portfolios-attributes/features/portfolioAttributesSlice';
import Modal from '../../../components/Modal';
import { Button } from '../../../components/elements/Buttons';
import PortfolioPortRelTable from './components/PortfolioPortRelTable';
import { fetchPortfoliosEnum } from '../../../modules/portfolios-enums/portfoliosEnum';
import { TypesAttribute } from '../../../components/enums/enums';
import { validateDate, validateType } from '../../../utils/helpers';
import UpdateEntity from './components/UpdateEntity';
import { getPortfolio } from '../../../modules/portfolios/features/getPortfolioEntitySlice';
import DeleteEntity from './components/DeleteEntity';
import { resetDeletePersonPortfolioRelationship } from '../../../modules/person-portfolio-relationships/features/deletePersonPortfolioRelationshipSlice';
import { resetDeleteCompanyPortfolioRelationship } from '../../../modules/business-relationship/businessPortfolioRelSlice';

type FormData = {
    value: any;
    name: string;
    index: number;
}; 

export default function HomeTable() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
		watch,
        setValue
    } = useForm<FormData>();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const { id } = params;

    const singlePortfolio = useSelector(
        (state: RootState) => state.singlePortfolio
    );

    const portfolioAttributes = useSelector(
        (state: RootState) => state.portfolioAttributes
    );
    const singlePortfoliosEnum = useSelector(
        (state: RootState) => state.portfoliosEnums
    );
    const portfolioPersonRel = useSelector(
        (state: RootState) => state.deletePersonPortfolioRelationship
    );
    const portfolioCompanyRel = useSelector(
        (state: RootState) => state.deleteCompanyPortfolioRelationship
    );
    const portfolioPortfolioRel = useSelector(
        (state: RootState) => state.deletePortfolioPortfolioRelationship
    );
    const [showCreate, setShowCreate] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);

    // const [getAvailableAttributes, setGetAvailableAttributes] = useState(true);

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    // const [availableAttributes, setAvailableAttributes] = useState<
    //     IPortfolioAttribute[]
    // >([]);
    // const [newAttributeStatus, setNewAttributeStatus] = useState('idle');
    const [isEnum, setIsEnum] = useState(false);
    const [isUnique, setIsUnique] = useState(false);

    const [idAttribute, setIdAttribute] = useState('');

    useEffect(() => {
        if (portfolioAttributes.status === 'idle') {
            dispatch(fetchPortfolioAttributes());
			dispatch(getPortfolio(Number(id)))

        }
    }, [dispatch, id, portfolioAttributes.status]);

    useEffect(() => {
        if (singlePortfolio.status === 'idle' || id !== singlePortfolio.id) {
            dispatch(fetchSinglePortfolio(id ?? ''));
        }
    }, [singlePortfolio.status, dispatch, id, singlePortfolio.id]);

    useEffect(() => {
        if (portfolioAttributes.status === 'deleted') {
            setAddRequestStatus('idle');
            dispatch(resetSinglePortfolio());
            dispatch(resetPortfolioAttribute());
            dispatch(fetchSinglePortfolio(id ?? ''));
        }
        if (portfolioAttributes.status === 'error') {
            setAddRequestStatus('idle');
        }
    }, [addRequestStatus, dispatch, id, portfolioAttributes.status]);

       useEffect(() => {
           if (portfolioPersonRel.status === 'success') {
               setAddRequestStatus('idle');
               dispatch(resetSinglePortfolio());
               dispatch(resetPortfolioAttribute());
               dispatch(resetDeletePersonPortfolioRelationship());
               dispatch(fetchSinglePortfolio(id ?? ''));

               reset();
           }
       }, [dispatch, id, portfolioPersonRel.status, reset]);
       useEffect(() => {
           if (portfolioCompanyRel.status === 'success') {
               setAddRequestStatus('idle');
               dispatch(resetSinglePortfolio());
               dispatch(resetPortfolioAttribute());
               dispatch(resetDeleteCompanyPortfolioRelationship());
               dispatch(fetchSinglePortfolio(id ?? ''));

               reset();
           }
       }, [dispatch, id, portfolioCompanyRel.status, portfolioPersonRel.status, reset]);
       useEffect(() => {
           if (portfolioPortfolioRel.status === 'success') {
               setAddRequestStatus('idle');
               dispatch(resetSinglePortfolio());
               dispatch(resetPortfolioAttribute());
               dispatch(resetDeletePersonPortfolioRelationship());
               dispatch(fetchSinglePortfolio(id ?? ''));

               reset();
           }
       }, [dispatch, id, portfolioPortfolioRel.status, reset]);

	const [type, setType] = useState(0);

	const value = watch('value');
	const [msgError, setMsgError] = useState('')

    const onSubmit = async (data: FormData) => {
        try {

            if(data.value === 'N/A'){
				// if(singlePortfoliosEnum.data && singlePortfoliosEnum.data[0].enum.length > 0){

				// 	const enumNA = singlePortfoliosEnum.data[0].enum.filter((e: { name: string; }) => e.name.toUpperCase() === 'N/A')

				// 	if(enumNA.length > 0){
				// 		// eslint-disable-next-line no-param-reassign
				// 		data.index = data.index || 1;

				// 		setAddRequestStatus('loading');
				// 		await dispatch(
				// 			addPortfolioAttributes({
				// 				id: id ?? '',
				// 				attributes: [data],
				// 			})
				// 		);
		
				// 		await  dispatch(fetchSinglePortfolio(id ?? ''));
				// 		// await create(body);
				// 		setAddRequestStatus('success');
				// 		reset();
				// 		setShowCreate(false);
				// 	} else{
				// 		await dispatch(
				// 			createPortfolioAttributeEnum({
				// 				name: data.value,
				// 				attribute: Number(idAttribute),
				// 				// client_relationship_attribute: 0,
				// 			})
				// 		);
				// 		setValue('index', 1);
				// 		setAddRequestStatus('loading');
				// 		await dispatch(
				// 			addPortfolioAttributes({
				// 				id: id ?? '',
				// 				attributes: [data],
				// 			})
				// 		);
				// 		// dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
				// 		// await create(body);
				// 		setAddRequestStatus('success');
				// 		reset();
				// 		setShowCreate(false);					
				// 	}
				// } else {
				// 	await dispatch(
				// 		createPortfolioAttributeEnum({
				// 			name: data.value,
				// 			attribute: Number(idAttribute),
				// 			// client_relationship_attribute: 0,
				// 		})
				// 	);
				// 	setValue('index', 1);
				// 	setAddRequestStatus('loading');
				// 	await dispatch(
				// 		addPortfolioAttributes({
				// 			id: id ?? '',
				// 			attributes: [data],
				// 		})
				// 	);
				// 	await dispatch(fetchSinglePortfolio(id ?? ''));
				// 	// await create(body);
				// 	setAddRequestStatus('success');
				// 	reset();
				// 	setShowCreate(false);	
				// }

            }else{
				if(type === TypesAttribute.Number){			
					// eslint-disable-next-line no-param-reassign
					data.value = Number(data.value)
				}
				if(type === TypesAttribute.Date ){
					
					const splitDate = value.split('-')
					const isValidDate = validateDate(splitDate, value)
					
					if(isValidDate){
						data.value = isValidDate
					}else{
						setMsgError('Formato fecha invalido debe ingresar dd-mm-yyyy')
					}

			
				}
                setAddRequestStatus('loading');
                await dispatch(
                    addPortfolioAttributes({
                        id: id ?? '',
                        attributes: [data],
                    })
                ).unwrap();
                await dispatch(fetchSinglePortfolio(id ?? ''));
                // await create(body);
                setAddRequestStatus('success');
                reset();
                setShowCreate(false);
            }

        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };

	useEffect(() => {
		if(addRequestStatus === 'success'){
		  setAddRequestStatus('idle')  
		  dispatch(fetchSinglePortfolio(id ?? ''))
		}
	}, [addRequestStatus, dispatch, id]);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('value', '')
        setIsEnum(false)
        setIsUnique(false)

		if(e.target.selectedOptions[0].getAttribute('data-type') === "3"){
			setType(3)
		}
		if(e.target.selectedOptions[0].getAttribute('data-type') === "4"){
			setType(4)
		}
        if (e.target.selectedOptions[0].getAttribute('data-unique') === 'true') {
            setIsUnique(true)
        }
        if(e.target.selectedOptions[0].getAttribute('data-enum') === "true"){
            setIsEnum(true)
            setIdAttribute(
                e.target.selectedOptions[0].getAttribute(
                    'data-id'
                ) as SetStateAction<string>
            );
        }

    }

    useEffect(() => {
        if (isEnum) {
            dispatch(fetchPortfoliosEnum({ id: idAttribute }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnum, idAttribute]);

    return (
        <div className="relative mb-20">
            <PageHeader title={t('investment.title')} />

            {singlePortfolio.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {singlePortfolio.status === 'success' && (
                <>
                    <div className="grid justify-items-stretch mb-12">
                        <div className="flex justify-self-end">
						<button
                        onClick={() => setShowDelete(true)}
                        type="button"
                        className="w-full ml-2 flex items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-700	 hover:bg-rose-800	focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        Eliminar Entidad
                    </button>
                            <button
                                onClick={() => setShowUpdate(true)}
                                type="button"
                                className="ml-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                Actualizar Entidad
                            </button>
                        </div>
                    </div>

                    <div>
                        <Button
                            label={t('attributes.create.addNew')}
                            type="button"
                            onClick={() => setShowCreate(!showCreate)}
                        />
                    </div>
                    <AttributesTable
                        clientId={id || ''}
                        data={singlePortfolio.attributes}
                    />
            
                    <CompanyRelTable data={singlePortfolio.businessRelations} clientId={id ? parseInt(id, 10) : 0} />
                    <RelationShipTable data={singlePortfolio.personRelations} clientId={id ? parseInt(id, 10) : 0}/>
                    <PortfolioPortRelTable data={singlePortfolio.portfolioRelations} clientId={id ? parseInt(id, 10) : 0} />
                    
                    {showCreate && (
                        <Modal
                            setShowModal={setShowCreate}
                            onClose={() => setShowCreate(false)}
                            className="h-[500px]"
                        >
                            <div className="px-4">
                                <h3 className="text-center text-2xl font-bold mb-10">
                                    {t('attributes.create.addNew')}
                                </h3>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {/* START Attributes Selection */}
                                        <div className="w-full mt-4 mb-4">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t('attributes.title')} *
                                                <div className="mt-1">
                                                    <select
                                                        id="name"
                                                        className={`${
                                                            errors.name
                                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                : ''
                                                        }`}
                                                        {...register('name', {
                                                            required: true,
                                                            onChange: handleChange
                                                        })}
                                                    >
                                                        <option value="">
                                                            {t(
                                                                'attributes.create.selectAnAttribute'
                                                            )}
                                                        </option>
                                                        {portfolioAttributes.status ===
                                                            'success' &&
                                                            portfolioAttributes.data.length >
                                                                0 &&
																portfolioAttributes.data.map(
                                                                (attribute) => (
                                                                    <option
                                                                        key={
                                                                            attribute.id
                                                                        }
                                                                        value={
                                                                            attribute.name
                                                                        }
                                                                        data-enum={
                                                                            attribute.type === 2 ? "true" : "false"
                                                                        }
																		data-type={
																			attribute.type
																		}
                                                                        data-unique={
                                                                            attribute.is_unique
                                                                                ? 'true'
                                                                                : 'false'
                                                                        }
                                                                        data-id={
                                                                            attribute.id
                                                                        }
                                                                    >
                                                                        {
                                                                            attribute.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </select>
                                                </div>
                                            </label>
                                            {errors.name && (
                                                <p className="text-red-600 ml-2">
                                                    {t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                        {/* END Attributes Selection */}
                                        
                                        {isEnum ? (
                                            <div className="w-full mt-4 mb-4">
                                                <label
                                                    htmlFor="value"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Valores de atributo *
                                                    <div className="mt-1">
                                                        <select
                                                            id="value"
                                                            className={`${
                                                                errors.value
                                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                    : ''
                                                            }`}
                                                            {...register(
                                                                'value',
                                                                {
                                                                    required:
                                                                        true,

                                                                }
                                                            )}
                                                        >
                                                            <option value="">
                                                                {t(
                                                                    'attributes.create.selectAnAttribute'
                                                                )}
                                                            </option>
                                                            {singlePortfoliosEnum.status ===
                                                                'success' &&
                                                                singlePortfoliosEnum
                                                                    .data
                                                                    .length >
                                                                    0 &&
                                                                    singlePortfoliosEnum.data.map(
                                                                    (
                                                                        attribute
                                                                    ) =>
                                                                    attribute.enum.length > 0 
                                                                    ?
                                                                        attribute.enum.map(
                                                                            (
                                                                                e
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        e.id
                                                                                    }
                                                                                    value={
                                                                                        e.name
                                                                                    }
                                                                                    data-enum={
                                                                                        attribute.type ===
                                                                                        2
                                                                                            ? 'true'
                                                                                            : 'false'
                                                                                    }
                                                                                    data-unique={
                                                                                        attribute.is_unique
                                                                                            ? 'true'
                                                                                            : 'false'
                                                                                    }
                                                                                    data-id={
                                                                                        e.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        e.name
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )
                                                                        :                                               
                                                                        (<option key={attribute.id} value="N/A">
                                                                        No existen valores
                                                                        </option>)
                                                                )}
                                                        </select>
                                                    </div>
                                                </label>

                                                {errors.name && (
                                                    <p className="text-red-600 ml-2">
                                                        {t(
                                                            'common.errors.fieldRequired'
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                            <label
                                                htmlFor="value"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t('common.value')} *
                                                <div className="mt-1">
                                                    <input
                                                        id="value"
														type={validateType(type)}
                                                        className={`${
                                                            errors.value
                                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                : ''
                                                        }`}
                                                        {...register('value', {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors.value && (
                                                        <p className="text-red-600 ml-2">
                                                            {errors.value
                                                                .type ===
                                                                'required' &&
                                                                t(
                                                                    'common.errors.fieldRequired'
                                                                )}
                                                        </p>
                                                    )}
													{
															msgError !== '' && 
																<p className="text-red-600 ml-2">
																	{msgError}
																</p>
															
														}
                                                </div>
                                            </label>
                                        </div>
                                        )}
                                        {/*  */}
                                        

                                        {/* {
                                        isEnum === true &&  isUnique === false &&
                                        <div className="mt-3">                                        
                                            <label
                                                    htmlFor="index"
                                                    className="block text-sm font-medium text-gray-700"
                                                >   Indice
                                                    <div className="mt-1">
                                                        <input
                                                            {...register('index', {
                                                            })}
                                                            type="number"
                                                            min={1}
                                                            defaultValue={1}
                                                            id="index"
                                                            className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
                                                        />
                                                    </div>
                                            </label>
                                        </div>                
                                        } */}
										{
											isUnique === false &&
											<div className="mt-3">
											<label
												htmlFor="index"
												className="block text-sm font-medium text-gray-700"
											>
												{' '}
												Indice
												<div className="mt-1">
													<input
														{...register(
															'index',
															{}
														)}
														type="number"
														min={1}
														defaultValue={1}
														id="index"
														className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
													/>
												</div>
											</label>
										</div>
										}
                                        <div className="mt-2">
                                            {addRequestStatus === 'error' && (
                                                <p className="text-red-600 mb-2">
                                                    {t(
                                                        'attributes.create.error'
                                                    )}
                                                </p>
                                            )}
                                            {addRequestStatus === 'success' && (
                                                <p className="text-green-600 mb-2">
                                                    {t(
                                                        'attributes.create.success'
                                                    )}
                                                </p>
                                            )}
                                            <button
                                                disabled={
                                                    addRequestStatus ===
                                                    'loading'
                                                }
                                                type="submit"
                                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                            >
                                                {addRequestStatus === 'loading'
                                                    ? t('wait.message')
                                                    : t('common.save')}
                                            </button>
                                            <Button
                                                type="button"
                                                variant="link"
                                                className="w-full mt-10"
                                                label={t('common.cancel')}
                                                onClick={() =>
                                                    setShowCreate(false)
                                                }
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    )}
                </>
            )}

				{
				showUpdate &&
					<UpdateEntity  setShowUpdate={setShowUpdate} />
				}
				{
				showDelete &&
				<DeleteEntity  setShowDelete={setShowDelete} />
			}
        </div>
    );
}
