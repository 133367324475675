import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { usersSlice, inviteUserSlice } from './modules/users/features';
import { personsSlice, singlePersonSlice } from './modules/persons/features';
import {
    businessSlice,
    singleBusinessSlice,
} from './modules/business/features';
import {
    portfoliosSlice,
    singlePortfolioSlice,
} from './modules/portfolios/features';

import { personsAttributesSlice } from './modules/persons-attributes/features';
import { businessAttributesSlice } from './modules/business-attributes/features';
import { portfolioAttributesSlice } from './modules/portfolios-attributes/features';

import { personPersonRelationshipSlice } from './modules/person-person-relationships/features';
import { personBusinessRelationshipSlice } from './modules/person-business-relationships/features';
import { personPortfolioRelationshipSlice } from './modules/person-portfolio-relationships/features';
import { businessPortfolioRelationshipSlice } from './modules/business-portfolio-relationships/features';
import { portfolioPortfolioRelationshipsSlice } from './modules/portfolio-portfolio-relationships/features/portfolioPortfolioRelationshipSlice';
import { businessEnumsSlice } from './modules/business-enums/businessEnum';
import { personsEnumsSlice } from './modules/persons-enums/personsEnum';
import { portfoliosEnumsSlice } from './modules/portfolios-enums/portfoliosEnum';
import { personsRelationshipAttributesSlice } from './modules/persons-relationships-attributes/features/personRelationshipAttributeSlice';
import { personsRelationshipAttributesValuesSlice } from './modules/persons-relationships-attributes/features/personRelationshipAttributesValuesSlice';
import { personRelationshipEnumSlice } from './modules/person-relationship-enums/personRelationshipEnum';
import { personsRelationshipAttributesValueSlice } from './modules/persons-relationships-attributes/features/personRelationshipValueSlice';
import { portfoliosPersonsAttributesSlice } from './modules/portfolio-persons-attributes/features/portfoliosPersonsAttributeSlice';
import {
    companiesRelationshipAttributesSlice,
    companiesRelationshipAttributesValuesSlice,
    companyRelationshipAttributesSlice,
} from './modules/company-relationships-attributes/features';
import { companyRelationshipAttributesValueSlice } from './modules/company-relationships-attributes/features/companyRelationshipValueSlice';
import { companyRelationshipEnumSlice } from './modules/companies-relationship-enums/companyRelationshipEnum';
import { portfoliosCompanyAttributesSlice } from './modules/portfolio-companies-attributes/features/portfoliosPersonsAttributeSlice';
import { portfoliosPortfolioAttributesSlice } from './modules/portfolio-portfolio-attributes/features/portfoliosPortfolioAttributeSlice';
import { portfolioPortfoliosRelationshipAttributesValuesSlice } from './modules/portfolio-portfolio-attributes/features/portfoliosPortfoliosRelationshipValues';
import { PortfolioPortfolioRelationshipEnumSlice } from './modules/portfolio-portfolio-attributes/features/portfolioPortfolioAttributeEnumValue';
import { portfolioPortfolioRelationshipAttributesValueSlice } from './modules/portfolio-portfolio-attributes/features/portfolioPortfolioRelationshipValue';
import { portfolioCompaniesRelationshipAttributesValuesSlice } from './modules/portfolio-companies-attributes/features/portfoliosCompaniesRealtionshipValues';
import { PortfolioCompanyRelationshipEnumSlice } from './modules/portfolio-companies-attributes/features/portfolioCompanyAttributeValueEnum';
import { portfolioCompanyRelationshipAttributesValueSlice } from './modules/portfolio-companies-attributes/features/portfolioCompanyRelationshipValue';
import { portfolioPersonsRelationshipAttributesValuesSlice } from './modules/portfolio-persons-attributes/features/portfoliosPersonRelationshipValuesSlice';
import { PortfolioPersonRelationshipEnumSlice } from './modules/portfolio-persons-attributes/features/portfolioPersonAttributeValueEnum';
import { portfolioPersonRelationshipAttributesValueSlice } from './modules/portfolio-persons-attributes/features/portfolioPersonRelationshipValueSlice';
import { personEntitySlice } from './modules/persons/features/personEntitySlice';
import { getPersonEntitySlice } from './modules/persons/features/getPersonEntitySlice';
// import { businessEntitySlice } from './modules/business/features/businessEntity';
// import { portfolioEntitySlice } from './modules/portfolios/features/portfolioEntity';
// import { fetchAllPersonRelationshipEnumSlice } from './modules/portfolio-enums/fetchPersonRelationshipEnum';
import { PortfolioEnumSlice } from './modules/portfolios-enums/portfoliosEntityEnum';
import { fetchAllPortfolioEnumSlice } from './modules/portfolios-enums/fetchPortfolioEnum';

import { businessEnumSlice } from './modules/business-enums/businessEntityEnum';
import { fetchAllBusinessEnumSlice } from './modules/business-enums/fetchBusinessEnum';
import { personEnumSlice } from './modules/persons-enums/personEnum';
import { fetchAllPersonsEnumSlice } from './modules/persons-enums/fetchPersonsEnum';
import { getPortfolioEntitySlice } from './modules/portfolios/features/getPortfolioEntitySlice';
import { getCompanyEntitySlice } from './modules/business/features/getBusinessEntity';
import { businessEntitySlice } from './modules/business/features/businessEntity';
import { portfolioEntitySlice } from './modules/portfolios/features/portfolioEntity';
import { fetchAllPersonRelationshipEnumSlice } from './modules/person-relationship-enums/fetchPersonRelationshipEnum';
import { personCRDRelationshipAttributeEnumSlice } from './modules/person-relationship-enums/personRelationshipEntityEnum';
import { fetchAllCompanyRelationshipEnumSlice } from './modules/company-relationship-enums/fetchCompanyRelationshipEnum';
import { companyCRDRelationshipAttributeEnumSlice } from './modules/company-relationship-enums/companyRelationshipEntityEnum';
import { fetchAllPortfolioPersonRelationshipEnumSlice } from './modules/portfolio-person-relationship-enums/fetchPortfolioPersonRelationshipEnum';
import { portfolioPersonCRDRelationshipAttributeEnumSlice } from './modules/portfolio-person-relationship-enums/portfolioPersonRelationshipEntityEnum';
import { fetchAllPortfolioCompanyRelationshipEnumSlice } from './modules/portfolio-company-relationship-enums/fetchPortfolioCompanyRelationshipEnum';
import { portfolioCompanyCRDRelationshipAttributeEnumSlice } from './modules/portfolio-company-relationship-enums/portfolioCompanyRelationshipEntityEnum';
import { fetchAllPortfolioPortfolioRelationshipEnumSlice } from './modules/portfolio-portfolio-relationship-enums/fetchPortfolioPortfolioRelationshipEnum';
import { portfolioPortfolioCRDRelationshipAttributeEnumSlice } from './modules/portfolio-portfolio-relationship-enums/portfolioPortfolioRelationshipEntityEnum';
import { personsRelationshipAttributesRelValuesSlice } from './modules/persons-relationships-attributes/features/personRelAttributesSlice';
import { companiesRelationshipAttributesRelValuesSlice } from './modules/company-relationships-attributes/features/companyRelAttributesSlice';
import { portfolioPersonRelationshipAttributesRelValuesSlice } from './modules/portfolio-persons-attributes/features/portfolioPersonRelAttributeSlice';
import { portfolioPortfolioRelationshipAttributesRelValuesSlice } from './modules/portfolio-portfolio-attributes/features/portfolioPortfolioRelAttributesSlice';
import { personPersonRelationshipTypeSlice } from './modules/personPersonRelationshipType/personPersonRelationshipTypeSlice';
import { personBusinessRelationshipTypeSlice } from './modules/personBusinessRelationshipType/personBusinessRelationshipTypeSlice';
import { personPortfolioRelationshipTypeSlice } from './modules/personPortfolioRelationshipType/personPortfolioRelationshipTypeSlice';
import { businessPortfolioRelationshipTypeSlice } from './modules/businessPortfolioRelationshipType/businessPortfolioRelationshipTypeSlice';
import { portfolioPortfolioRelationshipTypeSlice } from './modules/portfolioPortfolioRelationshipType/personBusinessRelationshipTypeSlice';
import { deleteCompanyPersonRelationshipSlice } from './modules/business-relationship/businessPersonRelationship';
import { deletePersonPortfolioRelationshipSlice } from './modules/person-portfolio-relationships/features/deletePersonPortfolioRelationshipSlice';
import { deleteBusinessRelationshipSlice } from './modules/person-business-relationships/features/deletePersonBusinessRelationshipSlice';
import { deletePersonPersonRelationshipSlice } from './modules/person-person-relationships/features/deletePersonPersonRelatinonshipSlice';
import { getPersonsAttributesSlice } from './modules/persons-attributes/features/getPersonAttributeSlice';
import { getCompanysAttributesSlice } from './modules/business-attributes/features/getBusinessAttributeSlice';
import { getPortfoliosAttributesSlice } from './modules/portfolios-attributes/features/getPortfolioAttributeSlice';
import { getPersonsPersonAttributesSlice } from './modules/persons-relationships-attributes/features/getPersonPersonAttributeSlice';
import { getCompanyPersonAttributesSlice } from './modules/company-relationships-attributes/features/getCompanyRelationshipAttributes';
import { getPortfolioPersonsAttributesSlice } from './modules/portfolio-persons-attributes/features/getPortfolioPersonAttributeSlice';
import { getPortfolioCompanysAttributesSlice } from './modules/portfolio-companies-attributes/features/getPortfolioCompanyAttributeSlice';
import { getPortfolioPortfolioAttributesSlice } from './modules/portfolio-portfolio-attributes/features/getPortfolioPortfolioAttributeSlice';
import { deleteportfolioPortfolioRelationshipAttributesRelValuesSlice } from './modules/portfolio-portfolio-attributes/features/deletePortfolioPortfolioRelValue';
import { deleteCompanyPortfolioRelationshipAttributesRelValuesSlice } from './modules/business-portfolio-relationships/features/deleteCompanyBusinessAttributeRelSlice';
import { deletePersonsCompanyRelationshipAttributesRelValuesSlice } from './modules/person-business-relationships/features/deletePersonCompanyRelValueSlice';
import { deletePersonsRelationshipAttributesRelValuesSlice } from './modules/persons-relationships-attributes/features/deletePersonRelValudeSlice';
import { deleteCompanyPortfolioRelationshipSlice } from './modules/business-relationship/businessPortfolioRelSlice';
import { deletePortfolioPortfolioRelationshipSlice } from './modules/portfolio-portfolio-relationships/features/deletePortfolioPortfolioRelSlice';
import {  personsRelationshipsFetchAttributesSlice } from './modules/persons-relationships-attributes/features/fetchPersonRelAttributeSlice';
import { fetchCompaniesRelationshipAttributesRelValuesSlice } from './modules/company-relationships-attributes/features/fetchCompanyRelValuesSlice';
import { deletePersonPortfolioRelationshipValueSlice } from './modules/person-portfolio-relationships/features/deletePersonPortfolioRelValueSlice';

export const store = configureStore({
    reducer: {
        business: businessSlice.reducer,
        singleBusiness: singleBusinessSlice.reducer,
        users: usersSlice.reducer,
        userInvite: inviteUserSlice.reducer,
        persons: personsSlice.reducer,
        businessEnums: businessEnumsSlice.reducer,
        personsEnums: personsEnumsSlice.reducer,
        portfoliosEnums: portfoliosEnumsSlice.reducer,
        singlePerson: singlePersonSlice.reducer,
        personPersonRel: deletePersonPersonRelationshipSlice.reducer,
        portfolios: portfoliosSlice.reducer,
        singlePortfolio: singlePortfolioSlice.reducer,
        personsAttributes: personsAttributesSlice.reducer,
        businessAttributes: businessAttributesSlice.reducer,
        portfolioAttributes: portfolioAttributesSlice.reducer,
        personPersonRelationships: personPersonRelationshipSlice.reducer,
        personBusinessRelationships: personBusinessRelationshipSlice.reducer,
        personPortfolioRelationships: personPortfolioRelationshipSlice.reducer,
        businessPortfolioRelationships:
            businessPortfolioRelationshipSlice.reducer,
        portfolioPortfolioRelationships:
            portfolioPortfolioRelationshipsSlice.reducer,

        // PERSONS RELATIONSHIP ATTRIBUTES
        personsRelationshipsAttributes:
            personsRelationshipAttributesSlice.reducer,
        personsRelationshipsAttributesValues:
            personsRelationshipAttributesValuesSlice.reducer,
        personsRelationshipsAttributesEnum: personRelationshipEnumSlice.reducer,
        personsRelationshipValue:
            personsRelationshipAttributesValueSlice.reducer,

        // PERSONS RELATIONSHIP ATTRIBUTES
        PortfoliosPersonsRelationshipsAttributes:
            portfoliosPersonsAttributesSlice.reducer,
        portfolioPersonRelationshipsAttributesValues:
            portfolioPersonsRelationshipAttributesValuesSlice.reducer,
        portfolioPersonRelationshipsAttributesEnum:
            PortfolioPersonRelationshipEnumSlice.reducer,
        portfolioPersonsRelationshipValue:
            portfolioPersonRelationshipAttributesValueSlice.reducer,

        // COMPANIES RELATIONSHIP ATTRIBUTES
        companyRelationshipsAttribute:
            companyRelationshipAttributesSlice.reducer,
        companiesRelationshipsAttributes:
            companiesRelationshipAttributesSlice.reducer,
        companiesRelationshipAttributeValues:
            companiesRelationshipAttributesValuesSlice.reducer,
        companyRelationshipValue:
            companyRelationshipAttributesValueSlice.reducer,
        companiesRelationshipsAttributesEnum:
            companyRelationshipEnumSlice.reducer,

        // PORTFOLIO PORTFOLIO RELATIONSHIP ATTRIBUTES
        portfoliosPortfolioRelationshipsAttributes:
            portfoliosPortfolioAttributesSlice.reducer,

        // PORTFOLIO PORTFOLIO RELATIONSHIP ATTRIBUTES
        portfolioPortfolioRelationshipsAttributesValues:
            portfolioPortfoliosRelationshipAttributesValuesSlice.reducer,
        portfolioPortfolioRelationshipsAttributesEnum:
            PortfolioPortfolioRelationshipEnumSlice.reducer,
        portfolioPortfoliosRelationshipValue:
            portfolioPortfolioRelationshipAttributesValueSlice.reducer,

        // PORTFOLIO COMPANY RELATIONSHIP ATTRIBUTES
        portfoliosCompaniesRelationshipsAttributes:
            portfoliosCompanyAttributesSlice.reducer,

        // COMPANY RELATIONSHIP ATTRIBUTES VALUES
        portfoliosCompanyRelationshipsAttributes:
            portfoliosCompanyAttributesSlice.reducer,
        portfolioCompanyRelationshipsAttributesValues:
            portfolioCompaniesRelationshipAttributesValuesSlice.reducer,
        portfolioCompanyRelationshipsAttributesEnum:
            PortfolioCompanyRelationshipEnumSlice.reducer,
        portfolioCompaniesRelationshipValue:
            portfolioCompanyRelationshipAttributesValueSlice.reducer,

        // ENTITIES
        person: getPersonEntitySlice.reducer,
        personEntity: personEntitySlice.reducer,
        company: getCompanyEntitySlice.reducer,
        businessEntity: businessEntitySlice.reducer,
        portfolio: getPortfolioEntitySlice.reducer,
        portfolioEntity: portfolioEntitySlice.reducer,

        // Person enums
        allPersonsAnums: fetchAllPersonsEnumSlice.reducer,
        personEnum: personEnumSlice.reducer,

        // company enums
        allCompaniesEnums: fetchAllBusinessEnumSlice.reducer,
        companyEnum: businessEnumSlice.reducer,

        // person Relationship enums
        allPersonRelationshipEnums: fetchAllPersonRelationshipEnumSlice.reducer,
        personRelationshipEnum: personCRDRelationshipAttributeEnumSlice.reducer,

        // portfolio enums
        allPortfoliosEnums: fetchAllPortfolioEnumSlice.reducer,
        portfolioEnum: PortfolioEnumSlice.reducer,

        // company Relationship enums
        allCompanyRelationshipEnums:
            fetchAllCompanyRelationshipEnumSlice.reducer,
        companyRelationshipEnum:
            companyCRDRelationshipAttributeEnumSlice.reducer,

        // company Relationship enums
        allPortfolioRelationshipEnums:
            fetchAllPortfolioPersonRelationshipEnumSlice.reducer,
        portfolioRelationshipEnum:
            portfolioPersonCRDRelationshipAttributeEnumSlice.reducer,

        // portfolio company Relationship enums
        allPortfolioCompanyRelationshipEnums:
            fetchAllPortfolioCompanyRelationshipEnumSlice.reducer,
        portfolioCompanyRelationshipEnum:
            portfolioCompanyCRDRelationshipAttributeEnumSlice.reducer,

        // portfolio portfolio Relationship enums
        allPortfolioPortfolioRelationshipEnums:
            fetchAllPortfolioPortfolioRelationshipEnumSlice.reducer,
        portfolioPortfolioRelationshipEnum:
            portfolioPortfolioCRDRelationshipAttributeEnumSlice.reducer,

        personRelAttributes:
            personsRelationshipAttributesRelValuesSlice.reducer,
        companyRelAttributes:
            companiesRelationshipAttributesRelValuesSlice.reducer,
        portfolioPersonRelAttributes:
            portfolioPersonRelationshipAttributesRelValuesSlice.reducer,
        portfolioPortfolioRelAttributes:
            portfolioPortfolioRelationshipAttributesRelValuesSlice.reducer,

        // RelationshipTypes
        personPersonRelationshipType: personPersonRelationshipTypeSlice.reducer,
        personBusinessRelationshipType:
            personBusinessRelationshipTypeSlice.reducer,
        personPortfolioRelationshipType:
            personPortfolioRelationshipTypeSlice.reducer,
        businessPortfolioRelationshipType:
            businessPortfolioRelationshipTypeSlice.reducer,
        portfolioPortfolioRelationshipType:
            portfolioPortfolioRelationshipTypeSlice.reducer,

        deleteCompanyPersonRelationship:
            deleteCompanyPersonRelationshipSlice.reducer,

        deletePersonPortfolioRelationship:
            deletePersonPortfolioRelationshipSlice.reducer,
        // delete company person
        deletePersonCompany: deleteBusinessRelationshipSlice.reducer,
        getPersonsAttributes: getPersonsAttributesSlice.reducer,
        getCompanyAttributes: getCompanysAttributesSlice.reducer,
        getPortfolioAttributes: getPortfoliosAttributesSlice.reducer,
        getPersonPersonAttributes: getPersonsPersonAttributesSlice.reducer,
        getCompanyPersonAttributes: getCompanyPersonAttributesSlice.reducer,
        getPortfolioPersonAttributes:
            getPortfolioPersonsAttributesSlice.reducer,
        getPortfolioCompanyAttributes:
            getPortfolioCompanysAttributesSlice.reducer,
        getPortfolioPortfolioAttributes:
            getPortfolioPortfolioAttributesSlice.reducer,
        deletePortfolioPortfolio:
            deleteportfolioPortfolioRelationshipAttributesRelValuesSlice.reducer,
        deleteCompanyPort:
            deleteCompanyPortfolioRelationshipAttributesRelValuesSlice.reducer,
        deletePersonsCompanyRelationshipAttributesRelValues:
            deletePersonsCompanyRelationshipAttributesRelValuesSlice.reducer,
        deletePersonsRelationshipAttributesRelValues:
            deletePersonsRelationshipAttributesRelValuesSlice.reducer,
        deleteCompanyPortfolioRelationship:
            deleteCompanyPortfolioRelationshipSlice.reducer,
        deletePortfolioPortfolioRelationship:
            deletePortfolioPortfolioRelationshipSlice.reducer,

        personsRelAttributes: personsRelationshipsFetchAttributesSlice.reducer,
        companiesRelAttributes:
            fetchCompaniesRelationshipAttributesRelValuesSlice.reducer,
        deletePersonPortfolioRelVal:
            deletePersonPortfolioRelationshipValueSlice.reducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
