import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonBusinessRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonBusinessRelationState {
    data: IPersonBusinessRelation[];
    status: Status;
    error: string | null;
}

const initialState: PersonBusinessRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deletePersonBusinessRelationships = createAsyncThunk(
    'deleteBusinessRelationships/deletePersonBusinessRelationships',
    async ({ idClient, idRel }: { idClient: number; idRel: string }) => {
        const response = await APIClient.deleteDataEntiy(
            `/company/id/${idClient}/relationship/${idRel}/`
        );
        return response.data.results;
    }
);


export const deleteBusinessRelationshipSlice = createSlice({
    name: 'deleteBusinessRelationships',
    initialState,
    reducers: {
        resetBusinessRelationshipDelete: (state) => {
            state.data = [];
            state.error = null;
            state.status = Status.idle;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deletePersonBusinessRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                deletePersonBusinessRelationships.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                deletePersonBusinessRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetBusinessRelationshipDelete } =
    deleteBusinessRelationshipSlice.actions;
