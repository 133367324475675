import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonPersonRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPersonRelationState {
    data: IPersonPersonRelation[];
    status: Status;
    error: string | null;
}

const initialState: PersonPersonRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deletePersonPersonRelationships = createAsyncThunk(
    'personPersonRelationshipsDelete/deletePersonPersonRelationships',
    async ({ idClient, idRel }: { idClient: number; idRel: string }) => {
        const response = await APIClient.deleteDataEntiy(
            `/client/id/${idClient}/relationship/${idRel}/`
        );
        return response.data.results;
    }
);

export const deletePersonPersonRelationshipSlice = createSlice({
    name: 'personPersonRelationshipsDelete',
    initialState,
    reducers: {
        resetDeletePersonRelationship: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deletePersonPersonRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                deletePersonPersonRelationships.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                deletePersonPersonRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetDeletePersonRelationship } =
    deletePersonPersonRelationshipSlice.actions;
