import { Routes, Route } from 'react-router-dom';

import useAuth from './context/useAuth';

/** UnAuthenticated Pages */
import Landing from './pages/Landing/Landing';
import SignIn from './pages/Landing/SignIn';
import SignUp from './pages/Landing/SignUp';
import ForgotSend from './pages/Landing/Forgot/Send';
import ForgotChange from './pages/Landing/Forgot/Change';
import Loading from './pages/Landing/Loading';

/** Authenticated Pages */
import Dashboard from './pages/Dashboard/Dashboard';
import Users from './pages/Dashboard/Users'; 
import ListUsers from './pages/Dashboard/Users/List';
import Persons from './pages/Dashboard/Persons';
import ListPersons from './pages/Dashboard/Persons/List';
import SinglePerson from './pages/Dashboard/Persons/Single';
import Business from './pages/Dashboard/Business';
import ListBusiness from './pages/Dashboard/Business/List';
import SingleBusiness from './pages/Dashboard/Business/Single';

import Portfolio from './pages/Dashboard/Portfolios';
import PortfolioList from './pages/Dashboard/Portfolios/List';
import SinglePortfolio from './pages/Dashboard/Portfolios/Single';

import PersonsAttributes from './pages/Dashboard/Persons-Attributes';
import PersonsAttributesList from './pages/Dashboard/Persons-Attributes/List';

// ENUMS

import PersonsAttributesEnums from './pages/Dashboard/Persons-Attributes-enum';
import PersonsAttributesListEnums from './pages/Dashboard/Persons-Attributes-enum/List';

import CompaniesAttributesEnum from './pages/Dashboard/Companies-Attributes-enum';
import CompaniesAttributesListEnums from './pages/Dashboard/Companies-Attributes-enum/List';

import PersonRelationshipAttributesEnum from './pages/Dashboard/Person-relationship-Attributes-enum';
import PersonRelationshipListEnums from './pages/Dashboard/Person-relationship-Attributes-enum/List';

import CompanyRelationshipAttributesEnum from './pages/Dashboard/Company-relationship-Attributes-enum';
import CompanyRelationshipListEnums from './pages/Dashboard/Company-relationship-Attributes-enum/List';

import PortfoliosAttributesEnum from './pages/Dashboard/Portfolios-Attributes-enum';
import PortfoliosAttributesListEnums from './pages/Dashboard/Portfolios-Attributes-enum/List';

import PortfolioPersonAttributesEnum from './pages/Dashboard/Portfolio-Person-relationship-Attributes-enum';
import PortfolioPersonAttributesListEnums from './pages/Dashboard/Portfolio-Person-relationship-Attributes-enum/List';

import PortfolioCompanyAttributesEnum from './pages/Dashboard/Portfolio-Company-relationship-Attributes-enum';
import PortfolioCompanyAttributesListEnums from './pages/Dashboard/Portfolio-Company-relationship-Attributes-enum/List';

import PortfolioPortfolioAttributesEnum from './pages/Dashboard/Portfolio-Portfolio-relationship-Attributes-enum';
import PortfolioPortfolioAttributesListEnums from './pages/Dashboard/Portfolio-Portfolio-relationship-Attributes-enum/List';

import BusinessAttributes from './pages/Dashboard/Business-Attributes';
import BusinessAttributesList from './pages/Dashboard/Business-Attributes/List';

import PortfolioAttributes from './pages/Dashboard/Portfolio-Attributes';
import PortfolioAttributesList from './pages/Dashboard/Portfolio-Attributes/List';

import PersonPersonRelationships from './pages/Dashboard/Person-Person-Relationships';
import PersonPersonRelationshipsList from './pages/Dashboard/Person-Person-Relationships/List';

import PersonBusinessRelationships from './pages/Dashboard/Person-Business-Relationships';
import PersonBusinessRelationshipsList from './pages/Dashboard/Person-Business-Relationships/List';

import PersonPortfolioRelationships from './pages/Dashboard/Person-Portfolio-Relationships';
import PersonPortfolioRelationshipsList from './pages/Dashboard/Person-Portfolio-Relationships/List';

import BusinessPortfolioRelationships from './pages/Dashboard/Business-Portfolio-Relationships';
import BusinessPortfolioRelationshipsList from './pages/Dashboard/Business-Portfolio-Relationships/List';

import PortfoliosPortfolioRelationships from './pages/Dashboard/Portfolios-Portfolio-Relationships/index';
import PortfoliosPortfolioRelationshipsList from './pages/Dashboard/Portfolios-Portfolio-Relationships/List';
import PersonRelationshipAttributesList from './pages/Dashboard/persons-relationships/List';

import NotFound from './pages/Dashboard/NotFound';
import PersonRelationshipAttributes from './pages/Dashboard/persons-relationships/index';
import PersonRelationshipAttributesValues from './pages/Dashboard/persons-relationships-values/index';
import PersonRelationshipAttributesValuesList from './pages/Dashboard/persons-relationships-values/List';

import PortfolioPersonRelationshipAttributes from './pages/Dashboard/Portfolio-Person-Attributes/index';
import PortfolioPersonRelationshipAttributesList from './pages/Dashboard/Portfolio-Person-Attributes/List';

import PortfolioPortfolioRelationshipAttributes from './pages/Dashboard/Portfolio-Portfolio-Attributes/index';
import PortfolioPortfolioRelationshipAttributesList from './pages/Dashboard/Portfolio-Portfolio-Attributes/List';

// import PersonRelationshipAttributesValues from './pages/Dashboard/persons-relationships-values/index';
// import PersonRelationshipAttributesValuesList from './pages/Dashboard/persons-relationships-values/List';
// eslint-disable-next-line import/extensions
import CompanyRelationshipAttributes from './pages/Dashboard/companies-relationships-attributes/index';
import CompanyRelationshipAttributesList from './pages/Dashboard/companies-relationships-attributes/List';
import CompanyRelationshipAttributesValues from './pages/Dashboard/compnanies-relationships-values/index';
import CompanyRelationshipAttributesValuesList from './pages/Dashboard/compnanies-relationships-values/List';

// IMPORT PORTFOLIO PERSON VALUES
import PortfolioPersonRelationshipAttributesValues from './pages/Dashboard/portfolio-relationships-values/index';
import PortfolioPersonRelationshipAttributesValuesList from './pages/Dashboard/portfolio-relationships-values/List';

import PortfolioCompanyRelationshipAttributes from './pages/Dashboard/Portfolio-Companies-Attributes/index';
import PortfolioCompanyRelationshipAttributesList from './pages/Dashboard/Portfolio-Companies-Attributes/List';

import PortfolioPortfolioRelationshipAttributesValues from './pages/Dashboard/portfolio-portfolio-relationship-value/index';
import PortfolioPortfolioRelationshipAttributesValuesList from './pages/Dashboard/portfolio-portfolio-relationship-value/List';



import PortfolioCompanyRelationshipAttributesValues from './pages/Dashboard/portfolio-company-relationship-value/index';
import PortfolioCompanyRelationshipAttributesValuesList from './pages/Dashboard/portfolio-company-relationship-value/List';

function AdminRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />}>
                <Route path="/" element={<Persons />}>
                    <Route index element={<ListPersons />} />
                    <Route path="/persons/:id" element={<SinglePerson />} />
                </Route>
                <Route path="company" element={<Business />}>
                    <Route index element={<ListBusiness />} />
                    <Route path="/company/:id" element={<SingleBusiness />} />
                </Route>
                <Route path="portfolio" element={<Portfolio />}>
                    <Route index element={<PortfolioList />} />
                    <Route
                        path="/portfolio/:id"
                        element={<SinglePortfolio />}
                    />
                </Route>
                <Route
                    path="persons-attributes"
                    element={<PersonsAttributes />}
                >
                    <Route index element={<PersonsAttributesList />} />
                </Route>
                <Route
                    path="person-enums"
                    element={<PersonsAttributesEnums />}
                >
                    <Route index element={<PersonsAttributesListEnums />} />
                </Route>
                <Route
                    path="company-enums"
                    element={<CompaniesAttributesEnum />}
                >
                    <Route index element={<CompaniesAttributesListEnums />} />
                </Route>
                
                <Route
                    path="person-relationship-enums"
                    element={<PersonRelationshipAttributesEnum />}
                >
                    <Route index element={<PersonRelationshipListEnums />} />
                </Route>

                <Route
                    path="company-relationship-enums"
                    element={<CompanyRelationshipAttributesEnum />}
                >
                    <Route index element={<CompanyRelationshipListEnums />} />
                </Route>

                <Route
                    path="portfolio-enums"
                    element={<PortfoliosAttributesEnum />}
                >
                    <Route index element={<PortfoliosAttributesListEnums />} />
                </Route>

                <Route
                    path="portfolio-person-relationship-enums"
                    element={<PortfolioPersonAttributesEnum />}
                >
                    <Route index element={<PortfolioPersonAttributesListEnums />} />
                </Route>


                <Route
                    path="portfolio-company-relationship-enums"
                    element={<PortfolioCompanyAttributesEnum />}
                >
                    <Route index element={<PortfolioCompanyAttributesListEnums />} />
                </Route>

                <Route
                    path="portfolio-portfolio-relationship-enums"
                    element={<PortfolioPortfolioAttributesEnum />}
                >
                    <Route index element={<PortfolioPortfolioAttributesListEnums />} />
                </Route>

				{/* PORTFOLIO PERSON RELATIONSHIP ATTRIBUTES */}
                <Route
                    path="portfolios-persons-attributes"
                    element={<PortfolioPersonRelationshipAttributes />}
                >
                    <Route index element={<PortfolioPersonRelationshipAttributesList />} />
                </Route>
				{/* PORTFOLIO COMPANY RELATIONSHIP ATTRIBUTES */}
                <Route
                    path="portfolios-companies-attributes"
                    element={<PortfolioCompanyRelationshipAttributes />}
                >
                    <Route index element={<PortfolioCompanyRelationshipAttributesList />} />
				</Route>

				{/* PORTFOLIO PORTFOLIO RELATIONSHIP ATTRIBUTES */}
                <Route
                    path="portfolios-portfolios-attributes"
                    element={<PortfolioPortfolioRelationshipAttributes />}
                >
                    <Route index element={<PortfolioPortfolioRelationshipAttributesList />} />
                </Route>
				<Route
                    path="portfolio-company-relationship-attributes-values/:id/:portfolioId"
                    element={<PortfolioCompanyRelationshipAttributesValues />}
                >
                    <Route index element={<PortfolioCompanyRelationshipAttributesValuesList />} />
                </Route>
				<Route
                    path="portfolio-portfolio-relationship-attributes-values/:id/:portfolioId"
                    element={<PortfolioPortfolioRelationshipAttributesValues />}
                >
                    <Route index element={<PortfolioPortfolioRelationshipAttributesValuesList />} />
                </Route>
                <Route
                    path="business-attributes"
                    element={<BusinessAttributes />}
                >
                    <Route index element={<BusinessAttributesList />} />
                </Route>
                <Route
                    path="portfolio-attributes"
                    element={<PortfolioAttributes />}
                >
                    <Route index element={<PortfolioAttributesList />} />
                </Route>
                <Route
                    path="relationship-attributes"
                    element={<PersonRelationshipAttributes />}
                >
                    <Route index element={<PersonRelationshipAttributesList />} />
                </Route>
                <Route
                    path="company-relationship-attributes"
                    element={<CompanyRelationshipAttributes />}
                >
                    <Route index element={<CompanyRelationshipAttributesList />} />
                </Route>
                <Route
                    path="person-relationship-attributes-values/:id/:personId/"
                    element={<PersonRelationshipAttributesValues />}
                >
                    <Route index element={<PersonRelationshipAttributesValuesList />} />
                </Route>
				{/* PORTFOLIO PERSON VALUES */}
                <Route
                    path="portfolio-person-relationship-attributes-values/:id/:portfolioId"
                    element={<PortfolioPersonRelationshipAttributesValues />}
                >
                    <Route index element={<PortfolioPersonRelationshipAttributesValuesList />} />
                </Route>
                <Route
                    path="company-relationship-attributes-values/:id/:companyId/:personId"
                    element={<CompanyRelationshipAttributesValues />}
                >
                    <Route index element={<CompanyRelationshipAttributesValuesList />} />
                </Route>
                <Route
                    path="person-person-relationships"
                    element={<PersonPersonRelationships />}
                >
                    <Route index element={<PersonPersonRelationshipsList />} />
                </Route>
                <Route
                    path="person-business-relationships"
                    element={<PersonBusinessRelationships />}
                >
                    <Route
                        index
                        element={<PersonBusinessRelationshipsList />}
                    />
                </Route>
                <Route
                    path="person-investment-relationships"
                    element={<PersonPortfolioRelationships />}
                >
                    <Route
                        index
                        element={<PersonPortfolioRelationshipsList />}
                    />
                </Route>
                <Route
                    path="business-investment-relationships"
                    element={<BusinessPortfolioRelationships />}
                >
                    <Route
                        index
                        element={<BusinessPortfolioRelationshipsList />}
                    />
                </Route>
                <Route
                    path="portfolios-investment-relationships"
                    element={<PortfoliosPortfolioRelationships />}
                >
                    <Route
                        index
                        element={<PortfoliosPortfolioRelationshipsList />}
                    />
                </Route>
                <Route path="users" element={<Users />}>
                    <Route index element={<ListUsers />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

function App() {
    const { user, initialLoading } = useAuth();

    if (initialLoading) {
        return <Loading />;
    }
    if (user) {
        return <AdminRoutes />;
        // switch (user.role) {
        //     case UserRoles.ADMIN:
        //         return <AdminRoutes />;
        //     case UserRoles.PROVIDER:
        //         return <ProviderRoutes />;
        //     default:
        //         return <NotFound />;
        // }
    }

    return (
        <Routes>
            <Route path="/" element={<Landing />}>
                <Route index element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot" element={<ForgotSend />} />
                <Route path="/forgot/code" element={<ForgotChange />} />
                <Route path="*" element={<SignIn />} />
            </Route>
        </Routes>
    );
}

export default App;
