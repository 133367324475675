/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal';
import { RootState } from '../../../../store';
import {
    resetSingleEntityPerson,
    updateClient,
} from '../../../../modules/persons/features/personEntitySlice';
// import { validaRut } from '../../../../utils/helpers';

import LoadingAnimated from '../../../../components/elements/Loading/index';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    email: string;
    name: string;
    last_name: string;
    rut: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function UpdateEntity({
    setShowUpdate,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowUpdate: any;
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const person = useSelector((state: RootState) => state.person);
 

    const updatePerson = useSelector((state: RootState) => state.personEntity);

    const { id } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>();



    useEffect(() => {
        if (person.status === 'success') {

            if (person.data) {
                if (person.data.email) {
                    setValue('email', person.data.email);
                }
                if (person.data.last_name) {
                    setValue('last_name', person.data.last_name);
                }
                setValue('name', person.data.name);
                setValue('rut', person.data.rut);
            }
        }
    }, [dispatch, person.data, person.status, setValue]);

    const onSubmit = (data: FormData) => {
        const payload = {
            id: Number(id),
            name: data.name,
            lastName: data.last_name,
            email: data.email,
            rut: data.rut,
        };
        dispatch(updateClient(payload));
    };

    const [message, setMessage] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');

    useEffect(() => {
        if (updatePerson.error) {
            setMessage('Error al actualizar entidad');
            dispatch(resetSingleEntityPerson());
        }
    }, [dispatch, updatePerson.error]);

    useEffect(() => {
        if (!updatePerson.error && updatePerson.status === 'success') {
            setMessage('');
            setMessageSuccess('Atributo Actualizado');
            dispatch(resetSingleEntityPerson());
        }
    }, [dispatch, updatePerson.error, updatePerson.status]);

    return (
        <>
            {person.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {person.status === 'success' && (
                <Modal
                    setShowModal={setShowUpdate}
                    onClose={() => setShowUpdate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            Actualizar persona natural
                        </h3>
                        <div>
                            {/* onSubmit={}handleSubmit(onSubmit) */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nombre 
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('name', {
                                                    // required: true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {/* {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )} */}
                                </div>
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Apellido 
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('last_name', {
                                                    // required: true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {/* {errors.last_name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )} */}
                                </div>
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Rut *
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('rut', {
                                                    required: true,
                                                    // validate: (e) =>
                                                    //     validaRut(e) === true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {errors.rut &&
                                        errors.rut?.type !== 'validate' && (
                                            <p className="text-red-600 ml-2">
                                                {t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            </p>
                                        )}
                                    {/* {errors.rut &&
                                        errors.rut?.type === 'validate' && (
                                            <p className="text-red-600 ml-2">
                                                Formato incorrecto debe ser
                                                XXXXXXXX-X
                                            </p>
                                        )} */}
                                </div>
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Email 
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('email', {
                                                    // required: true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {errors.email && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                >
                                    {updatePerson.status === 'loading'
                                        ? t('wait.message')
                                        : t('common.update')}
                                </button>
                            </form>
                        </div>
                        {message !== '' && (
                            <p className="text-red-600 text-center mt-2">
                                {message}
                            </p>
                        )}
                        {messageSuccess !== '' && (
                            <p className="text-green-600 text-center mt-2">
                                {messageSuccess}
                            </p>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
}
