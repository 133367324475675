import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../services/api/client';
import { ICompanyPerson } from './interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyPersonRelationState {
    data: ICompanyPerson[];
    status: Status;
    error: string | null;
}

const initialState: CompanyPersonRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deleteCompanyPersonRelationships = createAsyncThunk(
    'deleteCompanyPersonRel/deleteCompanyPersonRelationships',
    async ({ idRel, idClient }: { idRel: number , idClient: number}) => {
        const response = await APIClient.deleteDataEntiy(
            `/company/id/${idClient}/relationship/${idRel}/`
        );
        return response.data.results;
    }
);

export const deleteCompanyPersonRelationshipSlice = createSlice({
    name: 'deleteCompanyPersonRelationships',
    initialState,
    reducers: {
        resetDeleteCompanyPersonRelationship: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deleteCompanyPersonRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteCompanyPersonRelationships.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
                // state.data = state.data.concat(action.payload);
            })
            .addCase(
                deleteCompanyPersonRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetDeleteCompanyPersonRelationship } =
    deleteCompanyPersonRelationshipSlice.actions;
