import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IRelationshipType } from './interfaces';
import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BusinessPortfolioRelationshipTypeState {
    data: IRelationshipType | null;
    status: Status;
    error: string | null;
}

const initialState: BusinessPortfolioRelationshipTypeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const createBusinessPortfolioRelationshipType = createAsyncThunk(
    'businessPortfolioRelationshipType/createBusinessPortfolioRelationshipType',
    async ({ name }: { name: string }) => {
        const response = await APIClient.postData(
            `/portfolio/relationship/type/company/`,
            {
                name,
            }
        );
        return response.data;
    }
);

export const updateBusinessPortfolioRelationshipType = createAsyncThunk(
    'businessPortfolioRelationshipType/updateBusinessPortfolioRelationshipType',
    async ({ name, id }: { name: string; id: number }) => {
        const response = await APIClient.postData(
            `/portfolio/relationship/type/company/${id}/`,
            {
                name,
            }
        );
        return response.data;
    }
);
export const businessPortfolioRelationshipTypeSlice = createSlice({
    name: 'businessPortfolioRelationshipType',
    initialState,
    reducers: {
        resetRelationshipTypePortfolioBusiness: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                createBusinessPortfolioRelationshipType.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                createBusinessPortfolioRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                createBusinessPortfolioRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(
                updateBusinessPortfolioRelationshipType.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                updateBusinessPortfolioRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                updateBusinessPortfolioRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetRelationshipTypePortfolioBusiness } =
    businessPortfolioRelationshipTypeSlice.actions;
