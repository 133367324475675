/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioCompanyAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioCompanyRelationshipAttributeState {
    data: IPortfolioCompanyAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioCompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const addPortfolioCompanyRelationshiptAttribute = createAsyncThunk(
    'singlePortfolioCompany/addPortfolioCompanyRelationshiptAttribute',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: {
            name: string;
            value: string;
            index: number;
            idAttribute: number;
        }[];
    }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const neWData: any = [];
        let response: any;

        attributes.forEach(async (element) => {
            neWData.push({
                name: element.name,
                value: element.value,
                index: Number(element.index),
            });

            response = await APIClient.postData(
                `/portfolio/relationship/company/id/${id}/detail/${element.idAttribute}/`,
                {
                    data: [
                        {
                            name: element.name,
                            value: element.value,
                            index: Number(element.index),
                            // idAttribute: Number(element.idAttribute)
                        },
                    ],
                }
            );
            return response.data;
          });
          return response.data;
    }
);
export const portfolioCompanyRelationshipAttributesValueSlice = createSlice({
    name: 'singlePortfolioCompany',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(
                addPortfolioCompanyRelationshiptAttribute.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(
                addPortfolioCompanyRelationshiptAttribute.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                addPortfolioCompanyRelationshiptAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            );
    },
});
