/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
    validTypeAttributes,
    ValidAttributes,
} from '../validTypeAttribute/validTypesAttribute';
import { fetchPortfolioPersonAttributes } from '../../modules/portfolio-persons-attributes/features/portfoliosPersonsAttributeSlice';
import { fetchPortfolioPortfolioAttributes } from '../../modules/portfolio-portfolio-attributes/features/portfoliosPortfolioAttributeSlice';
import { fetchPortfolioCompanyAttributes } from '../../modules/portfolio-companies-attributes/features/portfoliosPersonsAttributeSlice';

type FormData = {
    name: string;
    index: number;
    type: number;
    is_unique: boolean;
    parent_attribute_id: number;
};

type FormPortfolioData = {
    name: string;
    index: number;
    type: number;
    is_unique: boolean;
    parent_attribute_id: number;
    portfolio_client_relationship_type: number;
};
type FormPortfolioCompanyData = {
    name: string;
    index: number;
    type: number;
    is_unique: boolean;
    parent_attribute_id: number;
    portfolio_company_relationship_type: number;
};

export default function CreateAttribute({
    create,
    show,
    entity,
}: {
    entity: number;
    create: ({
        label,
        name,
    }: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
    }) => Promise<void>;
    show: boolean;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormData>();

    const { t } = useTranslation();

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [isUniqueChecked, setIsUniqueChecked] = useState(false);

    const personsAttributes = useSelector(
        (state: RootState) => state.personsAttributes
    );

    const businessAttributes = useSelector(
        (state: RootState) => state.businessAttributes
    );

    const portfoliosAttributes = useSelector(
        (state: RootState) => state.portfolioAttributes
    );

    // eslint-disable-next-line
    const handleCheckboxUnique = (e: any) => {
        setIsUniqueChecked(!isUniqueChecked);
        setValue(e.target.name, isUniqueChecked);
    };

    // eslint-disable-next-line
    const handleChange = (e: any) => {

        setValue('parent_attribute_id', e.target.value);
    };
    useEffect(() => {
        if (!show) {
            reset();

            setAddRequestStatus('idle');
        }
    }, [show, reset]);

    const onSubmit = async (data: FormData) => {
            const body = {
                name: data.name,
                label: data.name,
                index: data.index,
                type: Number(data.type),
                is_unique: Boolean(data.is_unique),
                parent_attribute_id: Number(data.parent_attribute_id),
            };
            try {
                setAddRequestStatus('loading');
                await create(body);
                setAddRequestStatus('success');
                reset();
            } catch (error) {
                // console.log(error);
                setAddRequestStatus('error');
            }

    };

    return (
        <div className="mb-20">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.name')}
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('name', {
                                    required: true,
                                })}
                                // Format text to be uppercase and spaces as _
                                onChange={(e) => {
                                    setValue(
                                        'name',
                                        e.target.value
                                            .toUpperCase()
                                            .replace(/ /g, '_')
                                    );
                                }}
                            />
                            {errors.name && (
                                <p className="text-red-600 ml-2">
                                    {errors.name.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de atributo
                        <div className="mt-1">
                            <select
                                id="type"
                                // onChange={handleChange}
                                {...register('type', {})}
                                required
                            >
                                <option> Seleccione un valor ...</option>
                                {validTypeAttributes.map(
                                    (vAttribute: ValidAttributes) => (
                                        <option value={vAttribute.id}>
                                            {' '}
                                            {vAttribute.name}
                                        </option>
                                    )
                                )}
                                {/* <option value={2}> Enumeración</option>
                                <option value={3}> Fecha</option>
                                <option value={4}> Númerico</option> */}
                            </select>
                            {/* <input
                                id="type"
                                type="number"
                                defaultValue={1}
                                min={1}
                                max={4}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('type', {})}

                                // Format text to be uppercase and spaces as _
                            /> */}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="is_unique"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Es Único
                        <div className="mt-1">
                            <input
                                id="is_unique"
                                type="checkbox"
                                checked={isUniqueChecked}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('is_unique', {})}
                                // Format text to be uppercase and spaces as _
                                onChange={handleCheckboxUnique}
                            />
                        </div>
                    </label>
                </div>
                {entity === 1 && personsAttributes && (
                    <div className="mt-3">
                        <label
                            htmlFor="parent-attribute"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Attributo Padre
                            <div className="mt-1">
                                <select
                                    id="parent-attribute"
                                    onChange={handleChange}
                                >
                                    {personsAttributes.status === 'loading' && (
                                        <option> cargando ...</option>
                                    )}
                                    <option> Seleccione un valor ...</option>
                                    {personsAttributes.data.length > 0 &&
                                        personsAttributes.data.map((e) => (
                                            <option
                                                key={e.id}
                                                data-unique={e.is_unique}
                                                value={e.id}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {/* {isUnique === 'true' && (
                            <p className="text-red-600 mb-2">
                                Atributo padre es único
                            </p>
                        )} */}
                    </div>
                )}
                {entity === 2 && businessAttributes && (
                    <div className="mt-3">
                        <label
                            htmlFor="parent-attribute"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Attributo Padre
                            <div className="mt-1">
                                <select
                                    id="parent-attribute"
                                    onChange={handleChange}
                                >
                                    {businessAttributes.status ===
                                        'loading' && (
                                        <option> cargando ...</option>
                                    )}
                                    <option> Seleccione un valor ...</option>
                                    {businessAttributes.data.length > 0 &&
                                        businessAttributes.data.map((e) => (
                                            <option
                                                key={e.id}
                                                data-unique={e.is_unique}
                                                value={e.id}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {/* {isUnique === 'true' && (
                            <p className="text-red-600 mb-2">
                                Atributo padre es único
                            </p>
                        )} */}
                    </div>
                )}
                {entity === 3 && portfoliosAttributes && (
                    <div className="mt-3">
                        <label
                            htmlFor="parent-attribute"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Attributo Padre
                            <div className="mt-1">
                                <select
                                    id="parent-attribute"
                                    onChange={handleChange}
                                >
                                    {portfoliosAttributes.status ===
                                        'loading' && (
                                        <option> cargando ...</option>
                                    )}
                                    <option> Seleccione un valor ...</option>
                                    {portfoliosAttributes.data.length > 0 &&
                                        portfoliosAttributes.data.map((e) => (
                                            <option
                                                key={e.id}
                                                data-unique={e.is_unique}
                                                value={e.id}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {/* {isUnique === 'true' && (
                            <p className="text-red-600 mb-2">
                                Atributo padre es único
                            </p>
                        )} */}
                    </div>
                )}

                <div className="mt-4">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('attributes.create.error')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('attributes.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export function CreatePortfolioPersonAttribute({
    create,
    show,
    toggle,
    entity,
}: {
    entity: number;
    create: ({
        label,
        name,
    }: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
        portfolio_client_relationship_type: number;
    }) => Promise<void>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggle: any;
    show: boolean;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormPortfolioData>();

    const { t } = useTranslation();

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [isUniqueChecked, setIsUniqueChecked] = useState(false);

    const portfoliosPersonRelationships = useSelector(
        (state: RootState) => state.personPortfolioRelationships
    );

    const portfoliosPersonsAttributes = useSelector(
        (state: RootState) => state.PortfoliosPersonsRelationshipsAttributes
    );

    // eslint-disable-next-line
    const handleCheckboxUnique = (e: any) => {
        setIsUniqueChecked(!isUniqueChecked);
        setValue(e.target.name, isUniqueChecked);
    };

    // eslint-disable-next-line
    const handleChange = (e: any) => {
        setValue('parent_attribute_id', e.target.value);
    };

    useEffect(() => {
        if (!show) {
            reset();

            setAddRequestStatus('idle');
        }
    }, [show, reset]);

    const onSubmit = async (data: FormPortfolioData) => {
            const body = {
                name: data.name,
                label: data.name,
                index: data.index,
                type: Number(data.type),
                is_unique: Boolean(data.is_unique),
                parent_attribute_id: Number(data.parent_attribute_id),
                portfolio_client_relationship_type: Number(
                    data.portfolio_client_relationship_type
                ),
            };
            try {
                setAddRequestStatus('loading');
                await create(body);
                setAddRequestStatus('success');
                reset();
            } catch (error) {
                // console.log(error);
                setAddRequestStatus('error');
            }
        
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (addRequestStatus === 'success') {
            setTimeout(() => {
                setAddRequestStatus('idle');
                dispatch(fetchPortfolioPersonAttributes());
                reset();
                toggle(() => false);
            }, 2000);
        }
    }, [addRequestStatus, reset, dispatch, toggle]);

    return (
        <div className="mb-20">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.name')}
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('name', {
                                    required: true,
                                })}
                                // Format text to be uppercase and spaces as _
                                onChange={(e) => {
                                    setValue(
                                        'name',
                                        e.target.value
                                            .toUpperCase()
                                            .replace(/ /g, '_')
                                    );
                                }}
                            />
                            {errors.name && (
                                <p className="text-red-600 ml-2">
                                    {errors.name.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de atributo
                        <div className="mt-1">
                            <select
                                id="type"
                                // onChange={handleChange}
                                {...register('type', {})}
                                required
                            >
                                <option> Seleccione un valor ...</option>
                                {validTypeAttributes.map(
                                    (vAttribute: ValidAttributes) => (
                                        <option value={vAttribute.id}>
                                            {' '}
                                            {vAttribute.name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="is_unique"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Es Único
                        <div className="mt-1">
                            <input
                                id="is_unique"
                                type="checkbox"
                                checked={isUniqueChecked}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('is_unique', {})}
                                // Format text to be uppercase and spaces as _
                                onChange={handleCheckboxUnique}
                            />
                        </div>
                    </label>
                </div>

                {entity === 4 && portfoliosPersonsAttributes && (
                    <>
                        <div className="mt-3">
                            <label
                                htmlFor="parent-attribute"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Attributo Padre
                                <div className="mt-1">
                                    <select
                                        id="parent-attribute"
                                        onChange={handleChange}
                                    >
                                        {portfoliosPersonsAttributes.status ===
                                            'loading' && (
                                            <option> cargando ...</option>
                                        )}
                                        <option>
                                            {' '}
                                            Seleccione un valor ...
                                        </option>
                                        {portfoliosPersonsAttributes.data
                                            .length > 0 &&
                                            portfoliosPersonsAttributes.data.map(
                                                (e) => (
                                                    <option
                                                        key={e.id}
                                                        data-unique={
                                                            e.is_unique
                                                        }
                                                        value={e.id}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </label>
                            {/* {isUnique === 'true' && (
                            <p className="text-red-600 mb-2">
                                Atributo padre es único
                            </p>
                        )} */}
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor="relationship-attribute"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Relación
                                <div className="mt-1">
                                    <select
                                        id="relationship-attribute"
                                        // onChange={handleChange}
                                        {...register(
                                            'portfolio_client_relationship_type',
                                            {}
                                        )}
                                        required
                                    >
                                        {portfoliosPersonRelationships.status ===
                                            'loading' && (
                                            <option> cargando ...</option>
                                        )}
                                        <option>
                                            {' '}
                                            Seleccione un valor ...
                                        </option>
                                        {portfoliosPersonRelationships.data
                                            .length > 0 &&
                                            portfoliosPersonRelationships.data.map(
                                                (e) => (
                                                    <option
                                                        key={e.id}
                                                        data-unique={
                                                            e.is_unique
                                                        }
                                                        value={e.id}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </label>
                        </div>
                    </>
                )}

                <div className="mt-4">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('attributes.create.error')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('attributes.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export function CreatePortfolioPortfolioAttribute({
    create,
    show,
    toggle,
    entity,
}: {
    entity: number;
    create: ({
        label,
        name,
    }: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
        portfolio_portfolio_relationship_type: number;
    }) => Promise<void>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggle: any;
    show: boolean;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormPortfolioData>();

    const { t } = useTranslation();

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [isUniqueChecked, setIsUniqueChecked] = useState(false);

    const portfoliosPortfolioRelationships = useSelector(
        (state: RootState) => state.portfolioPortfolioRelationships
    );

    const portfoliosPortfolioAttributes = useSelector(
        (state: RootState) => state.portfoliosPortfolioRelationshipsAttributes
    );

    // eslint-disable-next-line
    const handleCheckboxUnique = (e: any) => {
        setIsUniqueChecked(!isUniqueChecked);
        setValue(e.target.name, isUniqueChecked);
    };

    // eslint-disable-next-line
    const handleChange = (e: any) => {
        setValue('parent_attribute_id', e.target.value);
    };

    useEffect(() => {
        if (!show) {
            reset();

            setAddRequestStatus('idle');
        }
    }, [show, reset]);

    const onSubmit = async (data: FormPortfolioData) => {
            const body = {
                name: data.name,
                label: data.name,
                index: data.index,
                type: Number(data.type),
                is_unique: Boolean(data.is_unique),
                parent_attribute_id: Number(data.parent_attribute_id),
                portfolio_portfolio_relationship_type: Number(
                    data.portfolio_client_relationship_type
                ),
            };
            try {
                setAddRequestStatus('loading');
                await create(body);
                setAddRequestStatus('success');
                reset();
            } catch (error) {
                // console.log(error);
                setAddRequestStatus('error');
            }
      
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (addRequestStatus === 'success') {
            setTimeout(() => {
                setAddRequestStatus('idle');
                dispatch(fetchPortfolioPortfolioAttributes());
                reset();
                toggle(() => false);
            }, 2000);
        }
    }, [addRequestStatus, reset, dispatch, toggle]);

    return (
        <div className="mb-20">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.name')}
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('name', {
                                    required: true,
                                })}
                                // Format text to be uppercase and spaces as _
                                onChange={(e) => {
                                    setValue(
                                        'name',
                                        e.target.value
                                            .toUpperCase()
                                            .replace(/ /g, '_')
                                    );
                                }}
                            />
                            {errors.name && (
                                <p className="text-red-600 ml-2">
                                    {errors.name.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de atributo
                        <div className="mt-1">
                            <select
                                id="type"
                                // onChange={handleChange}
                                {...register('type', {})}
                                required
                            >
                                <option> Seleccione un valor ...</option>
                                {validTypeAttributes.map(
                                    (vAttribute: ValidAttributes) => (
                                        <option value={vAttribute.id}>
                                            {' '}
                                            {vAttribute.name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="is_unique"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Es Único
                        <div className="mt-1">
                            <input
                                id="is_unique"
                                type="checkbox"
                                checked={isUniqueChecked}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('is_unique', {})}
                                // Format text to be uppercase and spaces as _
                                onChange={handleCheckboxUnique}
                            />
                        </div>
                    </label>
                </div>

                {entity === 4 && portfoliosPortfolioAttributes && (
                    <>
                        <div className="mt-3">
                            <label
                                htmlFor="parent-attribute"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Attributo Padre
                                <div className="mt-1">
                                    <select
                                        id="parent-attribute"
                                        onChange={handleChange}
                                    >
                                        {portfoliosPortfolioAttributes.status ===
                                            'loading' && (
                                            <option> cargando ...</option>
                                        )}
                                        <option>
                                            {' '}
                                            Seleccione un valor ...
                                        </option>
                                        {portfoliosPortfolioAttributes.data
                                            .length > 0 &&
                                            portfoliosPortfolioAttributes.data.map(
                                                (e) => (
                                                    <option
                                                        key={e.id}
                                                        data-unique={
                                                            e.is_unique
                                                        }
                                                        value={e.id}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </label>
                            {/* {isUnique === 'true' && (
                            <p className="text-red-600 mb-2">
                                Atributo padre es único
                            </p>
                        )} */}
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor="relationship-attribute"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Relación
                                <div className="mt-1">
                                    <select
                                        id="relationship-attribute"
                                        // onChange={handleChange}
                                        {...register(
                                            'portfolio_client_relationship_type',
                                            {}
                                        )}
                                        required
                                    >
                                        {portfoliosPortfolioRelationships.status ===
                                            'loading' && (
                                            <option> cargando ...</option>
                                        )}
                                        <option>
                                            {' '}
                                            Seleccione un valor ...
                                        </option>
                                        {portfoliosPortfolioRelationships.data
                                            .length > 0 &&
                                            portfoliosPortfolioRelationships.data.map(
                                                (e) => (
                                                    <option
                                                        key={e.id}
                                                        data-unique={
                                                            e.is_unique
                                                        }
                                                        value={e.id}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </label>
                        </div>
                    </>
                )}

                <div className="mt-4">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('attributes.create.error')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('attributes.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export function CreatePortfolioCompanyAttribute({
    create,
    show,
    toggle,
    entity,
}: {
    entity: number;
    create: ({
        label,
        name,
    }: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
        portfolio_company_relationship_type: number;
    }) => Promise<void>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggle: any;
    show: boolean;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormPortfolioCompanyData>();

    const { t } = useTranslation();

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [isUniqueChecked, setIsUniqueChecked] = useState(false);

    const businessPortfolioRelationships = useSelector(
        (state: RootState) => state.businessPortfolioRelationships
    );

    const portfoliosCompaniesAttributes = useSelector(
        (state: RootState) => state.portfoliosCompaniesRelationshipsAttributes
    );

    // eslint-disable-next-line
    const handleCheckboxUnique = (e: any) => {
        setIsUniqueChecked(!isUniqueChecked);
        setValue(e.target.name, isUniqueChecked);
    };

    // eslint-disable-next-line
    const handleChange = (e: any) => {
        setValue('parent_attribute_id', e.target.value);
    };

    useEffect(() => {
        if (!show) {
            reset();

            setAddRequestStatus('idle');
        }
    }, [show, reset]);

    const onSubmit = async (data: FormPortfolioCompanyData) => {
            const body = {
                name: data.name,
                label: data.name,
                index: data.index,
                type: Number(data.type),
                is_unique: Boolean(data.is_unique),
                parent_attribute_id: Number(data.parent_attribute_id),
                portfolio_company_relationship_type: Number(
                    data.portfolio_company_relationship_type
                ),
            };
            try {
                setAddRequestStatus('loading');
                await create(body);
                setAddRequestStatus('success');
                reset();
            } catch (error) {
                // console.log(error);
                setAddRequestStatus('error');
            }

    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (addRequestStatus === 'success') {
            setTimeout(() => {
                setAddRequestStatus('idle');
                dispatch(fetchPortfolioCompanyAttributes());
                reset();
                toggle(() => false);
            }, 2000);
        }
    }, [addRequestStatus, reset, dispatch, toggle]);

    return (
        <div className="mb-20">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.name')}
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('name', {
                                    required: true,
                                })}
                                // Format text to be uppercase and spaces as _
                                onChange={(e) => {
                                    setValue(
                                        'name',
                                        e.target.value
                                            .toUpperCase()
                                            .replace(/ /g, '_')
                                    );
                                }}
                            />
                            {errors.name && (
                                <p className="text-red-600 ml-2">
                                    {errors.name.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de atributo
                        <div className="mt-1">
                            <select
                                id="type"
                                // onChange={handleChange}
                                {...register('type', {})}
                                required
                            >
                                <option> Seleccione un valor ...</option>
                                {validTypeAttributes.map(
                                    (vAttribute: ValidAttributes) => (
                                        <option value={vAttribute.id}>
                                            {' '}
                                            {vAttribute.name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </label>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="is_unique"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Es Único
                        <div className="mt-1">
                            <input
                                id="is_unique"
                                type="checkbox"
                                checked={isUniqueChecked}
                                className={`${
                                    errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('is_unique', {})}
                                // Format text to be uppercase and spaces as _
                                onChange={handleCheckboxUnique}
                            />
                        </div>
                    </label>
                </div>

                {entity === 4 && portfoliosCompaniesAttributes && (
                    <>
                        <div className="mt-3">
                            <label
                                htmlFor="parent-attribute"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Attributo Padre
                                <div className="mt-1">
                                    <select
                                        id="parent-attribute"
                                        onChange={handleChange}
                                    >
                                        {portfoliosCompaniesAttributes.status ===
                                            'loading' && (
                                            <option> cargando ...</option>
                                        )}
                                        <option>
                                            {' '}
                                            Seleccione un valor ...
                                        </option>
                                        {portfoliosCompaniesAttributes.data
                                            .length > 0 &&
                                            portfoliosCompaniesAttributes.data.map(
                                                (e) => (
                                                    <option
                                                        key={e.id}
                                                        data-unique={
                                                            e.is_unique
                                                        }
                                                        value={e.id}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </label>
                            {/* {isUnique === 'true' && (
                            <p className="text-red-600 mb-2">
                                Atributo padre es único
                            </p>
                        )} */}
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor="relationship-attribute"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Relación
                                <div className="mt-1">
                                    <select
                                        id="relationship-attribute"
                                        // onChange={handleChange}
                                        {...register(
                                            'portfolio_company_relationship_type',
                                            {}
                                        )}
                                        required
                                    >
                                        {businessPortfolioRelationships.status ===
                                            'loading' && (
                                            <option> cargando ...</option>
                                        )}
                                        <option>
                                            {' '}
                                            Seleccione un valor ...
                                        </option>
                                        {businessPortfolioRelationships.data
                                            .length > 0 &&
                                            businessPortfolioRelationships.data.map(
                                                (e) => (
                                                    <option
                                                        key={e.id}
                                                        data-unique={
                                                            e.is_unique
                                                        }
                                                        value={e.id}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </label>
                        </div>
                    </>
                )}

                <div className="mt-4">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('attributes.create.error')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('attributes.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </form>
        </div>
    );
}
