/* eslint-disable react/jsx-props-no-spreading */

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import { updatePortfolioPortfolioAttribute } from '../../../../modules/portfolio-portfolio-attributes/features/portfolioPortfolioAttributeSlice';
import { EditPortfolioPortfolio } from '../../../../components/EditAttribute/index';

export default function EditPortfolioPortfolioAttribute({
    show = false,
    attributeData,
    toggle,
}: {
    show: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributeData: any;
    toggle: () => void;
}) {
    const dispatch = useAppDispatch();
    return (
        attributeData.data && (
            <SideCreate show={show} title="Editar atributo" toggle={toggle}>
                <EditPortfolioPortfolio
                    entity={8}
                    create={(body) =>
                        dispatch(updatePortfolioPortfolioAttribute(body)).unwrap()
                    }
                    show={show}
                    toggle={toggle}
                    attributeData={attributeData}
                />
            </SideCreate>
        )
    );
}
