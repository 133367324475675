import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IRelationshipType } from './interfaces';
import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonBusinessRelationshipTypeState {
    data: IRelationshipType | null;
    status: Status;
    error: string | null;
}

const initialState: PersonBusinessRelationshipTypeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const createPersonBusinessRelationshipType = createAsyncThunk(
    'personBusinessRelationshipType/createPersonBusinessRelationshipType',
    async ({ name }: { name: string }) => {
        const response = await APIClient.postData(`/company/relationship/`, {
            name,
        });
        return response.data;
    }
);
export const updatePersonBusinessRelationshipType = createAsyncThunk(
    'personBusinessRelationshipType/updatePersonBusinessRelationshipType',
    async ({ name , id}: { name: string , id: number}) => {
        const response = await APIClient.postData(`/company/relationship/${id}/`, {
            name,
        });
        return response.data;
    }
);

export const personBusinessRelationshipTypeSlice = createSlice({
    name: 'personBusinessRelationshipType',
    initialState,
    reducers: {
        resetRelationshipTypeBusiness: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createPersonBusinessRelationshipType.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPersonBusinessRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                createPersonBusinessRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(updatePersonBusinessRelationshipType.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                updatePersonBusinessRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                updatePersonBusinessRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetRelationshipTypeBusiness } =
    personBusinessRelationshipTypeSlice.actions;
