/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';

import { IPersonPortfolioRelation } from '../../../modules/person-portfolio-relationships/interfaces';
import {
    fetchPersonPortfolioRelationships,
    resetPersonPortfolioRelationship,
} from '../../../modules/person-portfolio-relationships/features/personPortfolioRelationshipSlice';
import TableSearch from '../../../components/Table/TableSearch';
import { CreateRelationshipType } from '../../../components/createRelationshipType/CreateRelationshipType';
import { createPersonPortfolioRelationshipType, resetRelationshipTypePortfolio, updatePersonPortfolioRelationshipType } from '../../../modules/personPortfolioRelationshipType/personPortfolioRelationshipTypeSlice';
import { IItemAttributeValue } from '../../../modules/common/interfaces/index';
import EditableCell from '../../../components/Table/EditableCell';

export default function ListPersonsAttributesBusiness() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const personPortfolioRelationships = useSelector(
        (state: RootState) => state.personPortfolioRelationships
    );
    const personRelationshipType = useSelector(
        (state: RootState) => state.personPortfolioRelationshipType
    );
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        if (personPortfolioRelationships.status === 'idle') {
            dispatch(fetchPersonPortfolioRelationships());
        }
    }, [personPortfolioRelationships.status, dispatch]);

    useEffect(() => {
        if (personRelationshipType.status === 'success') {
            dispatch(resetPersonPortfolioRelationship());
            dispatch(fetchPersonPortfolioRelationships());
        }
    }, [
        dispatch,
        personRelationshipType.status,
    ]);

    const columns = useMemo<Column<IPersonPortfolioRelation>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.identifier').toString(),
                accessor: 'name',
                Cell: EditableCell,
            },
        ],

        [t]
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [attributes, setAttributes] = useState<any[]>([]);

    const updateMyData = (
        rowIndex: string | number,
        columnId: number,
        original: IItemAttributeValue,
        value: string
    ) => {
        if (original.value === value) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const upData: any = {
            id: original.id,
            name: original.name,
            value,
            attribute: original.attribute,
        };

        const exist = attributes.findIndex(
            (item) =>
                item.name === upData.name &&
                item.attribute === upData.attribute &&
                value === upData.value
        );

        if (exist !== -1) {
            attributes[exist] = upData;
            setAttributes([...attributes]);
            return;
        }

        setAttributes([...attributes, upData]);
    };

    const tableInstance = useTable(
        {
            columns,
            data: personPortfolioRelationships.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
            updateMyData,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    const valdiateIfExists = (name: string): boolean => {
        const filterName = personPortfolioRelationships.data.filter(
            (p) => p.name === name.toUpperCase()
        );
        if (filterName.length > 0) {
            return true;
        }
        return false;
    };

    	const update = async () => {
            attributes.forEach((attribute) => {
                dispatch(
                    updatePersonPortfolioRelationshipType({
                        id: attribute.id,
                        name: attribute.value,
                    })
                );
                dispatch(resetRelationshipTypePortfolio());
            });
            setAttributes([]);
        };
    return (
        <div className="relative mb-20">
            {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}
            <Button
                className="absolute right-0 top-0 z-10"
                label={t('relationshipType.button.title')}
                type="button"
                onClick={() => setShowCreate(!showCreate)}
            />
            <Button
                className="mr-2 absolute right-48 top-0 z-60"
                label="Actualizar relación"
                type="button"
                onClick={() => update()}
            />
            <PageHeader title={t('person-investment-relationship.title')} />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {personPortfolioRelationships.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {personPortfolioRelationships.status === 'success' &&
                personPortfolioRelationships.data.length > 0 && (
                    <>
                        <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="overflow-x-auto shadow-md">
                            <table
                                className="table-auto w-full mx-auto"
                                {...getTableProps()}
                            >
                                <thead className="text-white">
                                    {
                                        // Loop over the header rows

                                        headerGroups.map((headerGroup) => (
                                            // Apply the header row props

                                            <tr
                                                className="text-left"
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {
                                                    // Loop over the headers in each row

                                                    headerGroup.headers.map(
                                                        (column) => (
                                                            // Apply the header cell props

                                                            <th
                                                                className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                                {...column.getHeaderProps(
                                                                    column.getSortByToggleProps()
                                                                )}
                                                                style={{
                                                                    minWidth:
                                                                        '2%',
                                                                }}
                                                            >
                                                                <div className="flex justify-between">
                                                                    {
                                                                        // Render the header

                                                                        column.render(
                                                                            'Header'
                                                                        )
                                                                    }
                                                                    {column.canSort ? (
                                                                        <span
                                                                            className={`${
                                                                                column.isSorted &&
                                                                                'text-sym-primary-500'
                                                                            }`}
                                                                        >
                                                                            {column.isSortedDesc && (
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    className="h-6 w-6"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor"
                                                                                >
                                                                                    <path
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        strokeWidth={
                                                                                            2
                                                                                        }
                                                                                        d="M19 9l-7 7-7-7"
                                                                                    />
                                                                                </svg>
                                                                            )}
                                                                            {!column.isSortedDesc && (
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    className="h-6 w-6"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor"
                                                                                >
                                                                                    <path
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        strokeWidth={
                                                                                            2
                                                                                        }
                                                                                        d="M5 15l7-7 7 7"
                                                                                    />
                                                                                </svg>
                                                                            )}
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </th>
                                                        )
                                                    )
                                                }
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {
                                        // Loop over the table rows

                                        page.map((row) => {
                                            // Prepare the row for display

                                            prepareRow(row);

                                            return (
                                                // Apply the row props

                                                <tr
                                                    className="odd:bg-slate-200 hover:bg-slate-300"
                                                    {...row.getRowProps()}
                                                >
                                                    {
                                                        // Loop over the rows cells

                                                        row.cells.map(
                                                            (cell) => (
                                                                // Apply the cell props

                                                                <td
                                                                    className="py-4 px-2 cursor-pointer"
                                                                    {...cell.getCellProps()}
                                                                >
                                                                    {
                                                                        // Render the cell contents

                                                                        cell.render(
                                                                            'Cell'
                                                                        )
                                                                    }
                                                                </td>
                                                            )
                                                        )
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </>
                )}
            {showCreate && (
                <CreateRelationshipType
                    setShowCreate={setShowCreate}
                    t={t}
                    title={t('person-portfolio-rel-type.title')}
                    relationshipCreate={createPersonPortfolioRelationshipType}
                    valdiateIfExists={valdiateIfExists}
                    stateRelationship={personRelationshipType}
                    person={false}
                    company={false}
                    portfolioPerson
                    portfolioBusiness={false}
                    portfolioPortfolio={false}
                />
            )}
        </div>
    );
}
