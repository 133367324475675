import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IRelationshipType } from './interfaces';
import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPortfolioRelationshipTypeState {
    data: IRelationshipType | null;
    status: Status;
    error: string | null;
}

const initialState: PersonPortfolioRelationshipTypeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const createPersonPortfolioRelationshipType = createAsyncThunk(
    'personPortfolioRelationshipType/createPersonPortfolioRelationshipType',
    async ({ name }: { name: string }) => {
        const response = await APIClient.postData(
            `/portfolio/relationship/type/client/`,
            {
                name,
            }
        );
        return response.data;
    }
);

export const updatePersonPortfolioRelationshipType = createAsyncThunk(
    'personPortfolioRelationshipType/updatePortfolioClientRelationshipType',
    async ({ name, id }: { name: string; id: number }) => {
        const response = await APIClient.postData(
            `/portfolio/relationship/type/client/${id}/`,
            {
                name,
            }
        );
        return response.data;
    }
);
export const personPortfolioRelationshipTypeSlice = createSlice({
    name: 'personPortfolioRelationshipType',
    initialState,
    reducers: {
        resetRelationshipTypePortfolio: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createPersonPortfolioRelationshipType.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPersonPortfolioRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                createPersonPortfolioRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(updatePersonPortfolioRelationshipType.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                updatePersonPortfolioRelationshipType.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                updatePersonPortfolioRelationshipType.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetRelationshipTypePortfolio } =
    personPortfolioRelationshipTypeSlice.actions;
