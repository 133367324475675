import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../services/api/client';
import { ICompanyPerson } from './interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyPortfolioRelationState {
    data: ICompanyPerson[];
    status: Status;
    error: string | null;
}

const initialState: CompanyPortfolioRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deleteCompanyPortfolioRelationships = createAsyncThunk(
    'deleteCompanyPortfolioRel/deleteCompanyPortfolioRelationships',
    async ({ idRel }: { idRel: number }) => {
        const response = await APIClient.deleteDataEntiy(
            `/portfolio/relationship/company/${idRel}/`
        );
        return response.data.results;
    }
);

export const deleteCompanyPortfolioRelationshipSlice = createSlice({
    name: 'deleteCompanyPortfolioRelationships',
    initialState,
    reducers: {
        resetDeleteCompanyPortfolioRelationship: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deleteCompanyPortfolioRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteCompanyPortfolioRelationships.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
                // state.data = state.data.concat(action.payload);
            })
            .addCase(
                deleteCompanyPortfolioRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetDeleteCompanyPortfolioRelationship } =
    deleteCompanyPortfolioRelationshipSlice.actions;
