


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioRelationshipAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const fetchPortfolioRelationshipRelAttributesValues = createAsyncThunk(
    'portfolioAttributes/fetchPortfolioAttributesRelValues',
    async (id: string) => {
        const response = await APIClient.getData(
            `/portfolio/relationship/client/attribute/relationship/${id}?limit=100000`
        );
        return response.data.results;
    }
);


export const portfolioPersonRelationshipAttributesRelValuesSlice = createSlice({
    name: 'portfolioPersonRelationshipAttributesValues',
    initialState,
    reducers: {

		resetValuesPortfoliosRelVa: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioRelationshipRelAttributesValues.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioRelationshipRelAttributesValues.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(fetchPortfolioRelationshipRelAttributesValues.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
          
    },
});

export const { resetValuesPortfoliosRelVa } =
    portfolioPersonRelationshipAttributesRelValuesSlice.actions;
