
 import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
 import APIClient from '../../../services/api/client';
 import { IPersonAttribute } from '../interfaces';

 enum Status {
     idle = 'idle',
     loading = 'loading',
     success = 'success',
     error = 'error',
 }

 export interface PersonAttributeState {
     data: IPersonAttribute | null;
     status: Status;
     error: string | null;
 }

 const initialState: PersonAttributeState = {
     data: null,
     status: Status.idle,
     error: null,
 };

  export const getPersonAttribute = createAsyncThunk(
      'getPersonAttributes/getPersonAttributes',
      async (id: string) => {
          const response = await APIClient.getData(`/client/attribute/${id}`);
          return response.data;
      }
  );


 export const getPersonsAttributesSlice = createSlice({
     name: 'getPersonAttribute',
     initialState,
     reducers: {
      resetGetPersonAttributes : (state) => {

        state.data = null;
        state.status = Status.idle;
        state.error =  null;
      }
     },
     extraReducers(builder) {
         builder
             .addCase(getPersonAttribute.pending, (state) => {
                 state.status = Status.loading;
             })
             .addCase(getPersonAttribute.fulfilled, (state, action) => {
                 state.status = Status.success;
                 // Add any fetched data to the array
                 state.data = action.payload
             })
             .addCase(getPersonAttribute.rejected, (state, action) => {
                 state.status = Status.error;
                 state.error = action.error.message ?? null;
             });
     },
 });


 export const { resetGetPersonAttributes } = getPersonsAttributesSlice.actions;
