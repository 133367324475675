

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonRelationshipAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolioPersonRelationshipAttributesValues = createAsyncThunk(
    'portfolioPersonRelationshipAttributesValues/fetchPortfolioPersonAttributesValues',
    async ({id, portfolioId} : { id: string, portfolioId: string }) => {
        const response = await APIClient.getData(
            `/portfolio/relationship/client/id/${id}/detail/${portfolioId}?limit=100000`
        );
        return response.data.results;
    }
);
export const portfolioPersonsRelationshipAttributesValuesSlice = createSlice({
    name: 'portfolioPersosnRelationshipAttributesValues',
    initialState,
    reducers: {

		resetValuesPortPersoRel: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioPersonRelationshipAttributesValues.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioPersonRelationshipAttributesValues.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchPortfolioPersonRelationshipAttributesValues.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
          
    },
});

export const { resetValuesPortPersoRel } = portfolioPersonsRelationshipAttributesValuesSlice.actions;
