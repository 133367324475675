import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
    deleted = 'deleted',
}

export interface PortfolioAttributeState {
    data: IPortfolioAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolioAttributes = createAsyncThunk(
    'portfolioAttributes/fetchPortfolioAttributes',
    async () => {
        const response = await APIClient.getData(
            '/portfolio/attribute/?limit=100000'
        );
        return response.data.results;
    }
);

export const createPortfolioAttribute = createAsyncThunk(
    'portfolioAttributes/create',
    async (body: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
    }) => {
        const response = await APIClient.postData(
            '/portfolio/attribute/',
            body
        );
        return response.data;
    }
);
export const createPortfolioAttributeEnum = createAsyncThunk(
    'createPortfolioAttribute/createEnum',
    async (body: { name: string; attribute: number }) => {
        const response = await APIClient.postData('/portfolio/enum/', body);
        return response.data;
    }
);

export const deletePortfolioAttribute = createAsyncThunk(
    'portfolioAttributes/deletePortfolioAttributes',
    async ({
        idClient,
        idAttribute,
    }: {
        idClient: string;
        idAttribute: string;
    }) => {
        const response = await APIClient.deleteDataEntiy(
            `/portfolio/id/${idClient}/detail/${idAttribute}/`
        );

        return response.data;
    }
);

export const updatePortfolioAttribute = createAsyncThunk(
    'portfolioAttributesUpdate/update',
    async (body: {
        id: string;
        label: string;
        name: string;
        type: number | undefined;
        is_unique: boolean;
        parent_attribute_id: number | undefined;
    }) => {
        const response = await APIClient.postData(
            `/portfolio/attribute/${body.id}/`,
            body
        );
        return response.data;
    }
);
export const portfolioAttributesSlice = createSlice({
    name: 'porfolioAttributes',
    initialState,
    reducers: {
        resetPortfolioAttribute: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioAttributes.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioAttributes.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchPortfolioAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createPortfolioAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPortfolioAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createPortfolioAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createPortfolioAttributeEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPortfolioAttributeEnum.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(createPortfolioAttributeEnum.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(deletePortfolioAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deletePortfolioAttribute.fulfilled, (state) => {
                state.status = Status.deleted;
                // Add any fetched data to the array
                // state.data.push(action.payload);
            })
            .addCase(deletePortfolioAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePortfolioAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePortfolioAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updatePortfolioAttribute.fulfilled, (state) => {
                state.status = Status.deleted;
                // Add any fetched data to the array
                // state.data.push(action.payload);
            });
    },
});


export const { resetPortfolioAttribute } = portfolioAttributesSlice.actions;
