import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonAttributeState {
    data: IPortfolioPersonAttribute | null;
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonAttributeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const getPortfolioPersonAttribute = createAsyncThunk(
    'getPortfolioPersonAttributes/getPortfolioPersonAttributes',
    async (id: string) => {
        const response = await APIClient.getData(
            `/portfolio/relationship/client/attribute/${id}`
        );
        return response.data;
    }
);

export const getPortfolioPersonsAttributesSlice = createSlice({
    name: 'getPortfolioPersonAttribute',
    initialState,
    reducers: {
        resetGetPortfolioPersonAttributes: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getPortfolioPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getPortfolioPersonAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(getPortfolioPersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetGetPortfolioPersonAttributes } = getPortfolioPersonsAttributesSlice.actions;
