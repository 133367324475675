
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IItemAttributePortfolioCompanyRelationship } from '../../common/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioCompanyRelationshipAttributeState {
    data: IItemAttributePortfolioCompanyRelationship[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioCompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolioCompanyRelationshipAttributesValues = createAsyncThunk(
    'portfolioCompaniesRelationshipAttributesValues/fetchPortfolioCompanyAttributesValues',
    async ({ id, portfolioId }: { id: string; portfolioId: string }) => {
        const response = await APIClient.getData(
            `/portfolio/relationship/company/id/${id}/detail/${portfolioId}?/limit=100000`
        );
        return response.data.results;
    }
);
export const portfolioCompaniesRelationshipAttributesValuesSlice = createSlice({
    name: 'portfolioCompaniesRelationshipAttributesValues',
    initialState,
    reducers: {

		resetValuesPortCompRel: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioCompanyRelationshipAttributesValues.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioCompanyRelationshipAttributesValues.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchPortfolioCompanyRelationshipAttributesValues.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });

    },
});

export const { resetValuesPortCompRel } = portfolioCompaniesRelationshipAttributesValuesSlice.actions;
