import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPortfolioAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioCompanyAttributeState {
    data: IPortfolioPortfolioAttribute | null;
    status: Status;
    error: string | null;
}

const initialState: PortfolioCompanyAttributeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const getPortfolioPortfolioAttribute = createAsyncThunk(
    'getPortfolioPortfolioAttributes/getPortfolioPortfolioAttributes',
    async (id: string) => {
        const response = await APIClient.getData(
            `/portfolio/relationship/portfolio/attribute/${id}`
        );
        return response.data;
    }
);

export const getPortfolioPortfolioAttributesSlice = createSlice({
    name: 'getPortfolioPortfolioAttribute',
    initialState,
    reducers: {
        resetGetPortfolioPortfoliottributes: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getPortfolioPortfolioAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getPortfolioPortfolioAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(getPortfolioPortfolioAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetGetPortfolioPortfoliottributes } =
    getPortfolioPortfolioAttributesSlice.actions;
