/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';

import TableSearch from '../../../components/Table/TableSearch';
// import Create from './components/Create';

import Modal from '../../../components/Modal';
import { validateDate, validateType } from '../../../utils/helpers';
import { TypesAttribute } from '../../../components/enums/enums';
import AttributesTable from './components/AttributesTable';
import {
    fetchPortfolioPersonRelationshipAttributesValues,
    resetValuesPortPersoRel,
} from '../../../modules/portfolio-persons-attributes/features/portfoliosPersonRelationshipValuesSlice';
import { getPortfolioPersonRelationshipEnum } from '../../../modules/portfolio-persons-attributes/features/portfolioPersonAttributeValueEnum';
import { addPortfolioPersonRelationshiptAttribute } from '../../../modules/portfolio-persons-attributes/features/portfolioPersonRelationshipValueSlice';
import {
    fetchPortfolioRelationshipRelAttributesValues,
    resetValuesPortfoliosRelVa,
} from '../../../modules/portfolio-persons-attributes/features/portfolioPersonRelAttributeSlice';
import { fetchSinglePerson } from '../../../modules/persons/features/singlePersonSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    name: string;
    index: number;
    idAttribute: number;
};

export default function ListPortfolioPersonsRelationshipAttributesValues() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormData>();

    const { id, portfolioId } = useParams();

    const [getAvailableAttributes, setGetAvailableAttributes] = useState(true);
    const [showCreate, setShowCreate] = useState(false);

    const [isEnum, setIsEnum] = useState(false);
    const [isUnique, setIsUnique] = useState(false);
    const [idAttribute, setIdAttribute] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const singlePortfolioPerson = useSelector(
        (state: RootState) => state.singlePerson
    );
    const portfolioPersonRelationshipAttributesValues = useSelector(
        (state: RootState) => state.portfolioPersonRelationshipsAttributesValues
    );
    const portfolioPersonRelationshipAttribute = useSelector(
        (state: RootState) => state.PortfoliosPersonsRelationshipsAttributes
    );
    const portfolioCreated = useSelector(
        (state: RootState) => state.portfolioPersonsRelationshipValue
    );
    const singleRelationshipEnum = useSelector(
        (state: RootState) => state.portfolioPersonRelationshipsAttributesEnum
    );
    // const singlePerson = useSelector((state: RootState) => state.singlePerson);

    const portfolioPersonRelAttributes = useSelector(
        (state: RootState) => state.portfolioPersonRelAttributes
    );
    const deletePersonRelVal = useSelector(
        (state: RootState) => state.deletePersonPortfolioRelVal
    );

    useEffect(() => {
        dispatch(resetValuesPortfoliosRelVa());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

        if (singlePortfolioPerson.status === 'idle') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(fetchSinglePerson(portfolioId!));
        }
    }, [dispatch, portfolioId, singlePortfolioPerson.status]);

    useEffect(() => {
        if (
            singlePortfolioPerson.status === 'success' &&
            portfolioPersonRelAttributes.status === 'idle'
        ) {
            const dataRel = singlePortfolioPerson.portfolioRelations.filter(
                (attribute) => attribute.idRelationship === Number(id)
            );

            dispatch(
                fetchPortfolioRelationshipRelAttributesValues(
                    dataRel[0].relationshipTypeId
                )
            );
        }
    }, [
        dispatch,
        id,
        portfolioId,
        portfolioPersonRelAttributes.status,
        singlePortfolioPerson.portfolioRelations,
        singlePortfolioPerson.status,
    ]);
    useEffect(() => {
      if (id && portfolioId){

        dispatch(
            fetchPortfolioPersonRelationshipAttributesValues({
                id,
                portfolioId,
            })
        );
      }
    }, [dispatch, id, portfolioId]);
    useEffect(() => {
        // Filter available attributes for new attribute
        if (
            getAvailableAttributes &&
            portfolioPersonRelationshipAttribute.data.length > 0 &&
            singlePortfolioPerson.attributes.length > 0
        ) {
            // setAvailableAttributes(portfolioPersonRelationshipAttribute.data);
            // setNewAttributeStatus('success');
            setGetAvailableAttributes(false);
        }
    }, [
        getAvailableAttributes,
        portfolioPersonRelationshipAttribute.data.length,
        singlePortfolioPerson.attributes.length,
    ]);

    useEffect(() => {
        if (
            portfolioPersonRelationshipAttributesValues.status === 'idle' &&
            id &&
            portfolioId
        ) {

            dispatch(
                fetchPortfolioPersonRelationshipAttributesValues({
                    id,
                    portfolioId,
                })
            );
        }
    }, [
        dispatch,
        id,
        portfolioId,
        portfolioPersonRelationshipAttributesValues.status,
    ]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.value').toString(),
                accessor: 'data',
            },
            {
                Header: 'Indice',
                accessor: 'index',
            },
        ],

        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: portfolioPersonRelationshipAttributesValues.data || [],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = tableInstance;

    const [msgError, setMsgError] = useState('');
    const [type, setType] = useState(0);
    const value = watch('value');
    const onSubmit = async (data: FormData) => {
        if (data.value === 'N/A') {
            // if(singleRelationshipEnum.data && singleRelationshipEnum.data[0].enum.length > 0){
            // 	const enumNA = singleRelationshipEnum.data[0].enum.filter((e: { name: string; }) => e.name.toUpperCase() === 'N/A')
            // 	if(enumNA.length > 0){
            // 	// eslint-disable-next-line no-param-reassign
            // 	data.index = data.index || 1;
            // 	setAddRequestStatus('loading');
            // 	dispatch(
            // 		addPortfolioPersonRelationshiptAttribute({
            // 			id: id ?? '',
            // 			attributes: [data],
            // 		})
            // 	);
            // 	 dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
            // 	// await create(body);
            // 	setAddRequestStatus('success');
            // 	reset();
            // 	setShowCreate(false);
            // } else{
            // 	dispatch(
            // 		createPortfolioPersonAttributeEnum({
            // 			name: data.value,
            // 			portfolio_client_relationship_attribute: Number(idAttribute),
            // 			// client_relationship_attribute: 0,
            // 		})
            // 	);
            // 	setValue('index', 1);
            // 	// eslint-disable-next-line no-param-reassign
            // 	setValue('idAttribute',  Number(idAttribute));
            // 	setAddRequestStatus('loading');
            // 	dispatch(
            // 		addPortfolioPersonRelationshiptAttribute({
            // 			id: id ?? '',
            // 			attributes: [data],
            // 		})
            // 	);
            // 	dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
            // 	// await create(body);
            // 	setAddRequestStatus('success');
            // 	reset();
            // 	setShowCreate(false);
            // }
            // } else {
            // 	dispatch(
            // 		createPortfolioPersonAttributeEnum({
            // 			name: data.value,
            // 			portfolio_client_relationship_attribute: Number(idAttribute),
            // 			// client_relationship_attribute: 0,
            // 		})
            // 	);
            // 	setValue('index', 1);
            // 	setAddRequestStatus('loading');
            // 	dispatch(
            // 		addPortfolioPersonRelationshiptAttribute({
            // 			id: id ?? '',
            // 			attributes: [data],
            // 		})
            // 	);
            // 	dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
            // 	// await create(body);
            // 	setAddRequestStatus('success');
            // 	reset();
            // 	setShowCreate(false);
            // }
        } else {
            if (type === TypesAttribute.Number) {
                // eslint-disable-next-line no-param-reassign
                data.value = Number(data.value);
            }
            if (type === TypesAttribute.Date) {
                let splitDate = '';
                if (value) {
                    splitDate = value.split('-');
                }

                const isValidDate = validateDate(splitDate, value);

                if (isValidDate) {
                    // eslint-disable-next-line no-param-reassign
                    data.value = isValidDate;
                } else {
                    setMsgError(
                        'Formato fecha invalido debe ingresar dd-mm-yyyy'
                    );
                }
            }

            // eslint-disable-next-line no-param-reassign
            data.index = data.index || 1;
            // eslint-disable-next-line no-param-reassign
            data.idAttribute = Number(idAttribute);

            setAddRequestStatus('loading');
            dispatch(
                addPortfolioPersonRelationshiptAttribute({
                    id: id ?? '',
                    attributes: [data],
                })
            );

            //  dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
            // await create(body);
            setAddRequestStatus('success');
            reset();
            setShowCreate(false);
        }
    };

    useEffect(() => {
        if (
            portfolioPersonRelAttributes.status === 'success' &&
            addRequestStatus === 'success' &&
            id &&
            portfolioId
        ) {
            dispatch(resetValuesPortfoliosRelVa());
            dispatch(resetValuesPortPersoRel());
            // dispatch(
            //     fetchPortfolioPersonRelationshipAttributesValues({
            //         id,
            //         portfolioId,
            //     })
            // );
            reset();
            setShowCreate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioCreated.status, id, portfolioId, idAttribute, dispatch]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('value', '');
        setIsEnum(false);
        setIsUnique(false);

        if (e.target.selectedOptions[0].getAttribute('data-type') === '3') {
            setType(3);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '4') {
            setType(4);
        }
        if (
            e.target.selectedOptions[0].getAttribute('data-unique') === 'true'
        ) {
            setIsUnique(true);
        }

        if (e.target.selectedOptions[0].getAttribute('data-enum') === 'true') {
            setIsEnum(true);

            setIdAttribute(
                e.target.selectedOptions[0].getAttribute(
                    'data-id'
                ) as SetStateAction<string>
            );
        }
        setIdAttribute(
            e.target.selectedOptions[0].getAttribute(
                'data-id'
            ) as SetStateAction<string>
        );
    };

    useEffect(() => {
        if (idAttribute) {
            dispatch(getPortfolioPersonRelationshipEnum(Number(idAttribute)));
            setIdAttribute(idAttribute);
        }
    }, [dispatch, idAttribute]);
    useEffect(() => {
        if (deletePersonRelVal.status === 'success') {
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, [deletePersonRelVal.status, dispatch]);

    return (
        <div className="relative mb-20">
            {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}

            <PageHeader title="Valor atributo de relación cliente" />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {singlePortfolioPerson.status === 'loading' ||
                (portfolioPersonRelationshipAttributesValues.status ===
                    'loading' && (
                    <LoadingAnimated message={t('common.loading')} />
                ))}

            {portfolioPersonRelationshipAttributesValues.status === 'success' &&
                singlePortfolioPerson.status === 'success' &&
                portfolioPersonRelationshipAttributesValues.data.length ===
                    0 && (
                    <h3 className="text-lg text-center">
                        No existen valores para esta relación
                    </h3>
                )}

            <div>
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={
                        singlePortfolioPerson.status === 'loading'
                            ? 'cargando...'
                            : t('attributes.create.title')
                    }
                    type="button"
                    disabled={
                        portfolioPersonRelAttributes.status === 'loading' ||
                        singlePortfolioPerson.status === 'loading'
                    }
                    onClick={() => setShowCreate(!showCreate)}
                />
            </div>
            {portfolioPersonRelationshipAttributesValues.status === 'success' &&
                portfolioPersonRelationshipAttributesValues.data.length > 0 && (
                    <AttributesTable
                        clientId={id || ''}
                        data={portfolioPersonRelationshipAttributesValues.data}
                    />
                )}
            {showCreate && (
                <Modal
                    setShowModal={setShowCreate}
                    onClose={() => setShowCreate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('attributes.create.addNew')}
                        </h3>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('attributes.title')} *
                                        <div className="mt-1">
                                            <select
                                                id="name"
                                                className={`${
                                                    errors.name
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register('name', {
                                                    required: true,
                                                    onChange: handleChange,
                                                })}
                                            >
                                                <option value="">
                                                    {t(
                                                        'attributes.create.selectAnAttribute'
                                                    )}
                                                </option>
                                                {portfolioPersonRelAttributes.status ===
                                                    'success' &&
                                                    portfolioPersonRelAttributes
                                                        .data.length > 0 &&
                                                    portfolioPersonRelAttributes.data.map(
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        (attribute: any) => (
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.name
                                                                }
                                                                data-enum={
                                                                    attribute.type ===
                                                                    2
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-type={
                                                                    attribute.type
                                                                }
                                                                data-unique={
                                                                    attribute.is_unique
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-id={
                                                                    attribute.id
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                {/* END Attributes Selection */}

                                {isEnum ? (
                                    <div className="w-full mt-4 mb-4">
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Valores de atributo *
                                            <div className="mt-1">
                                                <select
                                                    id="value"
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'attributes.create.selectAnAttribute'
                                                        )}
                                                    </option>
                                                    {singleRelationshipEnum.status ===
                                                        'success' &&
                                                        singleRelationshipEnum
                                                            .data.length > 0 &&
                                                        singleRelationshipEnum.data.map(
                                                            (attribute) =>
                                                                attribute.enum
                                                                    .length >
                                                                0 ? (
                                                                    attribute.enum.map(
                                                                        (
                                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                            e: any
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    e.id
                                                                                }
                                                                                value={
                                                                                    e.name
                                                                                }
                                                                                data-enum={
                                                                                    attribute.type ===
                                                                                    2
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-unique={
                                                                                    attribute.is_unique
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-id={
                                                                                    e.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <option
                                                                        key={
                                                                            attribute.id
                                                                        }
                                                                        disabled
                                                                    >
                                                                        No
                                                                        existen
                                                                        valores
                                                                    </option>
                                                                )
                                                        )}
                                                </select>
                                            </div>
                                        </label>

                                        {errors.name && (
                                            <p className="text-red-600 ml-2">
                                                {t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('common.value')} *
                                            <div className="mt-1">
                                                <input
                                                    id="value"
                                                    type={validateType(type)}
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                />
                                                {errors.value && (
                                                    <p className="text-red-600 ml-2">
                                                        {errors.value.type ===
                                                            'required' &&
                                                            t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                    </p>
                                                )}
                                                {msgError !== '' && (
                                                    <p className="text-red-600 ml-2">
                                                        {msgError}
                                                    </p>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                )}
                                {/*  */}

                                {isUnique === false && (
                                    <div className="mt-3">
                                        <label
                                            htmlFor="index"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {' '}
                                            Indice
                                            <div className="mt-1">
                                                <input
                                                    {...register('index', {})}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    id="index"
                                                    className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )}
                                <div className="mt-4">
                                    {addRequestStatus === 'error' && (
                                        <p className="text-red-600 mb-2">
                                            {t('attributes.create.error')}
                                        </p>
                                    )}
                                    {addRequestStatus === 'success' && (
                                        <p className="text-green-600 mb-2">
                                            {t('attributes.create.success')}
                                        </p>
                                    )}
                                    <button
                                        disabled={
                                            addRequestStatus === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {addRequestStatus === 'loading'
                                            ? t('wait.message')
                                            : t('common.save')}
                                    </button>
                                    <Button
                                        type="button"
                                        variant="link"
                                        className="w-full mt-10"
                                        label={t('common.cancel')}
                                        onClick={() => setShowCreate(false)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
