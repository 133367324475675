
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolio } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface UsersState {
    data: IPortfolio[];
    status: Status;
    error: string | null;
}

const initialState: UsersState = {
    data: [],
    status: Status.idle,
    error: null,
};

interface IEntityPortfolio {
	reference_id: string;
	client: number  | string | null;
	company: number | string  | null;
}
interface IEntityUpdatePortfolio {
	id: number;
	reference_id: string;
	client: number  | string | null;
	company: number | string  | null;
}

export const createPortfolio = createAsyncThunk(
    'singlePortfolio/createPortfolio',
    // eslint-disable-next-line camelcase
    async ({client, company, reference_id}:IEntityPortfolio) => {
        const response = await APIClient.postData(`/portfolio/`, {
			client_id: client,
			company_id: company,
			// eslint-disable-next-line camelcase
			reference_id,
        });
        return response.data;
    }
);

export const updatePortfolio = createAsyncThunk(
    'singlePortfolio/updatePortfolio',
    // eslint-disable-next-line camelcase
    async ({id, reference_id, client, company}:IEntityUpdatePortfolio) => {
        const response = await APIClient.putDataEntity(`/portfolio/id/${id}/`, {
			client_id: client, 
			company_id: company,
			// eslint-disable-next-line camelcase
			reference_id, 
        });
        return response.data;
    }
);


export const deletePortfolio = createAsyncThunk(
    'singlePerson/deletePortfolio',
    async ({id}:{id:number}) => {
        const response = await APIClient.deleteDataEntiy(`/portfolio/id/${id}/`);
        return response.data;
    }
);


export const portfolioEntitySlice = createSlice({
    name: 'portfolioEntity',
    initialState,
    reducers: {
		resetEntityPortfolio: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(createPortfolio.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPortfolio.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(createPortfolio.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePortfolio.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updatePortfolio.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(updatePortfolio.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(deletePortfolio.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deletePortfolio.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = [];
            })
            .addCase(deletePortfolio.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
export const { resetEntityPortfolio } = portfolioEntitySlice.actions;
