/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';

import TableSearch from '../../../components/Table/TableSearch';
// import Create from './components/Create';

import Modal from '../../../components/Modal';
import { validateDate, validateType } from '../../../utils/helpers';
import { TypesAttribute } from '../../../components/enums/enums';
import { addPortfolioCompanyRelationshiptAttribute } from '../../../modules/portfolio-companies-attributes/features/portfolioCompanyRelationshipValue';
import { getPortfolioCompanyRelationshipEnum } from '../../../modules/portfolio-companies-attributes/features/portfolioCompanyAttributeValueEnum';
import {
    fetchPortfolioCompanyRelationshipAttributesValues,
    resetValuesPortCompRel,
} from '../../../modules/portfolio-companies-attributes/features/portfoliosCompaniesRealtionshipValues';
import { fetchPortfolioCompanyAttributes } from '../../../modules/portfolio-companies-attributes/features/portfoliosPersonsAttributeSlice';
import AttributesTable from './components/AttributesTable';
import { fetchSingleBusiness } from '../../../modules/business/features/singleBusinessSlice';
import { resetValuesDeleteRelCompanyPort } from '../../../modules/business-portfolio-relationships/features/deleteCompanyBusinessAttributeRelSlice';
import { getPortfolioCompanyAttribute } from '../../../modules/portfolio-companies-attributes/features/getPortfolioCompanyAttributeSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    name: string;
    index: number;
    idAttribute: number;
};

export default function ListPortfolioCompaniesRelationshipAttributesValues() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormData>();

    const { id, portfolioId } = useParams();

    const [getAvailableAttributes, setGetAvailableAttributes] = useState(true);
    const [, setNewAttributeStatus] = useState('idle');
    const [showCreate, setShowCreate] = useState(false);

    const [isEnum, setIsEnum] = useState(false);
    const [isUnique, setIsUnique] = useState(false);
    const [idAttribute, setIdAttribute] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [, setAvailableAttributes] = useState<any>();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const portfolioCompanyRelationshipAttributes = useSelector(
        (state: RootState) =>
            state.portfolioCompanyRelationshipsAttributesValues
    );
    const PortfolioCompanyRelationshipAttribute = useSelector(
        (state: RootState) => state.portfoliosCompanyRelationshipsAttributes
    );

    const portfolioCreated = useSelector(
        (state: RootState) => state.portfolioCompaniesRelationshipValue
    );
    const portfolioDeleted = useSelector(
        (state: RootState) => state.deleteCompanyPort
    );
    // const personRelationships = useSelector(
    //     (state: RootState) => state.personPersonRelationships
    // );
    // const personsRelationshipsAttributesValues = useSelector(
    //     (state: RootState) => state.personsRelationshipsAttributesValues
    // );
    // const personsRelationshipValues = useSelector(
    //     (state: RootState) => state.personsRelationshipValue
    // );

    const singleRelationshipEnum = useSelector(
        (state: RootState) => state.portfolioCompanyRelationshipsAttributesEnum
    );

    const singleBusiness = useSelector(
        (state: RootState) => state.singleBusiness
    );

    const companyRelAttributes = useSelector(
        (state: RootState) => state.portfolioPersonRelAttributes
    );
    const companyPortRelAttributes = useSelector(
        (state: RootState) => state.getPortfolioCompanyAttributes
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

        if (singleBusiness.status === 'idle') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(fetchSingleBusiness(portfolioId!));
        }
    }, [dispatch, portfolioId, singleBusiness.status]);

    useEffect(() => {
        if (
            companyRelAttributes.status === 'idle' &&
            singleBusiness.status === 'success'
        ) {
            const dataRel = singleBusiness.portfolioRelations.filter(
                (attribute) => attribute.idRelationship === Number(id)
            );
            dispatch(
                getPortfolioCompanyAttribute(dataRel[0].relationshipTypeId)
                // fetchPortfolioCompanyRelationshipAttributesValues(
                //     dataRel[0].relationshipTypeId
                // );
            );
        }
    }, [
        companyRelAttributes.status,
        dispatch,
        id,
        singleBusiness.portfolioRelations,
        singleBusiness.status,
    ]);

    // const singlePerson = useSelector((state: RootState) => state.singlePerson);
    useEffect(() => {
        // Filter available attributes for new attribute
        if (
            getAvailableAttributes &&
            PortfolioCompanyRelationshipAttribute.data.length > 0
            // singlePerson.attributes.length > 0
        ) {
            setAvailableAttributes(PortfolioCompanyRelationshipAttribute.data);
            setNewAttributeStatus('success');
            setGetAvailableAttributes(false);
        }
    }, [PortfolioCompanyRelationshipAttribute.data, getAvailableAttributes]);

    useEffect(() => {
        if (
            portfolioCompanyRelationshipAttributes.status === 'idle' &&
            id &&
            portfolioId
        ) {
            dispatch(
                fetchPortfolioCompanyRelationshipAttributesValues({
                    id,
                    portfolioId,
                })
            );
        }
    }, [
        dispatch,
        id,
        portfolioId,
        portfolioCompanyRelationshipAttributes.status,
    ]);

    useEffect(() => {
        if (
            PortfolioCompanyRelationshipAttribute.status === 'idle'
            // personRelationships.status === 'idle'
        ) {
            dispatch(fetchPortfolioCompanyAttributes());
            // dispatch(fetchPersonPersonRelationships());
        }
    }, [dispatch, PortfolioCompanyRelationshipAttribute.status]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.value').toString(),
                accessor: 'data',
            },
            {
                Header: 'Indice',
                accessor: 'index',
            },
        ],

        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: portfolioCompanyRelationshipAttributes.data || [],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = tableInstance;

    const [msgError, setMsgError] = useState('');
    const [type, setType] = useState(0);
    const value = watch('value');
    const onSubmit = async (data: FormData) => {
        try {
            if (data.value === 'N/A') {
                // if(singleRelationshipEnum.data && singleRelationshipEnum.data[0].enum.length > 0){
                // 	const enumNA = singleRelationshipEnum.data[0].enum.filter((e: { name: string; }) => e.name.toUpperCase() === 'N/A')
                // 	if(enumNA.length > 0){
                // 		// eslint-disable-next-line no-param-reassign
                // 		data.index = data.index || 1;
                // 		setAddRequestStatus('loading');
                // 		await dispatch(
                // 			addPortfolioCompanyRelationshiptAttribute({
                // 				id: id ?? '',
                // 				attributes: [data],
                // 			})
                // 		);
                // 		await dispatch(fetchCompanyRelationshipAttributesValues(id ?? ''));
                // 		// await create(body);
                // 		setAddRequestStatus('success');
                // 		reset();
                // 		setShowCreate(false);
                // 	} else{
                // 		await dispatch(
                // 			createPortfolioCompanyAttributeEnum({
                // 				name: data.value,
                // 				portfolio_company_relationship_attribute: Number(idAttribute),
                // 				// client_relationship_attribute: 0,
                // 			})
                // 		);
                // 		setValue('index', 1);
                // 		setAddRequestStatus('loading');
                // 		await dispatch(
                // 			addPortfolioCompanyRelationshiptAttribute({
                // 				id: id ?? '',
                // 				attributes: [data],
                // 			})
                // 		);
                // 		dispatch(fetchCompanyRelationshipAttributesValues(id ?? ''));
                // 		// await create(body);
                // 		setAddRequestStatus('success');
                // 		reset();
                // 		setShowCreate(false);
                // 	}
                // } else {
                // 	await dispatch(
                // 		createPortfolioCompanyAttributeEnum({
                // 			name: data.value,
                // 			portfolio_company_relationship_attribute: Number(idAttribute),
                // 			// client_relationship_attribute: 0,
                // 		})
                // 	);
                // 	setValue('index', 1);
                // 	setAddRequestStatus('loading');
                // 	await dispatch(
                // 		addPortfolioCompanyRelationshiptAttribute({
                // 			id: id ?? '',
                // 			attributes: [data],
                // 		})
                // 	);
                // 	await dispatch(fetchCompanyRelationshipAttributesValues(id ?? ''));
                // 	// await create(body);
                // 	setAddRequestStatus('success');
                // 	reset();
                // 	setShowCreate(false);
                // }
            } else {
                if (type === TypesAttribute.Number) {
                    // eslint-disable-next-line no-param-reassign
                    data.value = Number(data.value);
                }
                if (type === TypesAttribute.Date) {
                    let splitDate = '';
                    if (value) {
                        splitDate = value.split('-');
                    }

                    const isValidDate = validateDate(splitDate, value);

                    if (isValidDate) {
                        // eslint-disable-next-line no-param-reassign
                        data.value = isValidDate;
                    } else {
                        setMsgError(
                            'Formato fecha invalido debe ingresar dd-mm-yyyy'
                        );
                    }
                }

                // eslint-disable-next-line no-param-reassign
                data.index = data.index || 1;
                // setValue('idAttribute',  Number(idAttribute));
                // eslint-disable-next-line no-param-reassign
                data.idAttribute = Number(idAttribute);
                setAddRequestStatus('loading');
                dispatch(
                    addPortfolioCompanyRelationshiptAttribute({
                        id: id ?? '',
                        attributes: [data],
                    })
                );
                // dispatch(fetchPortfolioCompanyRelationshipAttributesValues(id || ''));
                //  dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
                // await create(body);
                setAddRequestStatus('success');
                reset();
                setShowCreate(false);
            }
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };
    useEffect(() => {
        if (addRequestStatus === 'success') {
            setTimeout(() => {
                window.location.reload();

                // dispatch(fetchPortfolioPersonRelationshipAttributesValues(id ?? ''));
                reset();
                setShowCreate(false);
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioCreated.status, idAttribute, dispatch]);
    
    useEffect(() => {
        if (id && portfolioId) {
            dispatch(
                fetchPortfolioCompanyRelationshipAttributesValues({
                    id,
                    portfolioId,
                })
            );
        }
    }, [dispatch, id, portfolioId]);

    useEffect(() => {
        if (portfolioDeleted.status === 'success' && portfolioId && id) {
            dispatch(resetValuesDeleteRelCompanyPort());

            dispatch(resetValuesPortCompRel());
            dispatch(
                fetchPortfolioCompanyRelationshipAttributesValues({
                    id,
                    portfolioId,
                })
            );

            // dispatch(fetchPortfolioPersonRelationshipAttributesValues(id ?? ''));
            reset();
            setShowCreate(false);
        }
    }, [dispatch, id, portfolioId, portfolioDeleted.status, reset]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('value', '');
        setIsEnum(false);
        setIsUnique(false);
        if (e.target.selectedOptions[0].getAttribute('data-type') === '1') {
            setType(1);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '2') {
            setType(2);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '3') {
            setType(3);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '4') {
            setType(4);
        }
        if (
            e.target.selectedOptions[0].getAttribute('data-unique') === 'true'
        ) {
            setIsUnique(true);
        }

        if (e.target.selectedOptions[0].getAttribute('data-enum') === 'true') {
            setIsEnum(true);

            setIdAttribute(
                e.target.selectedOptions[0].getAttribute(
                    'data-id'
                ) as SetStateAction<string>
            );
        }
        setIdAttribute(
            e.target.selectedOptions[0].getAttribute(
                'data-id'
            ) as SetStateAction<string>
        );
    };

    useEffect(() => {
        if (idAttribute) {
            dispatch(getPortfolioCompanyRelationshipEnum(Number(idAttribute)));
        }
    }, [dispatch, idAttribute]);

    return (
        <div className="relative mb-20">
            {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}

            <PageHeader title="Valor atributo de relación jurídica" />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {portfolioCompanyRelationshipAttributes.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {portfolioCompanyRelationshipAttributes.status === 'success' &&
                portfolioCompanyRelationshipAttributes.data &&
                portfolioCompanyRelationshipAttributes.data.length === 0 && (
                    <h3 className="text-lg text-center">
                        No existen valores para esta relación
                    </h3>
                )}
            <div>
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={t('attributes.create.title')}
                    type="button"
                    onClick={() => setShowCreate(!showCreate)}
                />
            </div>
            {portfolioCompanyRelationshipAttributes.status === 'success' &&
                portfolioCompanyRelationshipAttributes.data.length > 0 && (
                    <AttributesTable
                        clientId={id || ''}
                        data={portfolioCompanyRelationshipAttributes.data}
                    />
                )}
            {showCreate && (
                <Modal
                    setShowModal={setShowCreate}
                    onClose={() => setShowCreate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('attributes.create.addNew')}
                        </h3>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('attributes.title')} *
                                        <div className="mt-1">
                                            <select
                                                id="name"
                                                className={`${
                                                    errors.name
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register('name', {
                                                    required: true,
                                                    onChange: handleChange,
                                                })}
                                            >
                                                <option value="">
                                                    {t(
                                                        'attributes.create.selectAnAttribute'
                                                    )}
                                                </option>
                                                {companyPortRelAttributes.status ===
                                                    'success' &&
                                                    companyPortRelAttributes
                                                        .data.length > 0 &&
                                                    companyPortRelAttributes.data.map(
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        (attribute: any) => (
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.name
                                                                }
                                                                data-enum={
                                                                    attribute.type ===
                                                                    2
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-type={
                                                                    attribute.type
                                                                }
                                                                data-unique={
                                                                    attribute.is_unique
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-id={
                                                                    attribute.id
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                {/* END Attributes Selection */}

                                {isEnum ? (
                                    <div className="w-full mt-4 mb-4">
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Valores de atributo *
                                            <div className="mt-1">
                                                <select
                                                    id="value"
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'attributes.create.selectAnAttribute'
                                                        )}
                                                    </option>
                                                    {singleRelationshipEnum.status ===
                                                        'success' &&
                                                        singleRelationshipEnum
                                                            .data.length > 0 &&
                                                        singleRelationshipEnum.data.map(
                                                            (attribute) =>
                                                                attribute.enum
                                                                    .length >
                                                                0 ? (
                                                                    attribute.enum.map(
                                                                        (
                                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                            e: any
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    e.id
                                                                                }
                                                                                value={
                                                                                    e.name
                                                                                }
                                                                                data-enum={
                                                                                    attribute.type ===
                                                                                    2
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-unique={
                                                                                    attribute.is_unique
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-id={
                                                                                    e.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <option
                                                                        key={
                                                                            attribute.id
                                                                        }
                                                                        value="N/A"
                                                                    >
                                                                        No
                                                                        existen
                                                                        valores
                                                                    </option>
                                                                )
                                                        )}
                                                </select>
                                            </div>
                                        </label>

                                        {errors.name && (
                                            <p className="text-red-600 ml-2">
                                                {t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('common.value')} *
                                            <div className="mt-1">
                                                <input
                                                    id="value"
                                                    type={validateType(type)}
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                />
                                                {errors.value && (
                                                    <p className="text-red-600 ml-2">
                                                        {errors.value.type ===
                                                            'required' &&
                                                            t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                    </p>
                                                )}
                                                {msgError !== '' && (
                                                    <p className="text-red-600 ml-2">
                                                        {msgError}
                                                    </p>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                )}
                                {/*  */}

                                {isUnique === false && (
                                    <div className="mt-3">
                                        <label
                                            htmlFor="index"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {' '}
                                            Indice
                                            <div className="mt-1">
                                                <input
                                                    {...register('index', {})}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    id="index"
                                                    className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )}
                                <div className="mt-4">
                                    {addRequestStatus === 'error' && (
                                        <p className="text-red-600 mb-2">
                                            {t('attributes.create.error')}
                                        </p>
                                    )}
                                    {addRequestStatus === 'success' && (
                                        <p className="text-green-600 mb-2">
                                            {t('attributes.create.success')}
                                        </p>
                                    )}
                                    <button
                                        disabled={
                                            addRequestStatus === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {addRequestStatus === 'loading'
                                            ? t('wait.message')
                                            : t('common.save')}
                                    </button>
                                    <Button
                                        type="button"
                                        variant="link"
                                        className="w-full mt-10"
                                        label={t('common.cancel')}
                                        onClick={() => setShowCreate(false)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
