import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface DeletePersonCompanyRelationshipAttributeState {
    data: null;
    status: Status;
    error: string | null;
}

const initialState: DeletePersonCompanyRelationshipAttributeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const deletePersonCompanyRelationshipRelAttributesValues = createAsyncThunk(
    'deletePersonACompanyttributes/deletePersonCompanyAttributesRelValues',
    async ({ id, idRel }: { id: string; idRel: string }) => {
        const response = await APIClient.deleteDataEntiy(
            `/company/relationship/id/${idRel}/detail/${id}/`
        );
        return response.data;
    }
);

export const deletePersonsCompanyRelationshipAttributesRelValuesSlice = createSlice({
    name: 'deletePersonCompanyRelationshipAttributesValues',
    initialState,
    reducers: {
        resetValuesDeleteRelPersonCompany: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                deletePersonCompanyRelationshipRelAttributesValues.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                deletePersonCompanyRelationshipRelAttributesValues.fulfilled,
                (state) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    // state.data = action.payload;
                }
            )
            .addCase(
                deletePersonCompanyRelationshipRelAttributesValues.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetValuesDeleteRelPersonCompany } =
    deletePersonsCompanyRelationshipAttributesRelValuesSlice.actions;
