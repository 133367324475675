/* eslint-disable react/jsx-props-no-spreading */
import { t } from 'i18next';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Column, useTable, useSortBy, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import PageHeader from '../../../../components/PageHeader';
import AutoComplete from '../../../../components/Autocomplete';
import { IEntity } from '../../../../modules/common/interfaces';
import Button from '../../../../components/elements/Buttons/Button';
import Modal from '../../../../components/Modal';
import { useAppDispatch, RootState } from '../../../../store';
import { IPortfolio } from '../../../../modules/portfolios/interfaces/index';
import {
    fetchPortfolios,
    addPortfolioPortfolioRelation,
} from '../../../../modules/portfolios/features/porfoliosSlice';
import { fetchSinglePortfolio } from '../../../../modules/portfolios/features/singlePortfolioSlice';
import { fetchPortfolioPortfolioRelationships } from '../../../../modules/portfolio-portfolio-relationships/features/portfolioPortfolioRelationshipSlice';
import { resetValues } from '../../../../modules/persons-relationships-attributes/features/personRelationshipAttributesValuesSlice';
import { deletePortfolioPortfolioRelationships } from '../../../../modules/portfolio-portfolio-relationships/features/deletePortfolioPortfolioRelSlice';

type FormData = {
    portfolio_to: number;
    relationship_name: string;
};

function AutoCompleteItem({
    item,
    onClick,
}: {
    item: IPortfolio;
    onClick: () => void;
}) {
    return (
        <li>
            <button
                type="button"
                onClick={onClick}
                className="text-left hover:bg-slate-200 flex gap-4 p-4 w-full"
            >
                <div>
                    <h3 className="text-sm font-semibold">
                        {item.reference_id}
                    </h3>
                </div>
            </button>
        </li>
    );
}

export default function PortfolioPortRelTable({
    data,
    clientId,
}: {
    data: IEntity[];
    clientId: number;
}) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<FormData>();
    
    const relations = useSelector(
        (state: RootState) => state.portfolioPortfolioRelationships
    );
    const portfolios = useSelector((state: RootState) => state.portfolios);

    const [showAdd, setShowAdd] = useState(false);
    const [addRelationshipState, setAddRelationshipState] = useState('idle');
    const [selectedValue, setSelectedValue] = useState<string>('');

 
    useEffect(() => {

        if (relations.status === 'idle') {
            dispatch(fetchPortfolioPortfolioRelationships());
        }

    }, [dispatch, relations.status]);
    
    useEffect(() => {

        if (portfolios.status === 'idle') {
            dispatch(fetchPortfolios());
        }

    }, [dispatch, portfolios.status]);
    



    const deletePersonRel = useCallback(
        (e, idRel: number) => {
            e.preventDefault();
            try {
                const payloadDelete = {
                    idRel,
                };
                dispatch(deletePortfolioPortfolioRelationships(payloadDelete));
                // setAttributesState('success');
            } catch (error) {
                setAddRelationshipState('error');
            }
        },
        [dispatch]
    );

    const columns = useMemo<Column<IEntity>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
                // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: { cell: { value: any } }) =>
                    value ? (
                        <a href={`/portfolio/${value}`} className="underline">
                            {value}
                        </a>
                    ) : (
                        value
                    ),
            },
            {
                Header: t('common.entity').toString(),
                accessor: 'entity',
            },
            {
                Header: t('common.relationship').toString(),
                accessor: 'name',
            },
            {
                Header: t('delete.title').toString(),
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({
                    row: {
                        original: { idRelationship },
                    },
                }: {
                    row: {
                        original: {
                            idRelationship: number;
                        };
                    };
                }) => (
                    <button
                        type="button"
                        className="hover:bg-red-200"
                        onClick={(e) => deletePersonRel(e, idRelationship)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                        </svg>
                    </button>
                ),
            },
            {
                Header: t('relAttributes.title').toString(),
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({
                    row: {
                        original: { idRelationship },
                    },
                }: {
                    row: {
                        original: {
                            idRelationship: string;
                            id: number;
                        };
                    };
                }) => (
                    <button
                        type="button"
                        onClick={() => {
                            dispatch(resetValues());
                            navigate(
                                `/portfolio-portfolio-relationship-attributes-values/${idRelationship}/${clientId}/`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                            />
                        </svg>
                    </button>
                ),
            },
        ],

        [clientId, deletePersonRel, dispatch, navigate]
    );
    
    const tableInstance = useTable(
    {
        columns,
        data: data || [],
        defaultColumn: {
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        },
        initialState: {
            pageSize: 100,
            pageIndex: 0,
        },
    },
    useSortBy,
    usePagination
    );
        
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;
    
    const clickSearchItem = (clientTo: number) => {
        const portf = portfolios.data.find(
         (portfolio: IPortfolio) => portfolio.id === clientTo
        );
        if (portf) {
            setSelectedValue(`${portf?.reference_id}`);
            setValue('portfolio_to', portf.id);
        }
    };
    
    const onSubmit = async (dat: FormData) => {
        const attributes = {
            portfolio_from: clientId,
            portfolio_to: dat.portfolio_to,
            relationship_name: dat.relationship_name,
        };

        try {
            setAddRelationshipState('loading');
            await dispatch(
                addPortfolioPortfolioRelation({
                    id: clientId.toString(),
                    attributes,
                })
            ).unwrap();
            await dispatch(fetchSinglePortfolio(clientId.toString()));
            setAddRelationshipState('success');
        } catch (e) {
            setAddRelationshipState('error');
        }
    };

    return (
        <div className="relative mb-20">
            <PageHeader title="Relaciones cuentas de inversión" />
            <div>
                <Button
                    label="Agregar nueva relación"
                    type="button"
                    onClick={() => setShowAdd(!showAdd)}
                />
            </div>
            {data && data.length === 0 && (
                <h3 className="text-lg text-center">No existen relaciones</h3>
            )}
            {data && data?.length > 0 && (
                <>
                    {pageOptions.length > 1 && (
                        <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="overflow-x-auto shadow-md">
                        <table
                            className="table-auto w-full mx-auto"
                            {...getTableProps()}
                        >
                            <thead className="text-white">
                                {
                                    // Loop over the header rows

                                    headerGroups.map((headerGroup) => (
                                        // Apply the header row props

                                        <tr
                                            className="text-left"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {
                                                // Loop over the headers in each row

                                                headerGroup.headers.map(
                                                    (column) => (
                                                        // Apply the header cell props

                                                        <th
                                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                minWidth: '2%',
                                                            }}
                                                        >
                                                            <div className="flex justify-between">
                                                                {
                                                                    // Render the header

                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                }
                                                                {column.canSort ? (
                                                                    <span
                                                                        className={`${
                                                                            column.isSorted &&
                                                                            'text-sym-primary-500'
                                                                        }`}
                                                                    >
                                                                        {column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M19 9l-7 7-7-7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                        {!column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M5 15l7-7 7 7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                    )
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    // Loop over the table rows

                                    page.map((row) => {
                                        // Prepare the row for display

                                        prepareRow(row);

                                        return (
                                            // Apply the row props

                                            <tr
                                                className="odd:bg-slate-200 hover:bg-slate-300"
                                                {...row.getRowProps()}

                                            >
                                                {
                                                    // Loop over the rows cells

                                                    row.cells.map((cell) => (
                                                        // Apply the cell props

                                                        <td
                                                            className="py-4 px-2 cursor-pointer"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents

                                                                cell.render(
                                                                    'Cell'
                                                                )
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {pageOptions.length > 1 && (
                        <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                </>
            )}

            {showAdd && (
                <Modal
                    setShowModal={setShowAdd}
                    onClose={() => {
                        reset();
                        setSelectedValue('');
                        setShowAdd(false);
                    }}
                    className="h-[600px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('person-person-relationship.add.addNew')}
                        </h3>
                        {/* START Business Selection */}
                        <div>
                            <div className="w-full mt-4">
                                <div className="block text-sm font-medium text-gray-700 -mb-2">
                                    Cuentas de inversión*
                                </div>
                                <AutoComplete
                                    getItems={({
                                        query,
                                    }: {
                                        query: string;
                                    }) => {
                                        setSelectedValue('');
                                        const items = portfolios.data?.filter(
                                            (portfolio) =>
                                                portfolio?.reference_id
                                                    ?.toLowerCase()
                                                    .includes(
                                                        query.toLowerCase()
                                                    )
                                        );
                                        if (!items) {
                                            return [];
                                        }
                                        return items;
                                    }}
                                    sourceId="client_to"
                                    onClick={clickSearchItem}
                                    Item={AutoCompleteItem}
                                    value={selectedValue}
                                    placeholder="Buscar Cuenta de inversión"
                                />
                            </div>
                            {/* END Business Selection */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="w-full -mt-4 mb-4">
                                    <label
                                        htmlFor="relationship_name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.relationship')} *
                                        <div className="mt-1">
                                            <select
                                                id="relationship_name"
                                                className={`${
                                                    errors.relationship_name
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register(
                                                    'relationship_name',
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                            >
                                                <option value="">
                                                    {t(
                                                        'person-person-relationship.add.selectARelationship'
                                                    )}
                                                </option>
                                                {relations.status ===
                                                    'success' &&
                                                    relations.data.length > 0 &&
                                                    relations.data.map(
                                                        (attribute) => (
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.name
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.relationship_name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                <div className="mt-4">
                                    {addRelationshipState === 'error' && (
                                        <p className="text-red-600 mb-2">
                                            {t('attributes.create.error')}
                                        </p>
                                    )}
                                    {addRelationshipState === 'success' && (
                                        <p className="text-green-600 mb-2">
                                            {t('attributes.create.success')}
                                        </p>
                                    )}
                                    <button
                                        disabled={
                                            addRelationshipState === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {addRelationshipState === 'loading'
                                            ? t('wait.message')
                                            : t('common.save')}
                                    </button>
                                    <Button
                                        type="button"
                                        variant="link"
                                        className="w-full mt-10"
                                        label={t('common.cancel')}
                                        onClick={() => {
                                            setShowAdd(false);
                                            setSelectedValue('');
                                            reset();
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
