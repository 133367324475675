/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IEntity, IItemAttribute } from '../../common/interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface SliceState {
    id: string | null;
    attributes: IItemAttribute[];
    personRelations: IEntity[];
    businessRelations: IEntity[];
    portfolioRelations: IEntity[];
    status: Status;
    error: string | null;
}

const initialState: SliceState = {
    id: null,
    attributes: [],
    personRelations: [],
    businessRelations: [],
    portfolioRelations: [],
    status: Status.idle,
    error: null,
};

export const updatePortfolioAttributes = createAsyncThunk(
    'singlePortfolio/updatePortfolioAttributes',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string | number }[];
    }) => {
        const response = await APIClient.postData(
            `/portfolio/id/${id}/detail/`,
            {
                data: attributes,
            }
        );

        return response.data;
    }
);

export const addPortfolioAttributes = createAsyncThunk(
    'singlePortfolio/addPortfolioAttributes',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string | number, index:number }[];
    }) => {
        const neWData: any = []
        
        attributes.forEach(element => {
            neWData.push(            {
                name: element.name,
                value: element.value,
                index: Number(element.index )
            })

        });
        const response = await APIClient.postData(
            `/portfolio/id/${id}/detail/`,
            {
                data: neWData,
            }
        );

        return response.data;
    }
);

export const fetchSinglePortfolio = createAsyncThunk(
    'singlePortfolio/fetchSinglePortfolio',
    async (id: string) => {
        const portfolio = await APIClient.getData(
            `/portfolio/id/${id}/detail?limit=10000`
        );

        // RELACIONES PERSONAS

        const portfolioResults = await APIClient.getData(
            `/portfolio/id/${id}/relationship/client/?limit=10000`
        );
        
        const profilios = portfolioResults.data.results;

        const relationsTypeResult = await APIClient.getData(
            `/client/?limit=10000`
        );
        const relationsType = relationsTypeResult.data.results;

        const personRelationsData = profilios
            .map((r: any) => {
                const client = relationsType.find(
                    (rt: any) => rt.id === r.client_from
                );

                if (client && r) {
                    return {
                        id: client.id,
                        entity: `${client.name}`,
						idRelationship: r.id,
						relationshipTypeId: r.type,

                        name: r.type.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        // RELACIONES EMPRESAS
        const companiesResult = await APIClient.getData(
            `/company/?limit=10000`
        );
        const companies = companiesResult.data.results;

        const portfolioClientResult = await APIClient.getData(
            `/portfolio/id/${id}/relationship/company/?limit=10000`
        );

        const portfolioClient = portfolioClientResult.data.results;

        const companyRelationsData = portfolioClient
            .map((r: any) => {
                const por = companies.find(
                    (rt: any) => rt.id === r.company_from
                );
                if (r && por) {
                    return {
                        id: r.company_from,
                        entity: por.name,
						idRelationship: r.id,
						relationshipTypeId: r.type,

                        name: r.type.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        // RELACIONES PORTFOLIO

        const portfoliosResult = await APIClient.getData(
            `/portfolio/?limit=10000`
        );
        const portfoliosPorts = portfoliosResult.data.results;


        const portfolioPortResults = await APIClient.getData(
            `/portfolio/id/${id}/relationship/portfolio/?limit=10000`
        );
        
        const profiliosPort = portfolioPortResults.data.results;


        const portfolioPortRelationsData = profiliosPort
            .map((r: any) => {
                const port = portfoliosPorts.find(
                    (rt: any) => rt.id === r.portfolio_to
                );
                if (port && r) {
                    return {
                        id: port.id,
                        entity: `${port.reference_id}`,
						idRelationship: r.id,
						relationshipTypeId: r.type.id,

                        name: r.type.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        return {
            id,
            portfolio: portfolio.data.results,
            persons: personRelationsData,
            business: companyRelationsData,
            portfolios: portfolioPortRelationsData,
        };
    }
);

export const singlePortfolioSlice = createSlice({
    name: 'singlePortfolio',
    initialState,
    reducers: {
      resetSinglePortfolio: (state) => {
          state.id = null;
          state.attributes = [];
          state.personRelations = [];
          state.businessRelations = [];
          state.portfolioRelations = [];
          state.status = Status.idle;
          state.error = null;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSinglePortfolio.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchSinglePortfolio.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.id = action.payload.id;
                state.attributes = action.payload.portfolio;
                state.personRelations = action.payload.persons;
                state.businessRelations = action.payload.business;
                state.portfolioRelations = action.payload.portfolios;
            })
            .addCase(fetchSinglePortfolio.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePortfolioAttributes.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
            })
            .addCase(updatePortfolioAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addPortfolioAttributes.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
            })
            .addCase(addPortfolioAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetSinglePortfolio } = singlePortfolioSlice.actions;
