
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBusiness } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface UsersState {
    data: IBusiness[];
    status: Status;
    error: string | null;
}

const initialState: UsersState = {
    data: [],
    status: Status.idle,
    error: null,
};

interface IEntityBusiness {
	name: string;
	rut: string;
}
interface IEntityBusinessUpdate {
	id: number;
	name: string;
	rut: string;
}
export const createBusiness = createAsyncThunk(
    'singleBusiness/createBusiness',
    async ({name,  rut}:IEntityBusiness) => {
        const response = await APIClient.postData(`/company/`, {
			name, 

			rut,
        });
        return response.data;
    }
);

export const updateCompany = createAsyncThunk(
    'singlePerson/updateBusiness',
    async ({id, name, rut}:IEntityBusinessUpdate) => {
        const response = await APIClient.postData(`/company/id/${id}/`, {
			name, 
			rut,
        });
        return response.data;
    }
);

export const deleteCompany = createAsyncThunk(
    'singlePerson/deleteCompany',
    async ({id}:{id:number}) => {
        const response = await APIClient.deleteDataEntiy(`/company/id/${id}/`);
        return response.data;
    }
);


export const businessEntitySlice = createSlice({
    name: 'businessEntity',
    initialState,
    reducers: {
		resetEntityBusines: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(createBusiness.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createBusiness.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(createBusiness.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(updateCompany.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateCompany.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(updateCompany.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteCompany.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteCompany.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = []
            })
            .addCase(deleteCompany.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
export const { resetEntityBusines } = businessEntitySlice.actions;
