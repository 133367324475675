import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICompanyRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyRelationshipAttributeState {
    data: ICompanyRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: CompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchCompaniesRelationshipRelAttributesValues = createAsyncThunk(
    'companyAttributesFetch/companyAttributesRelValuesFetch',
    async (id: string) => {
        const response = await APIClient.getData(
            `/company/relationship/attribute/relationship/${id}?limit=100000`
        );
        return response.data.results;
    }
);


export const fetchCompaniesRelationshipAttributesRelValuesSlice = createSlice({
    name: 'fetchCompanyRelationshipAttributesValues',
    initialState,
    reducers: {
        resetValuesCompaniesRelFetch: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                fetchCompaniesRelationshipRelAttributesValues.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                fetchCompaniesRelationshipRelAttributesValues.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchCompaniesRelationshipRelAttributesValues.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetValuesCompaniesRelFetch } =
    fetchCompaniesRelationshipAttributesRelValuesSlice.actions;
