
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPerson } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface UsersState {
    data: IPerson[];
    status: Status;
    error: string | null;
}

const initialState: UsersState = {
    data: [],
    status: Status.idle,
    error: null,
};

interface IEntityPerson {
	name: string;
	lastName: string;
	email: string;
	rut: string;
}
interface IEntityPersonUpdate {
	id:number | null;
	name: string;
	lastName: string;
	email: string;
	rut: string;
}

export const createClient = createAsyncThunk(
    'singlePerson/createClient',
    async ({name, lastName, email, rut}:IEntityPerson) => {
        const response = await APIClient.postData(`/client/`, {
			name, 
			lastName, 
			email,
			rut,
        });
        return response.data;
    }
);

export const updateClient = createAsyncThunk(
    'singlePerson/updateClient',
    async ({id, name, lastName, email, rut}:IEntityPersonUpdate) => {
        const response = await APIClient.postData(`/client/id/${id}/`, {
			name, 
			lastName, 
			email,
			rut,
        });
        return response.data;
    }
);
export const deleteClient = createAsyncThunk(
    'singlePerson/deleteClient',
    async ({id}:{id:number}) => {
        const response = await APIClient.deleteDataEntiy(`/client/id/${id}/`);
        return response.data;
    }
);



export const personEntitySlice = createSlice({
    name: 'personEntity',
    initialState,
    reducers: {
		resetSingleEntityPerson: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(createClient.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createClient.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(createClient.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;

            })
            .addCase(updateClient.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateClient.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(updateClient.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteClient.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteClient.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = [];
            })
            .addCase(deleteClient.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })

    },
});

export const { resetSingleEntityPerson } = personEntitySlice.actions;
