import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface DeleteportfolioPortfolioRelationshipAttributeState {
    data: null;
    status: Status;
    error: string | null;
}

const initialState: DeleteportfolioPortfolioRelationshipAttributeState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const deleteportfolioPortfolioRelationshipRelAttributesValues =
    createAsyncThunk(
        'deletePortfolioPortfolioAttributes/deletePortfolioPortfolioAttributesRelValues',
        async ({ id, idRel }: { id: string; idRel: string }) => {
            const response = await APIClient.deleteDataEntiy(
                `/portfolio/relationship/portfolio/id/${idRel}/detail/${id}/`
            );
            return response.data;
        }
    );

export const deleteportfolioPortfolioRelationshipAttributesRelValuesSlice =
    createSlice({
        name: 'deleteportfolioPortfolioRelationshipAttributesValues',
        initialState,
        reducers: {
            resetValuesDeleteRelportfolioPort: (state) => {
                state.data = null;
                state.status = Status.idle;
                state.error = null;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(
                    deleteportfolioPortfolioRelationshipRelAttributesValues.pending,
                    (state) => {
                        state.status = Status.loading;
                    }
                )
                .addCase(
                    deleteportfolioPortfolioRelationshipRelAttributesValues.fulfilled,
                    (state) => {
                        state.status = Status.success;
                        // Add any fetched data to the array
                        // state.data = action.payload;
                    }
                )
                .addCase(
                    deleteportfolioPortfolioRelationshipRelAttributesValues.rejected,
                    (state, action) => {
                        state.status = Status.error;
                        state.error = action.error.message ?? null;
                    }
                );
        },
    });

export const { resetValuesDeleteRelportfolioPort } =
    deleteportfolioPortfolioRelationshipAttributesRelValuesSlice.actions;
