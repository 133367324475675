import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface DeletePersonRelationshipAttributeState {
    data: IPersonRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: DeletePersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deletePersonRelationshipRelAttributesValues = createAsyncThunk(
    'deletePersonAttributes/deletePersonAttributesRelValues',
    async ({id, idRel}: {id: string, idRel: string}) => {
        const response = await APIClient.deleteDataEntiy(
            `/client/relationship/id/${idRel}/detail/${id}/`
        );
        return response.data;
    }
);

export const deletePersonsRelationshipAttributesRelValuesSlice = createSlice({
    name: 'deletePersonRelationshipAttributesValues',
    initialState,
    reducers: {
        resetValuesDeleteRelPerson: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                deletePersonRelationshipRelAttributesValues.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                deletePersonRelationshipRelAttributesValues.fulfilled,
                (state,) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    // state.data = action.payload;
                }
            )
            .addCase(
                deletePersonRelationshipRelAttributesValues.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetValuesDeleteRelPerson } =
    deletePersonsRelationshipAttributesRelValuesSlice.actions;
