import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonPortfolioRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPortfolioRelationState {
    data: IPersonPortfolioRelation[];
    status: Status;
    error: string | null;
}

const initialState: PersonPortfolioRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deletePersonPortfolioRelationshipsValues = createAsyncThunk(
    'deletePersonPortfolioRelationshipsValues/deletePersonPortfolioRelationshipsValues',
    async ({ idRel, idDetail }: { idRel: string; idDetail : string}) => {
        const response = await APIClient.deleteDataEntiy(
            `/portfolio/relationship/client/id/${idRel}/detail/${idDetail}`
        );
        return response.data.results;
    }
);

export const deletePersonPortfolioRelationshipValueSlice = createSlice({
    name: 'deletePersonPortfolioRelationshipsValue',
    initialState,
    reducers: {
        resetDeletePersonPortfolioRelationshipValue: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                deletePersonPortfolioRelationshipsValues.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                deletePersonPortfolioRelationshipsValues.fulfilled,
                (state) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    // state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                deletePersonPortfolioRelationshipsValues.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetDeletePersonPortfolioRelationshipValue } =
    deletePersonPortfolioRelationshipValueSlice.actions;
