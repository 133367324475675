/* eslint-disable react/jsx-props-no-spreading */

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import { updatePersonPersonAttribute } from '../../../../modules/persons-relationships-attributes/features/personRelationshipAttributeSlice';
import EditAttributeRelationship from '../../../../components/EditAttributeRelationship';

export default function EditPersonPersonAttribute({
    show = false,
    attributeData,
    toggle,
}: {
    show: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributeData: any;
    toggle: () => void;
}) {
    const dispatch = useAppDispatch();
    return (
        attributeData.data && (
            <SideCreate show={show} title="Editar atributo" toggle={toggle}>
                <EditAttributeRelationship
                    entity={4}
                    create={(body) =>
                        dispatch(updatePersonPersonAttribute(body)).unwrap()
                    }
                    show={show}
                    toggle={toggle}
                    attributeData={attributeData}
                />
            </SideCreate>
        )
    );
}
