import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const createPortfolioPersonAttribute = createAsyncThunk(
    'portfolioPersonAttributes/create',
    async (body: { label: string; name: string, index: number, type:number, is_unique:boolean, parent_attribute_id:number }) => {
        const response = await APIClient.postData('/portfolio/relationship/client/attribute/', body);
        return response.data;
    }
);

export const updatePortfolioPersonAttribute = createAsyncThunk(
    'portfolioPersonAttributes/updatePortfolioPerson',
    async (body: {
        id: string;
        label: string;
        name: string;
        type: number | undefined;
        is_unique: boolean;
        parent_attribute_id: number | null;
        portfolio_client_relationship_type: number | null | undefined;
    }) => {
        if (!body.portfolio_client_relationship_type) {
            // eslint-disable-next-line no-param-reassign
            delete body.portfolio_client_relationship_type;
        }
        const response = await APIClient.postData(
            `/portfolio/relationship/client/attribute/${body.id}/`,
            body
        );
        return response.data;
    }
);

export const portfolioPersonsAttributesSlice = createSlice({
    name: 'portfolioPersonAttributes',
    initialState,
    reducers: {
        resetPortfolioPersonRelAttribute: (state) => {
            state.data = [];
            state.error = null;
            state.status = Status.idle;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createPortfolioPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPortfolioPersonAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                createPortfolioPersonAttribute.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(updatePortfolioPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                updatePortfolioPersonAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(updatePortfolioPersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});


export const { resetPortfolioPersonRelAttribute } = portfolioPersonsAttributesSlice.actions;
