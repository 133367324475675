import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonPortfolioRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPortfolioRelationState {
    data: IPersonPortfolioRelation[];
    status: Status;
    error: string | null;
}

const initialState: PersonPortfolioRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const deletePersonPortfolioRelationships = createAsyncThunk(
    'deletePersonPortfolioRelationships/deletePersonPortfolioRelationships',
    async ({ idRel }: { idRel: number }) => {
        const response = await APIClient.deleteDataEntiy(
            `/portfolio/relationship/client/${idRel}/`
        );
        return response.data.results;
    }
);

export const deletePersonPortfolioRelationshipSlice = createSlice({
    name: 'deletePersonPortfolioRelationships',
    initialState,
    reducers: {
        resetDeletePersonPortfolioRelationship: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deletePersonPortfolioRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                deletePersonPortfolioRelationships.fulfilled,
                (state) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    // state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                deletePersonPortfolioRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});

export const { resetDeletePersonPortfolioRelationship } =
    deletePersonPortfolioRelationshipSlice.actions;
